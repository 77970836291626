import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import EditFranchiseContext from 'contexts/EditFranchiseContext';
import Franchise from 'types/models/Franchise';
import ListFranchisesPage from 'pages/private/admin/ListFranchisesPage';
import CreateFranchisesPage from 'pages/private/admin/CreateFranchisePage';
import EditFranchisePage from 'pages/private/admin/EditFranchisePage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedFranchise, setSelectedFranchise] = React.useState<Franchise | null>(null);

  return (
    <EditFranchiseContext.Provider value={{ selectedFranchise, setSelectedFranchise }}>
      <Switch>
        <Route exact={true} path={`${match.url}`} component={ListFranchisesPage} />
        <Route path={`${match.url}/create`} component={CreateFranchisesPage} />
        <Route path={`${match.url}/edit`} component={EditFranchisePage} />
        <Redirect to="/private/welcome" />
      </Switch>
    </EditFranchiseContext.Provider>
  );
};
