import * as yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';

export const MAX_FILE_SIZE = 2097152;
export const IMAGE_VALID_FILES = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg'];

export const regexColor = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

export const imageValidation = (imageType: string, isRequired: boolean): MixedSchema => {
  let mixed = yup
    .mixed()
    .test('FILE_SIZE', 'El archivo debe pesar menos de 2mb', (value) => !value || value.size <= MAX_FILE_SIZE)
    .test(
      'FILE_TYPE',
      'El archivo debe ser jpeg, png, jpg, gif o svg',
      (value) => !value || IMAGE_VALID_FILES.includes(value.type),
    );
  if (isRequired) {
    mixed = mixed.required(`La imagen ${imageType} es requerida`);
  }
  return mixed;
};

export const maxirestCodeValidation = (codeIsRequired: boolean): MixedSchema => {
  let mixed = yup.mixed();
  if (codeIsRequired) {
    mixed = mixed.required('El Codigo del Producto para Maxirest es requerido');
  }
  return mixed;
};

export const enabledValidation = yup.boolean().required('Es requerido seleccionar si se encuentra habilitado');

export const descriptionValidation = yup.string().required('La descripción es requerida');

export const validateTimeZoneIdentifiers = (): MixedSchema => {
  const mixed = yup
    .mixed()
    .test(
      'VALID_TIMEZONE',
      'Debe ingresar un Identificador de Zona Horaria Valido: Continente/Pais (si es necesario) /Provincia-Estado-Ciudad',
      (value) => {
        try {
          if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
            return false;
          }

          if (typeof value !== 'string') {
            return false;
          }

          // throws an error if timezone is not valid
          Intl.DateTimeFormat(undefined, { timeZone: value });
          return true;
        } catch (error) {
          return false;
        }
      },
    )
    .required('El identificador de zona horaria es requerido');
  return mixed;
};
