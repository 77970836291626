import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import EditVenueContext from 'contexts/EditVenueContext';
import Venue from 'types/models/Venue';
import Event from 'types/models/Event';
import Article from 'types/models/Article';
import Banner from 'types/models/Banner';
import ListVenuesPage from 'pages/private/admin/ListVenuesPage';
import ListArticlesPage from 'pages/private/admin/ListArticlesPage';
import CreateVenuePage from 'pages/private/admin/CreateVenuePage';
import EditVenuePage from 'pages/private/admin/EditVenuePage';
import EditVenuePaymentConfigPage from 'pages/private/admin/EditVenuePaymentConfigPage';
import EditVenueFactoryWidgetsPage from 'pages/private/admin/EditVenueFactoryWidgetsPage';
import EditVenueSNSConfigPage from 'pages/private/admin/EditVenueSNSConfigPage';
import EditVenueDeliveryConfigPage from 'pages/private/admin/EditVenueDeliveryConfigPage';
import ListEventsPage from 'pages/private/admin/ListEventsPage';
import CreateEventPage from 'pages/private/admin/CreateEventPage';
import CreateArticlePage from 'pages/private/admin/CreateArticlePage';
import EditEventPage from 'pages/private/admin/EditEventPage';
import EditArticlePage from 'pages/private/admin/EditArticlePage';
import ListBannersPage from 'pages/private/admin/ListBannersPage';
import CreateBannerPage from 'pages/private/admin/CreateBannerPage';
import EditBannerPage from 'pages/private/admin/EditBannerPage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedVenue, setSelectedVenue] = React.useState<Venue | null>(null);
  const [selectedEvent, setSelectedEvent] = React.useState<Event | null>(null);
  const [selectedArticle, setSelectedArticle] = React.useState<Article | null>(null);
  const [selectedBanner, setSelectedBanner] = React.useState<Banner | null>(null);

  return (
    <EditVenueContext.Provider
      value={{
        selectedVenue,
        setSelectedVenue,
        selectedEvent,
        setSelectedEvent,
        selectedArticle,
        setSelectedArticle,
        selectedBanner,
        setSelectedBanner,
      }}
    >
      <Switch>
        <Route exact={true} path={`${match.path}`} component={ListVenuesPage} />
        <Route path={`${match.path}/create`} component={CreateVenuePage} />
        <Route path={`${match.path}/:venueId/edit/payment-config`} component={EditVenuePaymentConfigPage} />
        <Route exact={true} path={`${match.path}/:venueId/edit`} component={EditVenuePage} />
        <Route path={`${match.path}/:venueId/edit/sns-config`} component={EditVenueSNSConfigPage} />
        <Route path={`${match.path}/:venueId/edit/factory-widgets`} component={EditVenueFactoryWidgetsPage} />
        <Route path={`${match.path}/:venueId/edit/delivery-config`} component={EditVenueDeliveryConfigPage} />
        <Route exact={true} path={`${match.path}/:venueId/banners`} component={ListBannersPage} />
        <Route path={`${match.path}/:venueId/banners/create`} component={CreateBannerPage} />
        <Route path={`${match.path}/:venueId/banners/edit`} component={EditBannerPage} />
        <Route exact={true} path={`${match.path}/:venueId/events`} component={ListEventsPage} />
        <Route path={`${match.path}/:venueId/events/create`} component={CreateEventPage} />
        <Route path={`${match.path}/:venueId/events/edit`} component={EditEventPage} />
        <Route exact={true} path={`${match.path}/:venueId/articles`} component={ListArticlesPage} />
        <Route path={`${match.path}/:venueId/articles/create`} component={CreateArticlePage} />
        <Route path={`${match.path}/:venueId/articles/edit`} component={EditArticlePage} />
        <Redirect to="/private/welcome" />
      </Switch>
    </EditVenueContext.Provider>
  );
};
