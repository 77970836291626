import React from 'react';
import { useFormik } from 'formik';
import { Box, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SelectField from 'components/SelectField';
import ConsumptionsReportFormProps from 'types/forms/ConsumptionsReportFormProps';
import 'styles/forms/ConsumptionsReportForm.scss';
import { AdminForm, EditorForm } from './schemas/ConsumptionsReportForm';
import AppContext from 'contexts/AppContext';
import { getAllVenues } from 'services/venuesServices';
import Venue from 'types/models/Venue';
import Commerce from 'types/models/Commerce';
import UserRoleType from 'types/enums/UserRoleType';
import { parseDateToFormik, parseDateTimeLumen } from 'utils/datetime';

export default ({ setCurrentFormData }: ConsumptionsReportFormProps): JSX.Element => {
  const { authData } = React.useContext(AppContext);
  const [venues, setVenues] = React.useState<Venue[] | null>(null);
  const [venueCommerces, setVenueCommerces] = React.useState<Commerce[] | null>(null);
  const [selectedVenueTimeZone, setSelectedVenueTimeZone] = React.useState<string | null>(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    const getVenues = async () => {
      const allVenues = await getAllVenues();
      setVenues(allVenues);
    };
    getVenues();
  }, []);

  const venuesForSelect = (venues: Venue[]) => {
    return venues.map((venue) => {
      return {
        value: venue.id,
        label: venue.name,
      };
    });
  };

  const commercesForSelect = (commerces: Commerce[]) => {
    return commerces.map((commerce) => {
      return {
        value: commerce.id,
        label: commerce.name,
      };
    });
  };

  const formik = useFormik({
    initialValues: {
      venueId: '',
      commerceIds: [],
      dateFrom: '',
      dateTo: '',
    },
    validationSchema:
      authData?.user?.role === UserRoleType.Admin || authData?.user?.role === UserRoleType.VenueEditor
        ? AdminForm(t)
        : EditorForm(t),
    onSubmit: (values) => {
      setCurrentFormData({
        ...values,
        dateFrom: parseDateTimeLumen(moment(values.dateFrom).toDate(), ''),
        dateTo: parseDateTimeLumen(moment(values.dateTo).toDate(), ''),
      });
      formik.setSubmitting(false);
    },
  });

  React.useEffect(() => {
    const getSelectedVenue = (): Venue[] => {
      if (!venues || !formik.values.venueId) return [];
      return venues.filter((venue) => venue.id.includes(formik.values.venueId));
    };
    getSelectedVenue().map((element) => {
      if (!element.commerces) return [];
      setSelectedVenueTimeZone(element.timeZoneIdentifier);
      return setVenueCommerces(element.commerces);
    });
  }, [formik.values.venueId]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {venues && (
        <SelectField
          label={t('consumptionsReport-form-venueId-label')}
          name="venueId"
          placeholder={t('consumptionsReport-form-venueId-placeholder')}
          value={formik.values.venueId}
          options={venuesForSelect(venues)}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.venueId}
          error={formik.errors.venueId}
          isClearable={false}
          backspaceRemovesValue={false}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
      )}
      {(authData?.user?.role === UserRoleType.Admin ||
        authData?.user?.role === UserRoleType.VenueEditor ||
        authData?.user?.role === UserRoleType.FranchiseEditor) && (
        <SelectField
          label={t('consumptionsReport-form-commerceId-label')}
          name="commerceIds"
          placeholder={t('consumptionsReport-form-commerceId-placeholder')}
          value={formik.values.commerceIds}
          options={venueCommerces ? commercesForSelect(venueCommerces) : []}
          isMulti={true}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.commerceIds}
          error={formik.errors.commerceIds}
          isClearable={false}
          backspaceRemovesValue={false}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
      )}
      <TextField
        margin="dense"
        fullWidth
        name="dateFrom"
        label={t('consumptionsReport-form-dateFrom')}
        value={
          formik.values.dateFrom
            ? parseDateToFormik(moment(formik.values.dateFrom).toDate(), '')
            : formik.values.dateFrom
        }
        onChange={(event) => {
          formik.setFieldValue('dateFrom', moment(moment(event.target.value).tz(selectedVenueTimeZone ?? '')).toDate());
        }}
        error={formik.touched.dateFrom && Boolean(formik.errors.dateFrom)}
        helperText={formik.touched.dateFrom && formik.errors.dateFrom}
        disabled={formik.isSubmitting}
        type="datetime-local"
        InputLabelProps={{
          shrink: true,
        }}
      />
      {formik.values.dateFrom && (
        <TextField
          margin="dense"
          fullWidth
          name="dateTo"
          label={t('consumptionsReport-form-dateTo')}
          value={
            formik.values.dateTo ? parseDateToFormik(moment(formik.values.dateTo).toDate(), '') : formik.values.dateTo
          }
          onChange={(event) => {
            formik.setFieldValue('dateTo', moment(moment(event.target.value).tz(selectedVenueTimeZone ?? '')).toDate());
          }}
          error={formik.touched.dateTo && Boolean(formik.errors.dateTo)}
          helperText={formik.touched.dateTo && formik.errors.dateTo}
          disabled={formik.isSubmitting}
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      <Box marginTop="1rem">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={!formik.isValid || formik.isSubmitting}
        >
          {t('consumptionsReport-form-search')}
        </Button>
      </Box>
    </form>
  );
};
