import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import EditUserContext from 'contexts/EditUserContext';
import User from 'types/models/User';
import ListUsersPage from 'pages/private/admin/ListUsersPage';
import CreateUserPage from 'pages/private/admin/CreateUserPage';
import EditUserPage from 'pages/private/admin/EditUserPage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

  return (
    <EditUserContext.Provider value={{ selectedUser, setSelectedUser }}>
      <Switch>
        <Route exact={true} path={`${match.url}`} component={ListUsersPage} />
        <Route path={`${match.url}/create`} component={CreateUserPage} />
        <Route path={`${match.url}/edit`} component={EditUserPage} />
        <Redirect to="/private/welcome" />
      </Switch>
    </EditUserContext.Provider>
  );
};
