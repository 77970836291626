import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Discount from 'types/models/Discount';
import PaginationData from 'types/models/PaginationData';
import { getDiscounts, searchDiscounts } from 'services/discountServices';
import EditCommerceContext from 'contexts/EditCommerceContext';
import 'styles/pages/ListDiscountsPage.scss';

export default (): JSX.Element => {
  const history = useHistory();
  const [paginationDiscount, setPaginationDiscount] = React.useState<PaginationData<Discount>>();
  const [searchDiscountText, setSearchDiscountText] = React.useState('');
  const { setSelectedDiscount } = React.useContext(EditCommerceContext);
  const { selectedCommerce } = React.useContext(EditCommerceContext);
  const { t } = useTranslation();

  const loadDiscounts = React.useCallback(async (id: string, page?: number) => {
    const paginationData = await getDiscounts(id, page);
    if (paginationData) setPaginationDiscount(paginationData);
  }, []);

  React.useEffect(() => {
    if (selectedCommerce) {
      loadDiscounts(selectedCommerce.id);
    } else {
      history.push('/private/commerces');
    }
  }, []);

  const handleSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchDiscountText(event.target.value);
  };

  const loadSearchData = React.useCallback(async (commerceId: string, discountSearchTerm: string, page?: number) => {
    const paginationData: PaginationData<Discount> | null = await searchDiscounts(commerceId, discountSearchTerm, page);
    if (paginationData) {
      setPaginationDiscount(paginationData);
    }
  }, []);

  React.useEffect(() => {
    if (!searchDiscountText && !paginationDiscount) return;
    if (!searchDiscountText && paginationDiscount) {
      if (selectedCommerce) {
        loadDiscounts(selectedCommerce?.id);
      }
    }
  }, [searchDiscountText]);

  const renderEditButton = (discount: Discount): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          setSelectedDiscount(discount);
          history.push('discounts/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderContent = (paginationData: Discount[]) => {
    return (
      <>
        {paginationData.map((discount: Discount) => (
          <TableRow key={discount.id}>
            <TableCell component="th" scope="row">
              {discount.description}
            </TableCell>
            <TableCell>{discount.enabled ? t('yes') : t('no')}</TableCell>
            <TableCell>{`${discount.amount}`}</TableCell>
            <TableCell>{`${discount.priority}`}</TableCell>
            <TableCell>{renderEditButton(discount)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="search-container">
        <div className="search-bar-container">
          <TextField
            id="search"
            name="searchTerm"
            label={t('listDiscount-page-search-placeholder')}
            value={searchDiscountText}
            onChange={handleSearchTerm}
            margin="dense"
            fullWidth
          />
        </div>
        <div className="search-button-element">
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            disabled={!searchDiscountText}
            onClick={() => {
              if (selectedCommerce) {
                loadSearchData(selectedCommerce.id, searchDiscountText);
              }
            }}
          >
            {t('listDiscount-page-search')}
          </Button>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          history.push('discounts/create');
        }}
      >
        {t('listDiscount-page-create')}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('listDiscount-page-description')}</TableCell>
              <TableCell>{t('listDiscount-page-enabled')}</TableCell>
              <TableCell>{t('listDiscount-page-amount')}</TableCell>
              <TableCell>{t('listDiscount-page-priority')}</TableCell>
              <TableCell>{t('listDiscount-page-edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationDiscount?.data && paginationDiscount?.data.length > 0 ? (
              renderContent(paginationDiscount.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listDiscount-page-noDiscounts')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationDiscount && (
        <TablePagination
          component="div"
          count={paginationDiscount.total}
          page={paginationDiscount.currentPage - 1}
          onPageChange={(_, page: number) => {
            if (selectedCommerce) {
              loadDiscounts(selectedCommerce.id, page + 1);
            }
          }}
          rowsPerPage={paginationDiscount.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
