import React from 'react';
import { useHistory } from 'react-router-dom';
import UserForm from 'forms/UserForm';
import EditUserContext from 'contexts/EditUserContext';
import { getUser } from 'services/userServices';
import User from 'types/models/User';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedUser } = React.useContext(EditUserContext);
  const [completeUser, setCompleteUser] = React.useState<User | null>(null);

  const load = React.useCallback(async (id: string) => {
    const user = await getUser(id);
    if (!user) {
      history.push('/private/admin/users');
      return;
    }
    setCompleteUser(user);
  }, []);

  React.useEffect(() => {
    if (!selectedUser) {
      history.push('/private/admin/users');
    } else {
      load(selectedUser.id);
    }
  }, [selectedUser]);

  if (!completeUser) return <></>;
  return <UserForm user={completeUser} />;
};
