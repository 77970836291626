import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { History } from 'history';
//import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    renderEvents: any;
    unmountEvents: any;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
window.renderEvents = (containerId: string, history: History) => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById(containerId),
  );
};

window.unmountEvents = (containerId: string) => {
  const component = document.getElementById(containerId);
  if (component) ReactDOM.unmountComponentAtNode(component);
};

if (!document.getElementById('Events-container')) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
