import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import EditTwitterConfigContext from 'contexts/EditTwitterConfigContext';
import TwitterConfig from 'types/models/TwitterConfig';
import ListTwitterConfigsPage from 'pages/private/ListTwitterConfigsPage';
import CreateTwitterConfigPage from 'pages/private/admin/CreateTwitterConfigPage';
import EditTwitterConfigPage from 'pages/private/admin/EditTwitterConfigPage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedTwitterConfig, setSelectedTwitterConfig] = React.useState<TwitterConfig | null>(null);

  return (
    <EditTwitterConfigContext.Provider value={{ selectedTwitterConfig, setSelectedTwitterConfig }}>
      <Switch>
        <Route exact={true} path={`${match.url}`} component={ListTwitterConfigsPage} />
        <Route path={`${match.url}/create`} component={CreateTwitterConfigPage} />
        <Route path={`${match.url}/edit`} component={EditTwitterConfigPage} />
        <Redirect to="/private/welcome" />
      </Switch>
    </EditTwitterConfigContext.Provider>
  );
};
