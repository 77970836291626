import React from 'react';
import { useHistory } from 'react-router-dom';
import BenefitForm from 'forms/BenefitForm';
import EditBenefitContext from 'contexts/EditBenefitContext';
import { getBenefit } from 'services/benefitServices';
import { loadImages } from 'services/imageServices';
import Benefit from 'types/models/Benefit';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedBenefit } = React.useContext(EditBenefitContext);
  const [completeBenefit, setCompleteBenefit] = React.useState<Benefit | null>(null);

  const load = React.useCallback(async (id: string) => {
    const benefit = await getBenefit(id);
    if (!benefit) {
      history.push('/private/admin/benefits');
      return;
    } else {
      if (benefit.images) {
        await loadImages(benefit.images);
      }
    }
    setCompleteBenefit(benefit);
  }, []);

  React.useEffect(() => {
    if (!selectedBenefit) {
      history.push('/private/admin/benefits');
    } else {
      load(selectedBenefit.id);
    }
  }, [selectedBenefit]);

  if (!completeBenefit) return <></>;
  return <BenefitForm benefit={completeBenefit} />;
};
