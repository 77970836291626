import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import EditIPConfigContext from 'contexts/EditIPConfigContext';
import IPConfigForm from 'forms/IPConfigForm';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedIPConfig } = React.useContext(EditIPConfigContext);
  if (!selectedIPConfig) {
    history.push('/private/admin/payments/izipay');
    return <></>;
  }

  return (
    <div>
      <IPConfigForm config={selectedIPConfig} />
      <Box display="flex" justifyContent="center" width={1}>
        <Button variant="contained" color="secondary" fullWidth onClick={() => history.go(-1)}>
          Back
        </Button>
      </Box>
    </div>
  );
};
