import * as yup from 'yup';
import { enabledValidation } from 'utils/yup';

export default yup.object({
  cashEnabled: enabledValidation,
  activeProvider: yup.number().positive(),
  firstDataConfigId: yup.string(),
  mpConfigId: yup.string(),
  mpCheckoutConfigId: yup.string(),
  mpPointConfigId: yup.string(),
  cloverPointConfigId: yup.string(),
});
