import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import AuthData from 'types/models/AuthData';
import AppContext from 'contexts/AppContext';
import AppHeader from 'components/AppHeader';

export default (): JSX.Element => {
  const [authData, setAuthData] = React.useState<AuthData | null>(null);
  const [currentPageListNumber, setCurrentPageListNumber] = React.useState<number | null | undefined>(1);

  if (!authData) {
    const storageAuthData = localStorage.getItem('auth-data');
    if (storageAuthData) {
      setAuthData(JSON.parse(storageAuthData));
    }
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{ authData, setAuthData, currentPageListNumber, setCurrentPageListNumber }}>
        <AppHeader />
        <div className="app-container">
          <Switch>
            {/* Private Routes */}
            <Route path="/private" component={PrivateRoutes} />
            {/* Public Routes */}
            <Route path="/public" component={PublicRoutes} />
            <Redirect to={`${authData ? '/private/welcome' : '/public/login'}`} />
          </Switch>
        </div>
      </AppContext.Provider>
    </BrowserRouter>
  );
};
