import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import MPPointConfigForm from 'forms/MPPointConfigForm';
export default (): JSX.Element => {
  const history = useHistory();
  return (
    <div>
      <MPPointConfigForm />
      <Box display="flex" justifyContent="center" width={1}>
        <Button variant="contained" color="secondary" fullWidth onClick={() => history.go(-1)}>
          Back
        </Button>
      </Box>
    </div>
  );
};
