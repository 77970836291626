import axiosInstance from './axios';
import { parseCommerce } from './commerceServices';
import PaginationData from 'types/models/PaginationData';
import Order from 'types/models/Order';
import OrderDetail from 'types/models/OrderDetail';
import { ConsumptionReportPayloadData } from 'types/forms/ConsumptionsReportFormProps';
import { FormDataTotalOrders, ProtocolOrderPayloadData, VenueAndCommerce } from 'types/forms/ProtocolOrdersFormProps';
import BalanceTransaction from 'types/models/BalanceTransaction';
import ClothingSizeType from 'types/models/ClothingSizeType';
import { parseUser } from './authServices';
import { parseVenue } from './venuesServices';
import { parseEvent } from './eventServices';
import { parseFDPayment } from './paymentsServices';

export const getReportingOrdersTotal = async (formData: ConsumptionReportPayloadData): Promise<number | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `reporting/orders/total`,
      data: formData,
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getReportingOrders = async (
  formData: ConsumptionReportPayloadData,
  page?: number,
): Promise<PaginationData<Order> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'post',
      url: `reporting/orders?page=${page ?? 1}`,
      data: {
        ...formData,
        download: false,
      },
    });
    return {
      currentPage: paginationData.current_page,
      data: parseOrders(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllReportingOrders = async (formData: ConsumptionReportPayloadData): Promise<Blob | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `reporting/orders`,
      data: {
        ...formData,
        download: true,
      },
      responseType: 'blob',
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getReportingLoads = async (
  params: Record<any, any>,
  page?: number,
): Promise<PaginationData<BalanceTransaction> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'post',
      url: `reporting/loads?page=${page ?? 1}`,
      data: {
        ...params,
        isToDownload: false,
      },
    });
    return {
      currentPage: paginationData.current_page,
      data: parseBalanceTransactions(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllReportingLoads = async (params: Record<any, any>): Promise<Blob | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `reporting/loads`,
      data: {
        ...params,
        isToDownload: true,
      },
      responseType: 'blob',
    });

    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const storeOrder = async (formData: ProtocolOrderPayloadData): Promise<Order | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'orders',
      data: { ...formData },
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getProtocolOrders = async (
  form: VenueAndCommerce,
  page?: number,
): Promise<PaginationData<Order> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'POST',
      url: `protocol-orders?page=${page ?? 1}`,
      data: form,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseOrders(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getProtocolOrdersTotal = async (formData: FormDataTotalOrders): Promise<number | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `protocol-orders/total`,
      data: formData,
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const parseOrders = (orders: any): Order[] => {
  return orders.map((order: any): Order => parseOrder(order));
};

const parseOrder = (order: any): Order => {
  return {
    id: order.id,
    total: order.total_amount,
    orderStatus: order.order_status_id,
    guestEmail: order.guest_email,
    firstDataPayment: order?.payment?.first_data_payment ? parseFDPayment(order.payment.first_data_payment) : null,
    commerce: parseCommerce(order.commerce),
    event: order.event ? parseEvent(order.event) : undefined,
    phone: order.phone,
    createdAt: order.created_at,
    updatedAt: order.update_at,
    localDatetime: order.local_datetime,
    details: parseDetailsOrder(order.order_detail),
    delivery: order.delivery,
  };
};

const parseDetailsOrder = (details: []): OrderDetail[] => {
  return details.map((detail: any): OrderDetail => parseDetails(detail));
};

const parseDetails = (detail: any): OrderDetail => ({
  id: detail.id,
  product: detail.product,
  price: detail.price,
  estimatedPreparationTime: detail.estimated_preparation_time,
  quantity: detail.quantity,
  totalAmount: detail.total_amount,
  comments: detail.comments,
  clothinSizeType: detail.clothing_size_type_id ? parseClothingSizeType(detail.clothing_size_type) : undefined,
});

const parseClothingSizeType = (clothingSize: any): ClothingSizeType => ({
  id: clothingSize.id,
  description: clothingSize.description,
});

const parseBalanceTransactions = (balances: any): BalanceTransaction[] => {
  return balances.map((balance: any): BalanceTransaction => parseBalanceTransaction(balance));
};

const parseBalanceTransaction = (order: any): BalanceTransaction => {
  return {
    id: order.id,
    amount: Number(order.amount),
    createdAt: order.created_at,
    localDateTime: order.local_datetime,
    user: parseUser(order.user),
    venue: parseVenue(order.venue),
  };
};
