enum VenueImageType {
  Logo = 1,
  Map = 2,
  Ticket = 3,
  VenueMap = 4,
  Sparx = 5,
  InstalationAppBanner = 6,
  Banner = 7,
  CloverMiniBackgroundImage = 8,
  CloverMiniLowerBanner = 9,
  CloverFlexBackgroundImage = 10,
  CloverFlexLowerBanner = 11,
  LogoForTinting = 12,
}

export default VenueImageType;
