import React from 'react';
import ProductForm from 'forms/ProductForm';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditCommerceContext from 'contexts/EditCommerceContext';
import AppContext from 'contexts/AppContext';
import UserRoleType from 'types/enums/UserRoleType';

export default (): JSX.Element => {
  const { authData } = React.useContext(AppContext);
  const { selectedCommerce } = React.useContext(EditCommerceContext);
  const { t } = useTranslation();

  if (
    !authData ||
    (authData.user.role !== UserRoleType.Admin &&
      authData.user.role !== UserRoleType.FranchiseEditor &&
      UserRoleType.CommerceEditor &&
      selectedCommerce?.editorId !== authData.user.id)
  ) {
    return (
      <div className="error-container">
        <Typography color="error" variant="h3" align="center">
          {t('not-authorized-error-or-not-editor')}
        </Typography>
      </div>
    );
  }

  return <ProductForm />;
};
