import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import EditBenefitContext from 'contexts/EditBenefitContext';
import Benefit from 'types/models/Benefit';
import ListBenefitsPage from 'pages/private/admin/ListBenefitsPage';
import CreateBenefitPage from 'pages/private/admin/CreateBenefitPage';
import EditBenefitPage from 'pages/private/admin/EditBenefitPage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedBenefit, setSelectedBenefit] = React.useState<Benefit | null>(null);

  return (
    <EditBenefitContext.Provider value={{ selectedBenefit, setSelectedBenefit }}>
      <Switch>
        <Route exact={true} path={`${match.url}`} component={ListBenefitsPage} />
        <Route path={`${match.url}/create`} component={CreateBenefitPage} />
        <Route path={`${match.url}/edit`} component={EditBenefitPage} />
        <Redirect to="/private/welcome" />
      </Switch>
    </EditBenefitContext.Provider>
  );
};
