import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PaginationData from 'types/models/PaginationData';
import Article from 'types/models/Article';
import { parseDateTime } from 'utils/datetime';
import EditVenueContext from 'contexts/EditVenueContext';
import { getArticles } from 'services/articleServices';

export default (): JSX.Element => {
  const history = useHistory();
  const [paginationArticle, setPaginationArticle] = React.useState<PaginationData<Article>>();
  const { setSelectedArticle } = React.useContext(EditVenueContext);
  const { selectedVenue } = React.useContext(EditVenueContext);
  const { t } = useTranslation();

  const loadArticles = React.useCallback(async (venueId: string, page?: number) => {
    const paginationData = await getArticles(venueId, page);
    if (paginationData) setPaginationArticle(paginationData);
  }, []);

  React.useEffect(() => {
    if (selectedVenue) {
      loadArticles(selectedVenue.id);
    } else {
      history.push('/private/admin/venues');
    }
  }, []);

  const renderEditButton = (article: Article): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        aria-label="edit"
        onClick={() => {
          setSelectedArticle(article);
          history.push('articles/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderContent = (paginationData: Article[]) => {
    return (
      <>
        {paginationData.map((article: Article) => (
          <TableRow key={article.id}>
            <TableCell component="th" scope="row">
              {article.title}
            </TableCell>
            <TableCell>{parseDateTime(article.date)}</TableCell>
            <TableCell>{renderEditButton(article)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          history.push('articles/create');
        }}
      >
        {t('listArticles-page-notice-create')}
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('listArticles-page-notice-title')}</TableCell>
              <TableCell>{t('listArticles-page-notice-date')}</TableCell>
              <TableCell>{t('listArticles-page-notice-edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationArticle?.data && paginationArticle?.data.length > 0 ? (
              renderContent(paginationArticle.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listArticles-page-noNotices')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationArticle && (
        <TablePagination
          component="div"
          count={paginationArticle.total}
          page={paginationArticle.currentPage - 1}
          onPageChange={(_, page: number) => {
            if (selectedVenue) {
              loadArticles(selectedVenue.id, page + 1);
            }
          }}
          rowsPerPage={paginationArticle.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
