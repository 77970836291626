import * as yup from 'yup';
import { TFunction } from 'react-i18next';

export const CreateForm = (t: TFunction): any =>
  yup.object().shape({
    email: yup.string().email(t('schema-user-form-email-format')).required(t('schema-user-form-email-required')),
    password: yup
      .string()
      .required(t('schema-user-form-email-password-required'))
      .min(3, t('schema-user-form-email-password-min'))
      .matches(/^\S*$/, t('schema-user-form-email-password-no-blank-spaces')),
    name: yup.string().required(t('schema-user-form-email-name-required')),
    role: yup.number().required(t('schema-user-form-email-role-required')),
  });

export const EditForm = (t: TFunction): any =>
  yup.object().shape({
    email: yup.string().email(t('schema-user-form-email-format')).required(t('schema-user-form-email-required')),
    password: yup
      .string()
      .min(3, t('schema-user-form-email-password-min'))
      .matches(/^\S*$/, t('schema-user-form-email-password-no-blank-spaces')),
    name: yup.string().required(t('schema-user-form-email-name-required')),
    role: yup.number().required(t('schema-user-form-email-role-required')),
  });
