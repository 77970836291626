import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import ProductCategory from 'types/models/ProductCategory';
import PaginationData from 'types/models/PaginationData';
import EditProductCategoriesContext from 'contexts/EditProductCategoriesContext';
import { getProductCategoriesWithPagination } from 'services/productServices';
import AppContext from 'contexts/AppContext';

export default (): JSX.Element => {
  const history = useHistory();
  const { currentPageListNumber, setCurrentPageListNumber } = React.useContext(AppContext);
  const [paginationProductCategory, setPaginationProductCategory] = React.useState<PaginationData<ProductCategory>>();
  const { setSelectedProductCategory } = React.useContext(EditProductCategoriesContext);
  const { t } = useTranslation();

  const loadProductCategories = React.useCallback(async (page?: number) => {
    const paginationData = await getProductCategoriesWithPagination(page);
    if (paginationData) {
      setPaginationProductCategory(paginationData);
    }
  }, []);

  React.useEffect(() => {
    if (currentPageListNumber) {
      loadProductCategories(currentPageListNumber);
    }
  }, [currentPageListNumber]);
  const renderEditButton = (productCategory: ProductCategory | null): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          setSelectedProductCategory(productCategory);
          history.push('product-categories/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderContent = (paginationData: ProductCategory[]): JSX.Element => {
    return (
      <>
        {paginationData.map((productCategory: ProductCategory) => (
          <TableRow key={productCategory.id}>
            <TableCell component="th" scope="row">
              {productCategory.description}
            </TableCell>
            <TableCell>{renderEditButton(productCategory)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          history.push('/private/admin/product-categories/create');
        }}
      >
        {t('listProductCategories-page-create')}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('listProductCategories-page-description')}</TableCell>
              <TableCell>{t('listProductCategories-page-edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationProductCategory?.data && paginationProductCategory?.data.length > 0 ? (
              renderContent(paginationProductCategory.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listProductCategories-page-noCategories')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationProductCategory && (
        <TablePagination
          component="div"
          count={paginationProductCategory.total}
          page={paginationProductCategory.currentPage - 1}
          onPageChange={(_, page: number) => {
            setCurrentPageListNumber(page + 1);
            loadProductCategories(page + 1);
          }}
          rowsPerPage={paginationProductCategory.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
