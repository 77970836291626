import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PaginationData from 'types/models/PaginationData';
import TwitterConfig from 'types/models/TwitterConfig';
import EditTwitterConfigContext from 'contexts/EditTwitterConfigContext';
import AppContext from 'contexts/AppContext';
import { getTwitterConfigs } from 'services/twitterConfigsServices';
import TwitterSearchType from 'types/enums/TwitterSearchType';

export default (): JSX.Element => {
  const history = useHistory();
  const { currentPageListNumber, setCurrentPageListNumber } = React.useContext(AppContext);
  const [paginationTwitterConfigs, setPaginationTwitterConfigs] = React.useState<PaginationData<TwitterConfig>>();
  const { setSelectedTwitterConfig } = React.useContext(EditTwitterConfigContext);
  const { t } = useTranslation();

  const loadTwitterConfigs = React.useCallback(async (page?: number) => {
    const paginationData = await getTwitterConfigs(page);
    if (paginationData) {
      setPaginationTwitterConfigs(paginationData);
    }
  }, []);

  React.useEffect(() => {
    if (currentPageListNumber) {
      loadTwitterConfigs(currentPageListNumber);
    }
  }, [currentPageListNumber]);
  const renderEditButton = (twitterConfig: TwitterConfig | null): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          setSelectedTwitterConfig(twitterConfig);
          history.push('twitter-configs/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderContent = (paginationData: TwitterConfig[]): JSX.Element => {
    return (
      <>
        {paginationData.map((twitterConfig: TwitterConfig) => (
          <TableRow key={twitterConfig.id}>
            <TableCell component="th" scope="row">
              {t('listTwitterConfig-page-type')}: {TwitterSearchType[twitterConfig.searchType]} |{' '}
              {t('listTwitterConfig-page-value')}: {twitterConfig.value}
            </TableCell>
            <TableCell>{renderEditButton(twitterConfig)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          history.push('/private/admin/twitter-configs/create');
        }}
      >
        {t('listTwitterConfig-page-create')}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('listTwitterConfig-page-description')}</TableCell>
              <TableCell>{t('listTwitterConfig-page-edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationTwitterConfigs?.data && paginationTwitterConfigs?.data.length > 0 ? (
              renderContent(paginationTwitterConfigs.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listTwitterConfig-page-noCategories')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationTwitterConfigs && (
        <TablePagination
          component="div"
          count={paginationTwitterConfigs.total}
          page={paginationTwitterConfigs.currentPage - 1}
          onPageChange={(_, page: number) => {
            setCurrentPageListNumber(page + 1);
            loadTwitterConfigs(page + 1);
          }}
          rowsPerPage={paginationTwitterConfigs.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
