import * as yup from 'yup';
import { AnyObjectSchema } from 'yup';
import { descriptionValidation, enabledValidation, imageValidation } from 'utils/yup';
import { TFunction } from 'react-i18next';

export const CreateForm = (t: TFunction): AnyObjectSchema => {
  return yup.object().shape({
    description: descriptionValidation,
    amount: yup
      .number()
      .positive(t('schema-discount-form-amount-positive'))
      .required(t('schema-discount-form-amount-required'))
      .min(1, t('schema-discount-form-amount-minimun')),
    priority: yup
      .number()
      .positive(t('schema-discount-form-priority-positive'))
      .required(t('schema-discount-form-priority-required'))
      .min(1, t('schema-discount-form-priority-minimun')),
    banner: imageValidation('Banner', true),
  });
};

export const EditForm = (t: TFunction): any =>
  yup.object().shape({
    description: descriptionValidation,
    amount: yup
      .number()
      .positive(t('schema-discount-form-amount-positive'))
      .required(t('schema-discount-form-amount-required'))
      .min(1, t('schema-discount-form-amount-minimun')),
    priority: yup
      .number()
      .positive(t('schema-discount-form-priority-positive'))
      .required(t('schema-discount-form-priority-required'))
      .min(1, t('schema-discount-form-priority-minimun')),
    banner: imageValidation('Banner', true),
    enabled: enabledValidation,
  });
