import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import WelcomePage from 'pages/private/WelcomePage';
import ReportingPage from 'pages/private/admin/ReportingPage';
import AdminRoutes from './AdminRoutes';
import CommerceContextRoute from './CommerceContextRoute';
import VenueContextRoute from './VenueContextRoute';
import UserRoleType from 'types/enums/UserRoleType';
import ConsumptionsReportPage from 'pages/private/admin/ConsumptionsReportPage';
import LoadsReportPage from 'pages/private/admin/LoadsReportPage';
import SellsReportPage from 'pages/private/admin/SellsReportPage';
import ProtocolOrdersPage from 'pages/private/admin/ProtocolOrdersPage';
import FranchisesContextRoute from './FranchisesContextRoute';
import BenefitContextRoute from './BenefitContextRoute';
import UserContextRoute from './UserContextRoute';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const { authData } = React.useContext(AppContext);

  if (!authData || authData.user.role === UserRoleType.Buyer) {
    return <Redirect to="/public/login" />;
  }

  return (
    <Switch>
      <Route exact path={`${match.url}/welcome`} component={WelcomePage} />
      {/* Admin Routes */}
      <Route path={`${match.url}/admin`} component={AdminRoutes} />
      <Route path={`${match.url}/venues`} component={VenueContextRoute} />
      <Route path={`${match.url}/commerces`} component={CommerceContextRoute} />
      <Route path={`${match.url}/franchises`} component={FranchisesContextRoute} />
      <Route path={`${match.url}/benefits`} component={BenefitContextRoute} />
      <Route path={`${match.url}/users`} component={UserContextRoute} />
      <Route exact path={`${match.url}/reporting`} component={ReportingPage} />
      <Route path={`${match.url}/reporting/consumptions`} component={ConsumptionsReportPage} />
      <Route path={`${match.url}/reporting/loads`} component={LoadsReportPage} />
      <Route path={`${match.url}/reporting/sells`} component={SellsReportPage} />
      <Route path={`${match.url}/protocol-orders`} component={ProtocolOrdersPage} />
      <Redirect to="/private/welcome" />
    </Switch>
  );
};
