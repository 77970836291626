import * as yup from 'yup';
import { TFunction } from 'i18next';

export default (t: TFunction): any =>
  yup.object({
    factoryWidgets: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          factoryWidgetLink: yup
            .string()
            .min(13, t('venueFactoryWidget-form-link-min'))
            .max(191, t('venueFactoryWidget-form-link-max'))
            .url(t('schema-venueFactoryWidget-url'))
            .required(t('schema-venueFactoryWidget-required')),
          height: yup.string().required(t('schema-venueFactoryWidget-height')),
        }),
      )
      .min(1, t('schema-venueFactoryWidget-min')),
  });
