import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import EditCloverPointConfigContext from 'contexts/EditCloverPointConfigContext';
import CloverPointForm from 'forms/CloverPointForm';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedCloverPoint } = React.useContext(EditCloverPointConfigContext);
  if (!selectedCloverPoint) {
    history.push('/private/admin/payments/clover-point/posnets');
    return <></>;
  }

  return (
    <div>
      <CloverPointForm cloverPoint={selectedCloverPoint} />
      <Box display="flex" justifyContent="center" width={1}>
        <Button variant="contained" color="secondary" fullWidth onClick={() => history.go(-1)}>
          Back
        </Button>
      </Box>
    </div>
  );
};
