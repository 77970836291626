import axiosInstance, { axiosMultipart } from './axios';
import PaginationData from 'types/models/PaginationData';
import Discount from 'types/models/Discount';
import { parseImages } from './imageServices';

export const getDiscounts = async (commerceId: string, page?: number): Promise<PaginationData<Discount> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `commerces/${commerceId}/discounts?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseDiscounts(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeDiscount = async (id: string, formData: FormData): Promise<Discount | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `commerces/${id}/discounts`,
      data: formData,
    });
    return parseDiscount(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateDiscount = async (discountId: string, formData: FormData): Promise<Discount | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `discounts/${discountId}?_method=put`,
      data: formData,
    });
    return parseDiscount(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getDiscount = async (discountId: string): Promise<Discount | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `discounts/${discountId}`,
    });
    return parseDiscount(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteDiscount = async (discountId: string): Promise<Discount | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `discounts/${discountId}`,
    });
    return parseDiscount(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const searchDiscounts = async (
  commerceId: string,
  searchTerm: string,
  page?: number,
): Promise<PaginationData<Discount> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `discounts/${commerceId}/search?page=${page ?? 1}&discountName=${searchTerm}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseDiscounts(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseDiscounts = (discounts: any): Discount[] => {
  return discounts.map((discount: any) => parseDiscount(discount));
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const parseDiscount = (discount: any): Discount => {
  return {
    id: discount.id,
    description: discount.description,
    enabled: discount.enabled === 1,
    amount: discount.amount,
    priority: discount.priority,
    images: discount.images ? parseImages(discount.images) : undefined,
  };
};
