import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import EditProductCategoriesContext from 'contexts/EditProductCategoriesContext';
import ProductCategory from 'types/models/ProductCategory';
import ListProductCategoriesPage from 'pages/private/admin/ListProductCategoriesPage';
import CreateProductCategoriesPage from 'pages/private/admin/CreateProductCategoriesPage';
import EditProductCategoriesPage from 'pages/private/admin/EditProductCategoriesPage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedProductCategory, setSelectedProductCategory] = React.useState<ProductCategory | null>(null);

  return (
    <EditProductCategoriesContext.Provider value={{ selectedProductCategory, setSelectedProductCategory }}>
      <Switch>
        <Route exact={true} path={`${match.url}`} component={ListProductCategoriesPage} />
        <Route path={`${match.url}/create`} component={CreateProductCategoriesPage} />
        <Route path={`${match.url}/edit`} component={EditProductCategoriesPage} />
        <Redirect to="/private/welcome" />
      </Switch>
    </EditProductCategoriesContext.Provider>
  );
};
