import React from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getReportingSells, getAllReportingSells } from 'services/paymentsServices';
import type PaginationData from 'types/models/PaginationData';
import type FDPayment from 'types/models/FirstDataPayment';
import 'styles/pages/ConsumptionsReportPage.scss';
import AppContext from 'contexts/AppContext';
import SelectField from 'components/SelectField';
import UserRoleType from 'types/enums/UserRoleType';
import Venue from 'types/models/Venue';
import { getAllVenues } from 'services/venuesServices';
import Commerce from 'types/models/Commerce';
import { parseDateToFormik, parseDateTimeLumen } from 'utils/datetime';

export default (): JSX.Element => {
  const [params, setParams] = React.useState<Record<any, any> | null>(null);
  const [paginationSells, setPaginationSells] = React.useState<PaginationData<FDPayment> | null>(null);

  const { t } = useTranslation();

  const loadData = async (page?: number, commerceId?: string) => {
    if (!params) return;
    const allSells = await getReportingSells(params, page, commerceId);
    if (allSells) setPaginationSells(allSells);
  };

  const downloadReport = async (): Promise<void> => {
    if (!params) return;
    const result = await getAllReportingSells(params);
    if (result) {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(
        new Blob([result], {
          type: 'application/octet-stream',
        }),
      );
      a.download = 'reporte_ventas.xlsx';
      a.click();
    }
  };

  React.useEffect(() => {
    if (paginationSells) {
      setPaginationSells(null);
    }
    loadData();
  }, [params]);

  const renderContent = (sell: FDPayment): JSX.Element => {
    return (
      <>
        <TableRow className="content-table">
          <TableCell>{sell?.clientId ?? '-'}</TableCell>
          <TableCell>
            {sell?.txnDateProcessed ? moment(sell.txnDateProcessed, 'DD/MM/YY HH:mm:ss').format('YYYY-MM-DD') : '-'}
          </TableCell>
          <TableCell>{sell?.ccBrand ?? '-'}</TableCell>
          <TableCell>{sell?.ccBin && sell?.cardNumber ? `${sell.ccBin}${sell.ccLastFourDigits}` : '-'}</TableCell>
          <TableCell>
            {sell?.approvalCode ? `${sell.approvalCode.substring(sell.approvalCode.length - 4)}` : '-'}
          </TableCell>
          <TableCell>{sell?.chargeTotal ?? '-'}</TableCell>
          <TableCell>{sell?.dues ?? '-'}</TableCell>
          <TableCell>{sell?.terminalId ?? '-'}</TableCell>
          <TableCell>{sell?.approvalCode ? sell.approvalCode.split(':')[1] : '-'}</TableCell>
          <TableCell>{sell?.status ?? '-'}</TableCell>
          <TableCell>{sell?.oid ?? '-'}</TableCell>
          <TableCell>
            {sell?.txnDatetime ? moment(sell.txnDatetime, 'YYYY:MM:DD-HH:mm:ss').format('YYYY-MM-DD') : '-'}
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <LoadReportForm parameters={[params, setParams]} />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>{t('sellsReport-page-client')}</TableCell>
              <TableCell>{t('sellsReport-page-date')}</TableCell>
              <TableCell>{t('sellsReport-page-brand')}</TableCell>
              <TableCell>{t('sellsReport-page-cardNumber')}</TableCell>
              <TableCell>{t('sellsReport-page-coupon')}</TableCell>
              <TableCell>{t('sellsReport-page-amount')}</TableCell>
              <TableCell>{t('sellsReport-page-dues')}</TableCell>
              <TableCell>{t('sellsReport-page-terminal')}</TableCell>
              <TableCell>{t('sellsReport-page-authCode')}</TableCell>
              <TableCell>{t('sellsReport-page-status')}</TableCell>
              <TableCell>{t('sellsReport-page-operationId')}</TableCell>
              <TableCell>{t('sellsReport-page-linkDate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationSells?.data && paginationSells?.data.length > 0 ? (
              paginationSells?.data.map((sell) => <React.Fragment key={sell.id}>{renderContent(sell)}</React.Fragment>)
            ) : (
              <TableRow>
                <TableCell>{t('sellsReport-page-no-sales')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationSells && (
        <>
          <TablePagination
            component="div"
            count={paginationSells.total}
            page={paginationSells.currentPage - 1}
            onPageChange={(_, page: number) => {
              loadData(page + 1);
            }}
            rowsPerPage={paginationSells.perPage}
            rowsPerPageOptions={[]}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
            }}
          />
          {paginationSells?.data.length > 0 && (
            <Box marginTop="1rem">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  downloadReport();
                }}
              >
                {t('consumptionsReport-page-download')}
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
};

const LoadReportForm = ({
  parameters: useParameters,
}: {
  parameters: [Record<any, any> | null, (params: Record<any, any>) => void];
}) => {
  const [params, setParams] = useParameters;
  const [venueCommerces, setVenueCommerces] = React.useState<Commerce[]>([]);
  const [venues, setVenues] = React.useState<Venue[] | null>(null);
  const [selectedVenueTimeZone, setSelectedVenueTimeZone] = React.useState<string | null>(null);
  const { t } = useTranslation();
  const { authData } = React.useContext(AppContext);

  const formik = useFormik({
    initialValues: {
      venueId: '',
      commerceIds: [],
      dateFrom: '',
      dateTo: '',
    },
    onSubmit: (values) => {
      setParams({
        ...params,
        ...values,
        dateFrom: parseDateTimeLumen(moment(values.dateFrom).toDate(), ''),
        dateTo: parseDateTimeLumen(moment(values.dateTo).toDate(), ''),
      });
      formik.setSubmitting(false);
    },
  });

  const commercesForSelect = (commerces: Commerce[]) => {
    return commerces.map((commerce) => {
      return {
        value: commerce.id,
        label: commerce.name,
      };
    });
  };

  React.useEffect(() => {
    const getVenues = async () => {
      const allVenues = await getAllVenues();
      setVenues(allVenues);
      if (authData?.user.role === UserRoleType.VenueEditor) {
        formik.setFieldValue('venueId', allVenues && allVenues[0].id);
      }
    };
    getVenues();
  }, []);

  const venuesForSelect = (venues: Venue[]) => {
    return venues.map((venue) => {
      return {
        value: venue.id,
        label: venue.name,
      };
    });
  };

  React.useEffect(() => {
    const getSelectedVenue = (): Venue[] => {
      if (!venues || !formik.values.venueId) return [];
      return venues.filter((venue) => venue.id.includes(formik.values.venueId));
    };
    getSelectedVenue().map((element) => {
      if (!element.commerces) return [];
      setSelectedVenueTimeZone(element.timeZoneIdentifier);
      return setVenueCommerces(element.commerces);
    });
  }, [formik.values.venueId]);

  return (
    <form className="Grid--layout">
      <div className="Grid--row">
        <div className="Grid--row">
          {venues &&
            (authData?.user?.role === UserRoleType.Admin || authData?.user.role === UserRoleType.CommerceEditor) && (
              <SelectField
                label={t('consumptionsReport-form-venueId-label')}
                name="venueId"
                placeholder={t('consumptionsReport-form-venueId-placeholder')}
                value={formik.values.venueId}
                options={venuesForSelect(venues)}
                isMulti={false}
                setFieldValue={formik.setFieldValue}
                onBlur={formik.handleBlur}
                touched={formik.touched.venueId}
                error={formik.errors.venueId}
                isClearable={false}
                backspaceRemovesValue={false}
                isDisabled={formik.isSubmitting}
                isSearchable={true}
              />
            )}
        </div>
        <div className="Grid--row">
          {(authData?.user?.role === UserRoleType.Admin || authData?.user?.role === UserRoleType.VenueEditor) && (
            <SelectField
              label={t('consumptionsReport-form-commerceId-label')}
              name="commerceIds"
              placeholder={t('consumptionsReport-form-commerceId-placeholder')}
              value={formik.values.commerceIds}
              options={venueCommerces ? commercesForSelect(venueCommerces) : []}
              isMulti={true}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              touched={formik.touched.commerceIds}
              error={formik.errors.commerceIds}
              isClearable={false}
              backspaceRemovesValue={false}
              isDisabled={formik.isSubmitting}
              isSearchable={true}
            />
          )}
        </div>
        <div className="Grid--columns">
          <TextField
            margin="dense"
            name="dateFrom"
            fullWidth
            label={t('consumptionsReport-form-dateFrom')}
            value={
              formik.values.dateFrom
                ? parseDateToFormik(moment(formik.values.dateFrom).toDate(), '')
                : formik.values.dateFrom
            }
            onChange={(event) => {
              formik.setFieldValue(
                'dateFrom',
                moment(moment(event.target.value).tz(selectedVenueTimeZone ?? '')).toDate(),
              );
            }}
            error={formik.touched.dateFrom && Boolean(formik.errors.dateFrom)}
            helperText={formik.touched.dateFrom && formik.errors.dateFrom}
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            name="dateTo"
            fullWidth
            label={t('consumptionsReport-form-dateTo')}
            value={
              formik.values.dateTo ? parseDateToFormik(moment(formik.values.dateTo).toDate(), '') : formik.values.dateTo
            }
            onChange={(event) => {
              formik.setFieldValue(
                'dateTo',
                moment(moment(event.target.value).tz(selectedVenueTimeZone ?? '')).toDate(),
              );
            }}
            error={formik.touched.dateTo && Boolean(formik.errors.dateTo)}
            helperText={formik.touched.dateTo && formik.errors.dateTo}
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="Grid--row">
          <Box marginTop="1rem">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="button"
              disabled={!formik.isValid || !formik.values.venueId || formik.isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
            >
              {t('consumptionsReport-form-search')}
            </Button>
          </Box>
        </div>
      </div>
    </form>
  );
};
