import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PaginationData from 'types/models/PaginationData';
import Event from 'types/models/Event';
import { parseDateTime } from 'utils/datetime';
import { getEvents } from 'services/eventServices';
import EditVenueContext from 'contexts/EditVenueContext';

export default (): JSX.Element => {
  const history = useHistory();
  const [paginationEvent, setPaginationEvent] = React.useState<PaginationData<Event>>();
  const { setSelectedEvent } = React.useContext(EditVenueContext);
  const { selectedVenue } = React.useContext(EditVenueContext);
  const { t } = useTranslation();

  const loadEvents = React.useCallback(async (venueId: string, page?: number) => {
    const paginationData = await getEvents(venueId, page);
    if (paginationData) setPaginationEvent(paginationData);
  }, []);

  React.useEffect(() => {
    if (selectedVenue) {
      loadEvents(selectedVenue.id);
    } else {
      history.push('/private/admin/venues');
    }
  }, []);

  const renderEditButton = (event: Event): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        aria-label="edit"
        onClick={() => {
          setSelectedEvent(event);
          history.push('events/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderContent = (paginationData: Event[]) => {
    return (
      <>
        {paginationData.map((event: Event) => (
          <TableRow key={event.id}>
            <TableCell component="th" scope="row">
              {event.name}
            </TableCell>
            <TableCell>{parseDateTime(event.eventStart)}</TableCell>
            <TableCell>{parseDateTime(event.eventEnd)}</TableCell>
            <TableCell>{renderEditButton(event)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          history.push('events/create');
        }}
      >
        {t('listEvents-page-create')}
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('listEvents-page-name')}</TableCell>
              <TableCell>{t('listEvents-page-begin')}</TableCell>
              <TableCell>{t('listEvents-page-end')}</TableCell>
              <TableCell>{t('listEvents-page-edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationEvent?.data && paginationEvent?.data.length > 0 ? (
              renderContent(paginationEvent.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listEvents-page-noEvents')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationEvent && (
        <TablePagination
          component="div"
          count={paginationEvent.total}
          page={paginationEvent.currentPage - 1}
          onPageChange={(_, page: number) => {
            if (selectedVenue) {
              loadEvents(selectedVenue.id, page + 1);
            }
          }}
          rowsPerPage={paginationEvent.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
