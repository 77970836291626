import React from 'react';
import { useHistory } from 'react-router-dom';
import FranchiseForm from 'forms/FranchiseForm';
import EditFranchiseContext from 'contexts/EditFranchiseContext';
import { getFranchise } from 'services/franchiseServices';
import Franchise from 'types/models/Franchise';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedFranchise } = React.useContext(EditFranchiseContext);
  const [completeFranchise, setCompleteFranchise] = React.useState<Franchise | null>(null);

  const load = React.useCallback(async (id: string) => {
    const franchise = await getFranchise(id);
    if (!franchise) {
      history.push('/private/admin/franchises');
      return;
    }
    setCompleteFranchise(franchise);
  }, []);

  React.useEffect(() => {
    if (!selectedFranchise) {
      history.push('/private/admin/franchises');
    } else {
      load(selectedFranchise.id);
    }
  }, [selectedFranchise]);

  if (!completeFranchise) return <></>;
  return <FranchiseForm franchise={completeFranchise} />;
};
