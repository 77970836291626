import axiosInstance from './axios';
import PaginationData from 'types/models/PaginationData';
import FirstDataConfig from 'types/models/FirstDataConfig';

export const getFDConfigs = async (page?: number): Promise<PaginationData<FirstDataConfig> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `payments/first-data/configs?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseConfigs(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeFDConfig = async (firstDataConfig: FirstDataConfig): Promise<FirstDataConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'payments/first-data/config',
      data: firstDataConfig,
    });
    return parseConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateFDConfig = async (firstDataConfig: FirstDataConfig): Promise<FirstDataConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `payments/first-data/config?_method=put`,
      data: firstDataConfig,
    });
    return parseConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseConfigs = (data: any): FirstDataConfig[] => {
  return data.map((item: any): FirstDataConfig => parseConfig(item));
};

const parseConfig = (data: any): FirstDataConfig => {
  return {
    id: data.id,
    description: data.description,
    store: data.fd_store,
    secret: data.fd_secret ?? '',
    timeZone: data.time_zone,
    currency: data.currency,
  };
};
