import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import MultipleImagesInputProps from 'types/components/MultipleImagesInputProps';
import Image from 'types/models/Image';
import 'styles/components/ImageInput.scss';

export default ({
  label,
  name,
  values,
  setFieldValue,
  isSubmitting,
  ImageType,
}: MultipleImagesInputProps): JSX.Element => {
  const [thumbs, setThumbs] = React.useState<any[]>(
    values.map((value) => value.preview ?? 'https://via.placeholder.com/700x300'),
  );

  const loadThumbs = async (values: Image<typeof ImageType>[]): Promise<any[]> => {
    return new Promise((resolve) => {
      if (values.length === 0) resolve([]);
      const newThumbs: any[] = [];
      values.forEach((value) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          newThumbs.push(reader.result);
          if (values.length === newThumbs.length) {
            resolve(newThumbs);
          }
        };
        if (value.data) reader.readAsDataURL(value.data);
      });
    });
  };

  React.useEffect(() => {
    if (values) {
      loadThumbs(values).then((newThumbs) => setThumbs(newThumbs));
    }
  }, [values]);

  return (
    <>
      {thumbs && (
        <div className="thumb-container">
          {thumbs.map((thumb: any, index: number) => {
            return (
              <div key={index}>
                <img src={thumb} height={200} width={200} />
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    if (!values) return;
                    values.splice(index, 1);
                    setFieldValue(name, [...values]);
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </div>
            );
          })}
        </div>
      )}
      <Button fullWidth variant="contained" color="secondary" component="label" disabled={isSubmitting}>
        {label}
        <input
          id={name}
          name={name}
          type="file"
          hidden
          multiple
          onChange={(event) => {
            if (!values) return;
            const files = event?.currentTarget?.files;
            if (files && files[0]) {
              values.push({ type: ImageType, data: files[0] });
              setFieldValue(name, [...values]);
            }
          }}
        />
      </Button>
    </>
  );
};
