import axiosInstance from './axios';
import Language from 'types/models/Language';

export const getLanguages = async (): Promise<Language[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `languages`,
    });
    return parseLanguages(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseLanguages = (languages: any[]): Language[] => {
  return languages.map((language: any) => parseLanguage(language));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseLanguage = (language: any): Language => {
  return {
    id: language.id,
    code: language.code,
    description: language.description,
  };
};
