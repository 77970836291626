import * as yup from 'yup';
import { enabledValidation } from 'utils/yup';

export default (): any =>
  yup.object({
    deliveryEnabled: enabledValidation,
    pickUpEnabled: enabledValidation,
    isLocalDelivery: enabledValidation,
    areas: yup.array(),
  });
