import React from 'react';
import { useHistory } from 'react-router-dom';
import BannerForm from 'forms/BannerForm';
import EditVenueContext from 'contexts/EditVenueContext';
import { getBanner } from 'services/bannerServices';
import { loadImages } from 'services/imageServices';
import Banner from 'types/models/Banner';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedBanner, selectedVenue } = React.useContext(EditVenueContext);
  const [completeBanner, setCompleteBanner] = React.useState<Banner | null>(null);

  const load = React.useCallback(async (id: string) => {
    const banner = await getBanner(id);
    if (!banner) {
      history.push('/private/admin/venues');
      return;
    } else {
      if (banner.images) {
        await loadImages(banner.images);
      }
    }
    setCompleteBanner(banner);
  }, []);

  React.useEffect(() => {
    if (!selectedBanner || !selectedVenue) {
      history.push('/private/admin/venues');
    } else {
      load(selectedBanner.id);
    }
  }, [selectedBanner, selectedVenue]);

  if (!completeBanner) return <></>;
  return <BannerForm banner={completeBanner} />;
};
