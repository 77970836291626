import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export default (t: TFunction): any =>
  yup.object({
    instagramToken: yup
      .string()
      .min(3, t('schema-venueSNSConfig-form-instagramToken-min'))
      .max(191, t('schema-venueSNSConfig-form-instagramToken-max')),
    twitterConfig: yup.string().length(36),
  });
