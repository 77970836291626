import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import EditMPCheckoutConfigContext from 'contexts/EditMPCheckoutConfigContext';
import MPCheckoutConfigForm from 'forms/MPCheckoutConfigForm';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedMPCheckoutConfig } = React.useContext(EditMPCheckoutConfigContext);
  if (!selectedMPCheckoutConfig) {
    history.push('/private/admin/payments/mercado-pago-checkout');
    return <></>;
  }

  return (
    <div>
      <MPCheckoutConfigForm config={selectedMPCheckoutConfig} />
      <Box display="flex" justifyContent="center" width={1}>
        <Button variant="contained" color="secondary" fullWidth onClick={() => history.go(-1)}>
          Back
        </Button>
      </Box>
    </div>
  );
};
