import axios from 'axios';
//import AsyncStorage from '@react-native-async-storage/async-storage';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosMultipart = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    /*
    const userData = await AsyncStorage.getItem(USER_DATA_KEY);
    */
    const userData = localStorage.getItem('auth-data');
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      config.headers.Authorization = parsedUserData?.token ? `Bearer ${parsedUserData.token}` : '';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosMultipart.interceptors.request.use(
  async (config) => {
    /*
    const userData = await AsyncStorage.getItem(USER_DATA_KEY);
    */
    const userData = localStorage.getItem('auth-data');
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      config.headers.Authorization = parsedUserData?.token ? `Bearer ${parsedUserData.token}` : '';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const authFailed = [401, 403].includes(error?.response?.status);
    if (authFailed) {
      /*
      await AsyncStorage.removeItem(USER_DATA_KEY);
      // Nagivate to Login
      */
    } else {
      return Promise.reject(error);
    }
  },
);

export { axiosInstance as default, axiosMultipart };
