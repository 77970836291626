import React from 'react';
import {
  FormControlLabel,
  Switch,
  Button,
  Typography,
  List,
  ListItem,
  TextField,
  Collapse,
  IconButton,
  ListSubheader,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AreasInputProps from 'types/components/AreasInputProps';
import { AddCircle, DeleteOutlineRounded, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import 'styles/components/AreasInput.scss';

export default ({ type, name, values, setFieldValue, isDisabled }: AreasInputProps): JSX.Element => {
  const [openCollapseFields, setOpenCollapseFields] = React.useState(0);
  const { t } = useTranslation();

  const handleCollapseFields = (index: number): void => {
    if (openCollapseFields === index) {
      setOpenCollapseFields(0);
    } else {
      setOpenCollapseFields(index);
    }
  };

  return (
    <>
      <div className="title-container">
        <Typography variant="h6" component="div">
          {`${t('areas-input-selector-typography')} ${type}`}
        </Typography>
        <div className="add-container">
          <Button
            variant="contained"
            color="primary"
            endIcon={<AddCircle />}
            onClick={() => {
              values.push({ id: '', description: '', fields: [] });
              setFieldValue('name', [...values]);
            }}
          >
            {t('areas-input-add-selector')}
          </Button>
        </div>
      </div>
      <List>
        {values.map((venueArea, index) => (
          <>
            <ListItem key={index}>
              <TextField
                margin="dense"
                id="description"
                name="description"
                label={t('areas-input-description')}
                value={venueArea.description}
                onChange={(e) => {
                  values[index].description = e.target.value.toString();
                  setFieldValue(name, [...values]);
                }}
                disabled={isDisabled}
              />
              <div className="area-buttons-container">
                <div className="area-button-element">
                  <Button
                    variant="contained"
                    color="secondary"
                    endIcon={index + 1 === openCollapseFields ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    onClick={() => handleCollapseFields(index + 1)}
                  >
                    {t('areas-input-view-fields')}
                  </Button>
                </div>
                <div className="area-button-element">
                  <Button
                    variant="outlined"
                    color="secondary"
                    endIcon={<DeleteOutlineRounded />}
                    onClick={() => {
                      values.splice(index, 1);
                      setFieldValue(name, [...values]);
                    }}
                  >
                    {t('areas-input-delete-selector')}
                  </Button>
                </div>
              </div>
            </ListItem>
            <Collapse in={index + 1 === openCollapseFields} timeout="auto" unmountOnExit={true}>
              <div className="field-button-element">
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<AddCircle />}
                  onClick={() => {
                    venueArea.fields.push({
                      id: '',
                      description: '',
                      placeholder: '',
                      isRequired: false,
                      isNumber: false,
                      maxNumberValue: '',
                      minNumberValue: '',
                      order: 0,
                    });
                    setFieldValue(name, [...values]);
                  }}
                >
                  {t('areas-input-add-field')}
                </Button>
              </div>
              {venueArea && venueArea.fields.length > 0 && (
                <List
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      {t('areas-input-fields')}
                    </ListSubheader>
                  }
                >
                  {venueArea.fields.map((field, index) => (
                    <ListItem key={index}>
                      <TextField
                        margin="dense"
                        id="description"
                        name="description"
                        label={t('areas-input-description')}
                        value={field.description}
                        onChange={(e) => {
                          venueArea.fields[index].description = e.target.value.toString();
                          setFieldValue(name, [...values]);
                        }}
                        disabled={isDisabled}
                      />
                      <TextField
                        margin="dense"
                        id="placeholder"
                        name="placeholder"
                        label={t('areas-input-placeholder')}
                        value={field.placeholder}
                        onChange={(e) => {
                          venueArea.fields[index].placeholder = e.target.value.toString();
                          setFieldValue(name, [...values]);
                        }}
                        disabled={isDisabled}
                      />
                      <FormControlLabel
                        id="isRequired"
                        name="isRequired"
                        control={
                          <Switch
                            checked={field.isRequired}
                            onChange={(e) => {
                              venueArea.fields[index].isRequired = e.target.checked;
                              setFieldValue(name, [...values]);
                            }}
                          />
                        }
                        label={t('areas-input-is-required')}
                        disabled={isDisabled}
                      />
                      <FormControlLabel
                        id="isNumber"
                        name="isNumber"
                        control={
                          <Switch
                            checked={field.isNumber}
                            onChange={(e) => {
                              venueArea.fields[index].isNumber = e.target.checked;
                              setFieldValue(name, [...values]);
                            }}
                          />
                        }
                        label={t('areas-input-is-number')}
                        disabled={isDisabled}
                      />
                      <TextField
                        margin="dense"
                        id="minNumberValue"
                        name="minNumberValue"
                        label={t('areas-input-value-min')}
                        type={'text'}
                        value={field.minNumberValue}
                        onChange={(e) => {
                          venueArea.fields[index].minNumberValue = venueArea.fields[index].isNumber
                            ? parseFloat(e.target.value).toString()
                            : e.target.value.toString();
                          setFieldValue(name, [...values]);
                        }}
                        disabled={isDisabled}
                      />
                      <TextField
                        margin="dense"
                        id="maxNumberValue"
                        name="maxNumberValue"
                        label={t('areas-input-value-max')}
                        type={'text'}
                        value={field.maxNumberValue}
                        onChange={(e) => {
                          venueArea.fields[index].maxNumberValue = venueArea.fields[index].isNumber
                            ? parseFloat(e.target.value).toString()
                            : e.target.value.toString();
                          setFieldValue(name, [...values]);
                        }}
                        disabled={isDisabled}
                      />
                      <TextField
                        margin="dense"
                        id="order"
                        name="order"
                        label={t('areas-input-order')}
                        type="number"
                        value={field.order}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        onChange={(e) => {
                          venueArea.fields[index].order = parseInt(e.target.value);
                          setFieldValue(name, [...values]);
                        }}
                        disabled={isDisabled}
                      />
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                          venueArea.fields.splice(index, 1);
                          setFieldValue(name, [...values]);
                        }}
                      >
                        <DeleteOutlineRounded />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </Collapse>
          </>
        ))}
      </List>
    </>
  );
};
