import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/SelectField';
import ImageInput from 'components/ImageInput';
import ConfirmDialog from 'components/ConfirmDialog';
import SelectedFieldOption from 'types/models/SelectFieldOption';
import Product from 'types/models/Product';
import Commerce from 'types/models/Commerce';
import Franchise from 'types/models/Franchise';
import Benefit from 'types/models/Benefit';
import BenefitType from 'types/enums/BenefitType';
import BenefitFormProps from 'types/forms/BenefitFormProps';
import { getCommerces } from 'services/commerceServices';
import { getAllFranchises } from 'services/franchiseServices';
import { getProductsByBenefitLocation } from 'services/productServices';
import { storeBenefit, updateBenefit } from 'services/benefitServices';
import { CreateForm, EditForm } from './schemas/BenefitForm';
import BenefitImageType from 'types/enums/BenefitImageType';

export default ({ benefit }: BenefitFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [franchisesForBenefit, setFranchisesForBenefit] = React.useState<Franchise[] | null>(null);
  const [commercesForBenefit, setCommercesForBenefit] = React.useState<Commerce[] | null>(null);
  const [productsForBenefit, setProductsForBenefit] = React.useState<Product[] | null>(null);
  const { t } = useTranslation();
  const benefitTypes = [
    { label: t('listBenefits-page-typeDiscount'), value: BenefitType.DiscountBenefit },
    { label: t('listBenefits-page-typeProduct'), value: BenefitType.ProductBenefit },
  ];
  const benefitLocations = [
    { label: t('benefit-form-commerce-location'), value: 'Commerce' },
    { label: t('benefit-form-franchise-location'), value: 'Franchise' },
  ];
  const banner = benefit?.images?.find((image) => image.type === BenefitImageType.banner);

  const itemsForSelect = (items: Commerce[] | Franchise[] | Product[]): SelectedFieldOption[] =>
    items.map((item) => ({
      label: item.name,
      value: item.id,
    }));

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('totalAmountOrdersMin', formik.values.totalAmountOrdersMin.toString());
    formData.append('totalAmountOrdersMax', formik.values.totalAmountOrdersMax.toString());
    formData.append('totalCantOrdersMin', formik.values.totalCantOrdersMin.toString());
    formData.append('totalCantOrdersMax', formik.values.totalCantOrdersMax.toString());
    formData.append('benefitType', formik.values.benefitType.toString());
    formData.append('benefitDescription', formik.values.benefitDescription);
    formData.append('benefitLocation', formik.values.benefitLocation);
    formData.append('benefitLocationId', formik.values.benefitLocationId);
    formData.append('benefitDiscountAmount', formik.values.benefitDiscountAmount.toString());
    formData.append('productBenefit', formik.values.productBenefit);

    if (formik.values.banner) {
      formData.append('banner', formik.values.banner as unknown as File);
    }
    try {
      let result: Benefit | null;

      if (benefit) {
        result = await updateBenefit(benefit.id, formData);
      } else {
        result = await storeBenefit(formData);
      }

      if (result) {
        history.go(-1);
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      totalAmountOrdersMin: benefit?.totalAmountOrdersMin ?? 0,
      totalAmountOrdersMax: benefit?.totalAmountOrdersMax ?? 0,
      totalCantOrdersMin: benefit?.totalCantOrdersMin ?? 0,
      totalCantOrdersMax: benefit?.totalCantOrdersMax ?? 0,
      benefitType: benefit?.benefitType ?? 0,
      benefitDescription: benefit?.benefitDescription ?? '',
      benefitDiscountAmount: benefit?.discountAmount ?? 0,
      benefitLocation: benefit?.franchiseId ? 'Franchise' : benefit?.commerceId ? 'Commerce' : '',
      benefitLocationId: benefit?.franchiseId ? benefit?.franchiseId : benefit?.commerceId ? benefit?.commerceId : '',
      productBenefit: benefit?.product?.id ?? '',
      banner: banner?.data ?? null,
    },
    validationSchema: benefit ? EditForm(t) : CreateForm(t),
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  React.useEffect(() => {
    const getLocations = async () => {
      if (!formik.values.benefitLocation) return;
      if (formik.values.benefitLocation === 'Franchise') {
        const franchises = await getAllFranchises();
        if (franchises) {
          setFranchisesForBenefit(franchises);
        }
      } else if (formik.values.benefitLocation === 'Commerce') {
        const commerces = await getCommerces();
        if (commerces) {
          setCommercesForBenefit(commerces);
        }
      }
    };
    getLocations();
  }, [formik.values.benefitLocation]);

  React.useEffect(() => {
    const getProductsForBenefit = async () => {
      if (formik.values.benefitType !== BenefitType.ProductBenefit) return;
      const products = await getProductsByBenefitLocation(
        formik.values.benefitLocation,
        formik.values.benefitLocationId,
      );
      if (products) {
        setProductsForBenefit(products);
      }
    };
    getProductsForBenefit();
  }, [formik.values.benefitLocationId]);

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="totalAmountOrdersMin"
          name="totalAmountOrdersMin"
          type="number"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          label={t('benefit-form-total-amount-orders-min-label')}
          value={formik.values.totalAmountOrdersMin}
          onChange={formik.handleChange}
          error={formik.touched.totalAmountOrdersMin && Boolean(formik.errors.totalAmountOrdersMin)}
          helperText={formik.touched.totalAmountOrdersMin && formik.errors.totalAmountOrdersMin}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="totalAmountOrdersMax"
          name="totalAmountOrdersMax"
          type="number"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          label={t('benefit-form-total-amount-orders-max-label')}
          value={formik.values.totalAmountOrdersMax}
          onChange={formik.handleChange}
          error={formik.touched.totalAmountOrdersMax && Boolean(formik.errors.totalAmountOrdersMax)}
          helperText={formik.touched.totalAmountOrdersMax && formik.errors.totalAmountOrdersMax}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="totalCantOrdersMin"
          name="totalCantOrdersMin"
          type="number"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          label={t('benefit-form-total-total_cant_orders_min-label')}
          value={formik.values.totalCantOrdersMin}
          onChange={formik.handleChange}
          error={formik.touched.totalCantOrdersMin && Boolean(formik.errors.totalCantOrdersMin)}
          helperText={formik.touched.totalCantOrdersMin && formik.errors.totalCantOrdersMin}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="totalCantOrdersMax"
          name="totalCantOrdersMax"
          type="number"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          label={t('benefit-form-total-total_cant_orders_max-label')}
          value={formik.values.totalCantOrdersMax}
          onChange={formik.handleChange}
          error={formik.touched.totalCantOrdersMax && Boolean(formik.errors.totalCantOrdersMax)}
          helperText={formik.touched.totalCantOrdersMax && formik.errors.totalCantOrdersMax}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="benefitDescription"
          name="benefitDescription"
          label={t('benefit-form-description')}
          value={formik.values.benefitDescription}
          onChange={formik.handleChange}
          error={formik.touched.benefitDescription && Boolean(formik.errors.benefitDescription)}
          helperText={formik.touched.benefitDescription && formik.errors.benefitDescription}
          disabled={formik.isSubmitting}
        />
        <SelectField
          name="benefitType"
          label={t('benefit-form-benefitType')}
          placeholder={t('benefit-form-select-benefitType')}
          value={formik.values.benefitType}
          options={benefitTypes}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.benefitType}
          error={formik.errors.benefitType}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <SelectField
          name="benefitLocation"
          label={t('benefit-form-location')}
          placeholder={t('benefit-form-placeholder')}
          value={formik.values.benefitLocation}
          options={benefitLocations}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.benefitType}
          error={formik.errors.benefitType}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        {formik.values.benefitLocation && (
          <SelectField
            name="benefitLocationId"
            label={t('benefit-form-location-options')}
            placeholder={t('benefit-form-select-location-options')}
            value={formik.values.benefitLocationId}
            options={
              formik.values.benefitLocation === 'Franchise' && franchisesForBenefit
                ? itemsForSelect(franchisesForBenefit)
                : formik.values.benefitLocation === 'Commerce' && commercesForBenefit
                ? itemsForSelect(commercesForBenefit)
                : []
            }
            isMulti={false}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.benefitType}
            error={formik.errors.benefitType}
            isClearable={true}
            backspaceRemovesValue={true}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        {formik.values.benefitType === BenefitType.ProductBenefit && productsForBenefit && (
          <SelectField
            name="productBenefit"
            label={t('benefit-form-products')}
            placeholder={t('benefit-form-select-products')}
            value={formik.values.productBenefit}
            options={itemsForSelect(productsForBenefit)}
            isMulti={false}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.benefitType}
            error={formik.errors.benefitType}
            isClearable={true}
            backspaceRemovesValue={true}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        {formik.values.benefitType === BenefitType.DiscountBenefit && (
          <TextField
            margin="dense"
            fullWidth
            id="benefitDiscountAmount"
            name="benefitDiscountAmount"
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            label={t('benefit-form-discount-amount')}
            value={formik.values.benefitDiscountAmount}
            onChange={formik.handleChange}
            error={formik.touched.benefitDiscountAmount && Boolean(formik.errors.benefitDiscountAmount)}
            helperText={formik.touched.benefitDiscountAmount && formik.errors.benefitDiscountAmount}
            disabled={formik.isSubmitting}
          />
        )}
        <ImageInput
          label={`${formik.values.banner || banner ? t('benefit-form-banner-edit') : t('benefit-form-banner-create')}`}
          name="banner"
          isSubmitting={formik.isSubmitting}
          value={formik.values.banner}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched.banner}
          errors={formik.errors.banner}
          preview={banner?.preview}
        />
        <div className="user-message">{t('benefit-form-banner-size')}</div>
        <div className="footer">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${benefit ? t('benefit-form-edit') : t('benefit-form-create')}`}
          </Button>
        </div>
      </form>
    </>
  );
};
