import axiosInstance, { axiosMultipart } from './axios';
import PaginationData from 'types/models/PaginationData';
import Product, { ClothingSizeTypeStock } from 'types/models/Product';
import ProductCategory from 'types/models/ProductCategory';
import { parseImages } from './imageServices';
import ProductCategoryData from 'types/requests/ProductCategoryData';
import ClothingSizeType from 'types/models/ClothingSizeType';

export const getProducts = async (commerceId: string, page?: number): Promise<PaginationData<Product> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `commerces/${commerceId}/products?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseProducts(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getAllSuggestedProducts = async (commerceId: string): Promise<null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `commerces/${commerceId}/suggested-products/all`,
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const searchProducts = async (
  commerceId: string,
  searchTerm: string,
  page?: number,
): Promise<PaginationData<Product> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `products/${commerceId}/search?page=${page ?? 1}&productName=${searchTerm}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseProducts(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getProductsByBenefitLocation = async (location: string, locationId: string): Promise<Product[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `products/get-products-for-benefit`,
      data: {
        location,
        locationId,
      },
    });
    return parseProducts(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getProduct = async (productId: string): Promise<Product | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `products/${productId}`,
    });
    return parseProduct(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeProduct = async (id: string, formData: FormData): Promise<Product | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `commerces/${id}/products`,
      data: formData,
    });
    return parseProduct(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateProduct = async (productId: string, formData: FormData): Promise<Product | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `products/${productId}?_method=put`,
      data: formData,
    });
    return parseProduct(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteProduct = async (productId: string): Promise<Product | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `products/${productId}`,
    });
    return parseProduct(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getProductCategoriesWithPagination = async (
  page?: number,
): Promise<PaginationData<ProductCategory> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `product-categories?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseProductCategories(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getProductCategories = async (): Promise<ProductCategory[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'product-categories/all',
    });
    return parseProductCategories(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getClothingSizeTypes = async (): Promise<ClothingSizeType[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'products/clothing-size-types/all',
    });
    return parseClothingSizeTypes(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

const parseClothingSizeTypes = (clothingSizeTypes: any[]) => {
  return clothingSizeTypes.map((clothingSizeType) => ({
    id: clothingSizeType.id,
    description: clothingSizeType.description,
  }));
};

export const getProductCategory = async (productCategoryid: string): Promise<ProductCategory | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `product-categories/${productCategoryid}`,
    });
    return parseProductCategory(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeProductCategory = async (productCategory: ProductCategoryData): Promise<ProductCategory | null> => {
  const body = formToFormData(productCategory);
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'product-categories',
      data: body,
    });
    return parseProductCategory(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateProductCategory = async (productCategory: ProductCategoryData): Promise<ProductCategory | null> => {
  try {
    const body = formToFormData(productCategory);
    const { data } = await axiosInstance({
      method: 'post',
      url: `product-categories/${productCategory.id}?_method=put`,
      data: body,
    });
    return parseProductCategory(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseProducts = (products: any): Product[] => {
  return products.map((product: any) => parseProduct(product));
};

const parseSuggestedProducts = (product: any): Product[] => {
  const products = product.suggested_by_products.map((product: any) => product.id);
  return products;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const parseProduct = (product: any): Product => {
  return {
    id: product.id,
    name: product.name,
    description: product.description,
    price: product.price,
    estimatedPreparationTime: product.estimated_preparation_time ?? undefined,
    enabled: product.enabled === 1,
    stock: product.stock,
    minimunStock: product.minimun_stock,
    isPromotion: product.is_promotion === 1,
    isClothing: product.is_clothing === 1,
    isSuggested: product.is_suggested === 1,
    suggestedType: product.suggested_type,
    suggestedProductsApplied: product.suggested_by_products ? parseSuggestedProducts(product) : undefined,
    minimumAmount: product.minimum_amount,
    showInList: product.show_in_list === 1,
    clothingSizeStocks: product.clothing_sizes_stocks ? parseClothingSizeTypeStocks(product.clothing_sizes_stocks) : [],
    maxirestCode: product.maxirest_code ?? undefined,
    promotionBannerBackgroundColor: product.promotion_banner_background_color ?? undefined,
    promotionBannerColorTitle: product.promotion_banner_color_title ?? undefined,
    category: product.category ? parseProductCategory(product.category) : undefined,
    images: product.images ? parseImages(product.images) : undefined,
  };
};

const parseClothingSizeTypeStocks = (clothingSizeStocks: any[]): ClothingSizeTypeStock[] => {
  return clothingSizeStocks.map((clothingSizeStock) => ({
    id: clothingSizeStock.clothing_size_type_id,
    description: clothingSizeStock.clothing_size_type.description,
    stock: clothingSizeStock.stock,
  }));
};

const formToFormData = (request: any): FormData => {
  const formData = new FormData();
  for (const key in request) {
    if (request[key]) {
      const value = request[key];
      formData.append(key, value);
    }
  }

  return formData;
};

const parseProductCategories = (categories: any): ProductCategory[] => {
  return categories.map((category: any): ProductCategory => parseProductCategory(category));
};

const parseProductCategory = (data: any): ProductCategory => {
  return {
    description: data.description,
    id: data.id,
    requiresIdentification: data.requires_identification,
    image: { type: 1, path: data.path },
  };
};
