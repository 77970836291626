import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommerceForm from 'forms/CommerceForm';
import EditCommerceContext from 'contexts/EditCommerceContext';
import AppContext from 'contexts/AppContext';
import { getCommerce } from 'services/commerceServices';
import { loadImages } from 'services/imageServices';
import Commerce from 'types/models/Commerce';
import UserRoleType from 'types/enums/UserRoleType';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedCommerce } = React.useContext(EditCommerceContext);
  const { authData } = React.useContext(AppContext);
  const [completeCommerce, setCompleteCommerce] = React.useState<Commerce | null>(null);
  const { t } = useTranslation();

  if (
    !authData ||
    (authData.user.role !== UserRoleType.Admin &&
      authData.user.role !== UserRoleType.FranchiseEditor &&
      UserRoleType.CommerceEditor &&
      selectedCommerce?.editorId !== authData.user.id)
  ) {
    return (
      <div className="error-container">
        <Typography color="error" variant="h3" align="center">
          {t('not-authorized-error-or-not-editor')}
        </Typography>
      </div>
    );
  }

  const load = React.useCallback(async (id: string) => {
    const commerce = await getCommerce(id);
    if (!commerce) {
      history.push('/private/commerces');
      return;
    } else {
      if (commerce.images) {
        await loadImages(commerce.images);
      }
    }
    setCompleteCommerce(commerce);
  }, []);

  React.useEffect(() => {
    if (!selectedCommerce) {
      history.push('/private/commerces');
    } else {
      load(selectedCommerce.id);
    }
  }, [selectedCommerce]);

  if (!completeCommerce) return <></>;
  return <CommerceForm commerce={completeCommerce} />;
};
