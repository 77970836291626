import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Franchise from 'types/models/Franchise';
import PaginationData from 'types/models/PaginationData';
import EditFranchiseContext from 'contexts/EditFranchiseContext';
import { getFranchisesWithPagination } from 'services/franchiseServices';
import AppContext from 'contexts/AppContext';

export default (): JSX.Element => {
  const history = useHistory();
  const { currentPageListNumber, setCurrentPageListNumber } = React.useContext(AppContext);
  const [paginationFranchise, setPaginationFranchise] = React.useState<PaginationData<Franchise>>();
  const { setSelectedFranchise } = React.useContext(EditFranchiseContext);
  const { t } = useTranslation();

  const loadFranchises = React.useCallback(async (page?: number) => {
    const paginationData = await getFranchisesWithPagination(page);
    if (paginationData) {
      setPaginationFranchise(paginationData);
    }
  }, []);

  React.useEffect(() => {
    if (currentPageListNumber) {
      loadFranchises(currentPageListNumber);
    }
  }, [currentPageListNumber]);

  const renderEditButton = (franchise: Franchise | null): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          setSelectedFranchise(franchise);
          history.push('franchises/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderContent = (paginationData: Franchise[]): JSX.Element => {
    return (
      <>
        {paginationData.map((franchise: Franchise) => (
          <TableRow key={franchise.id}>
            <TableCell component="th" scope="row">
              {franchise.name}
            </TableCell>
            <TableCell>{renderEditButton(franchise)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          history.push('/private/franchises/create');
        }}
      >
        {t('listFranchises-page-create')}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('listFranchises-page-name')}</TableCell>
              <TableCell>{t('listFranchises-page-edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationFranchise?.data && paginationFranchise?.data.length > 0 ? (
              renderContent(paginationFranchise.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listFranchises-page-noFranchises')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationFranchise && (
        <TablePagination
          component="div"
          count={paginationFranchise.total}
          page={paginationFranchise.currentPage - 1}
          onPageChange={(_, page: number) => {
            setCurrentPageListNumber(page + 1);
            loadFranchises(page + 1);
          }}
          rowsPerPage={paginationFranchise.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
