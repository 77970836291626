import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, Button, Menu, MenuItem, Typography, Select } from '@material-ui/core';
import { Menu as MenuIcon, AccountCircle } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import AppContext from 'contexts/AppContext';
import 'styles/components/AppHeader.scss';
import { getLanguages } from 'services/languageServices';
import Language from 'types/models/Language';
import UserRoleType from 'types/enums/UserRoleType';
import '../translation/config';

export default (): JSX.Element => {
  const history = useHistory();
  const { authData, setAuthData, setCurrentPageListNumber } = React.useContext(AppContext);
  const [toggleDrawer, setToggleDrawer] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [languages, setLanguages] = React.useState<Language[]>();
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();
  const role = authData?.user?.role;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToPage = (route: string) => {
    history.push(route);
    setToggleDrawer(false);
  };

  const loadLanguages = React.useCallback(async () => {
    const languages = await getLanguages();
    if (languages) setLanguages(languages);
  }, []);

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  React.useEffect(() => {
    loadLanguages();
  }, []);

  const loadLanguagesElement = (): JSX.Element => {
    return (
      <>
        <Select labelId="demo-simple-select-helper-label" displayEmpty>
          <MenuItem className="app-bar-title">{t('app-header-language')}</MenuItem>
          {languages?.map((language) => (
            <MenuItem
              onClick={() => {
                handleChangeLanguage(language.code);
              }}
              value={language.id}
              key={language.id}
            >
              {language.description}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <>
          {authData && (
            <>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setToggleDrawer(true);
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" className="app-bar-title">
                {t('app-header-title')}
              </Typography>
              <div>{loadLanguagesElement()}</div>
              <div>
                <IconButton onClick={handleMenu} color="inherit" edge="end">
                  <AccountCircle />
                </IconButton>
                <Menu id="menu-appbar" anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem
                    onClick={() => {
                      setAuthData(null);
                      localStorage.removeItem('auth-data');
                      handleClose();
                      return <Redirect to="/public/login" />;
                    }}
                  >
                    {t('app-header-logout')}
                  </MenuItem>
                </Menu>
              </div>
            </>
          )}
        </>
      </Toolbar>
      <Drawer
        anchor="left"
        open={toggleDrawer}
        onClose={() => {
          setToggleDrawer(false);
        }}
      >
        {(authData?.user?.role === UserRoleType.Admin || authData?.user.role === UserRoleType.VenueEditor) && (
          <>
            <Button
              onClick={() => {
                setCurrentPageListNumber(1);
                goToPage('/private/venues');
              }}
            >
              {t('app-header-venues')}
            </Button>
            <Button
              onClick={() => {
                setCurrentPageListNumber(1);
                goToPage('/private/benefits');
              }}
            >
              {t('app-header-benefits')}
            </Button>
          </>
        )}
        {(authData?.user?.role === UserRoleType.Admin || authData?.user?.role === UserRoleType.FranchiseEditor) && (
          <Button
            onClick={() => {
              setCurrentPageListNumber(1);
              goToPage('/private/franchises');
            }}
          >
            {t('app-header-franchise')}
          </Button>
        )}
        <Button
          onClick={() => {
            setCurrentPageListNumber(1);
            goToPage('/private/commerces');
          }}
        >
          {t('app-header-commerces')}
        </Button>
        {authData?.user?.role === UserRoleType.Admin && (
          <>
            <Button
              onClick={() => {
                setCurrentPageListNumber(1);
                goToPage('/private/users');
              }}
            >
              {t('app-header-users')}
            </Button>
            <Button
              onClick={() => {
                setCurrentPageListNumber(1);
                goToPage('/private/admin/commerce-categories');
              }}
            >
              {t('app-header-commerces-categories')}
            </Button>

            <Button
              onClick={() => {
                setCurrentPageListNumber(1);
                goToPage('/private/admin/product-categories');
              }}
            >
              {t('app-header-products')}
            </Button>

            <Button
              onClick={() => {
                goToPage('/private/admin/payments');
              }}
            >
              {t('app-payment-methods')}
            </Button>
            <Button
              onClick={() => {
                setCurrentPageListNumber(1);
                goToPage('/private/admin/twitter-configs');
              }}
            >
              {t('app-header-twitter')}
            </Button>
          </>
        )}
        {(role === UserRoleType.Admin || role === UserRoleType.VenueEditor) && (
          <Button
            onClick={() => {
              goToPage('/private/protocol-orders');
            }}
          >
            {t('app-header-orders')}
          </Button>
        )}
        <Button
          onClick={() => {
            goToPage('/private/reporting');
          }}
        >
          {t('app-header-reports')}
        </Button>
      </Drawer>
    </AppBar>
  );
};
