import moment from 'moment-timezone';
import 'moment/locale/es';

moment.locale('es');

export const parseDateTime = (date: Date): string => {
  return moment(date).format('DD [de] MMMM HH:mm');
};

export const parseDateTimeLumen = (date: Date, venueTimeZone?: string): string => {
  if (venueTimeZone) {
    return moment(date).tz(venueTimeZone).format('yyyy-MM-DD HH:mm:ss');
  } else {
    return moment(date).format('yyyy-MM-DD HH:mm:ss');
  }
};

export const parseDateToFormik = (date: Date, venueTimeZone?: string): string => {
  if (venueTimeZone) {
    return moment(date).tz(venueTimeZone).format('yyyy-MM-DD[T]HH:mm');
  } else {
    return moment(date).format('yyyy-MM-DD[T]HH:mm');
  }
};
