import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PaginationData from 'types/models/PaginationData';
import FirstDataConfig from 'types/models/FirstDataConfig';
import EditFDConfigContext from 'contexts/EditFDConfigContext';
import { getFDConfigs } from 'services/firstDataServices';

export default (): JSX.Element => {
  const history = useHistory();
  const [paginationFirstDataConfig, setPaginationFirstDataConfigs] = useState<PaginationData<FirstDataConfig> | null>(
    null,
  );
  const { setSelectedFDConfig } = React.useContext(EditFDConfigContext);
  const { t } = useTranslation();

  const goToPage = (url: string): void => {
    history.push(url);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const loadData = React.useCallback(async (page?: number) => {
    const paginationData = await getFDConfigs(page);
    if (paginationData) setPaginationFirstDataConfigs(paginationData);
  }, []);

  const renderEditButton = (firstDataConfig: FirstDataConfig): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          setSelectedFDConfig(firstDataConfig);
          history.push('first-data/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderContent = (paginationData: FirstDataConfig[]): JSX.Element => {
    return (
      <>
        {paginationData.map((firstDataConfig: FirstDataConfig) => (
          <TableRow key={firstDataConfig.description}>
            <TableCell component="th" scope="row">
              {firstDataConfig.description}
            </TableCell>
            <TableCell>{firstDataConfig.store}</TableCell>
            <TableCell>{firstDataConfig.timeZone}</TableCell>
            <TableCell>{firstDataConfig.currency}</TableCell>
            <TableCell>{renderEditButton(firstDataConfig)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <div>
      <div className="vertical-bottom-space">
        <Button variant="contained" color="primary" fullWidth onClick={() => goToPage('first-data/create')}>
          {t('listFDConfigs-page-create')}
        </Button>
      </div>
      <div className="vertical-bottom-space">
        <Button variant="contained" color="secondary" fullWidth onClick={() => history.go(-1)}>
          Back
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('listFDConfigs-page-description')}</TableCell>
              <TableCell>{t('listFDConfigs-page-store')}</TableCell>
              <TableCell>{t('listFDConfigs-page-timeZone')}</TableCell>
              <TableCell>{t('listFDConfigs-page-currency')}</TableCell>
              <TableCell>{t('listFDConfigs-page-edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationFirstDataConfig?.data ? (
              renderContent(paginationFirstDataConfig.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listFDConfigs-page-noConfigs')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationFirstDataConfig && (
        <TablePagination
          component="div"
          count={paginationFirstDataConfig.total}
          page={paginationFirstDataConfig.currentPage - 1}
          onPageChange={(_, page: number) => {
            loadData(page + 1);
          }}
          rowsPerPage={paginationFirstDataConfig.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </div>
  );
};
