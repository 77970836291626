import React from 'react';
import { useHistory } from 'react-router-dom';
import CommerceDeliveryConfigForm from 'forms/CommerceDeliveryConfigForm';
import EditCommerceContext from 'contexts/EditCommerceContext';
import { getCommerce } from 'services/commerceServices';
import Commerce from 'types/models/Commerce';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedCommerce } = React.useContext(EditCommerceContext);
  const [completeCommerce, setCompleteCommerce] = React.useState<Commerce | null>(null);

  const load = React.useCallback(async (id: string) => {
    const commerce = await getCommerce(id);
    if (!commerce) {
      history.push('/private/commerces');
      return;
    }
    setCompleteCommerce(commerce);
  }, []);

  React.useEffect(() => {
    if (selectedCommerce) {
      load(selectedCommerce.id);
    } else {
      history.push('/private/commerces');
    }
  }, [selectedCommerce]);

  if (!completeCommerce) return <></>;
  return <CommerceDeliveryConfigForm commerce={completeCommerce} />;
};
