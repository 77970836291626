import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Product from 'types/models/Product';
import PaginationData from 'types/models/PaginationData';
import { getProducts, searchProducts } from 'services/productServices';
import EditCommerceContext from 'contexts/EditCommerceContext';
import 'styles/pages/ListProductsPage.scss';

export default (): JSX.Element => {
  const history = useHistory();
  const [paginationProduct, setPaginationProduct] = React.useState<PaginationData<Product>>();
  const [searchProductText, setSearchProductText] = React.useState('');
  const { setSelectedProduct } = React.useContext(EditCommerceContext);
  const { selectedCommerce } = React.useContext(EditCommerceContext);
  const { t } = useTranslation();

  const loadProducts = React.useCallback(async (id: string, page?: number) => {
    const paginationData = await getProducts(id, page);
    if (paginationData) setPaginationProduct(paginationData);
  }, []);

  React.useEffect(() => {
    if (selectedCommerce) {
      loadProducts(selectedCommerce.id);
    } else {
      history.push('/private/commerces');
    }
  }, []);

  const handleSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchProductText(event.target.value);
  };

  const loadSearchData = React.useCallback(async (commerceId: string, productSearchTerm: string, page?: number) => {
    const paginationData: PaginationData<Product> | null = await searchProducts(commerceId, productSearchTerm, page);
    if (paginationData) {
      setPaginationProduct(paginationData);
    }
  }, []);

  React.useEffect(() => {
    if (!searchProductText && !paginationProduct) return;
    if (!searchProductText && paginationProduct) {
      if (selectedCommerce) {
        loadProducts(selectedCommerce?.id);
      }
    }
  }, [searchProductText]);

  const renderEditButton = (product: Product): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          setSelectedProduct(product);
          history.push('products/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderContent = (paginationData: Product[]) => {
    return (
      <>
        {paginationData.map((product: Product) => (
          <TableRow key={product.id}>
            <TableCell component="th" scope="row">
              {product.name}
            </TableCell>
            <TableCell>{product.description}</TableCell>
            <TableCell>{product.price}</TableCell>
            <TableCell>{product.isPromotion ? t('yes') : t('no')}</TableCell>
            <TableCell>{product.enabled ? t('yes') : t('no')}</TableCell>
            <TableCell>{`${
              product.estimatedPreparationTime
                ? `${product.estimatedPreparationTime} ${t('listProduct-page-minutes')}`
                : ''
            }`}</TableCell>
            <TableCell>{renderEditButton(product)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="search-container">
        <div className="search-bar-container">
          <TextField
            id="search"
            name="searchTerm"
            label={t('listProduct-page-search-placeholder')}
            value={searchProductText}
            onChange={handleSearchTerm}
            margin="dense"
            fullWidth
          />
        </div>
        <div className="search-button-element">
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            disabled={!searchProductText}
            onClick={() => {
              if (selectedCommerce) {
                loadSearchData(selectedCommerce.id, searchProductText);
              }
            }}
          >
            {t('listProduct-page-search')}
          </Button>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          history.push('products/create');
        }}
      >
        {t('listProduct-page-create')}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('listProduct-page-name')}</TableCell>
              <TableCell>{t('listProduct-page-description')}</TableCell>
              <TableCell>{t('listProduct-page-price')}</TableCell>
              <TableCell>{t('listProduct-page-isPromotion')}</TableCell>
              <TableCell>{t('listProduct-page-enabled')}</TableCell>
              <TableCell>{t('listProduct-page-timeOfPreparation')}</TableCell>
              <TableCell>{t('listProduct-page-edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationProduct?.data && paginationProduct?.data.length > 0 ? (
              renderContent(paginationProduct.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listProduct-page-noProducts')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationProduct && (
        <TablePagination
          component="div"
          count={paginationProduct.total}
          page={paginationProduct.currentPage - 1}
          onPageChange={(_, page: number) => {
            if (selectedCommerce) {
              loadProducts(selectedCommerce.id, page + 1);
            }
          }}
          rowsPerPage={paginationProduct.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
