import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export default (t: TFunction): any =>
  yup.object({
    description: yup.string().required(t('schema-fdConfig-form-descrption-required')),
    store: yup.number().positive().integer().required(t('schema-fdConfig-form-store-required')),
    secret: yup.string().required(t('schema-fdConfig-form-secret-required')),
    timeZone: yup.string(),
    currency: yup.string().required(t('schema-fdConfig-form-currency-required')),
  });
