import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import EditMPConfigContext from 'contexts/EditMPConfigContext';
import MPConfigForm from 'forms/MPConfigForm';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedMPConfig } = React.useContext(EditMPConfigContext);
  if (!selectedMPConfig) {
    history.push('/private/admin/payments/mercado-pago');
    return <></>;
  }

  return (
    <div>
      <MPConfigForm config={selectedMPConfig} />
      <Box display="flex" justifyContent="center" width={1}>
        <Button variant="contained" color="secondary" fullWidth onClick={() => history.go(-1)}>
          Back
        </Button>
      </Box>
    </div>
  );
};
