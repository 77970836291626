import * as yup from 'yup';
import { imageValidation, enabledValidation, regexColor, validateTimeZoneIdentifiers } from 'utils/yup';
import { TFunction } from 'i18next';

export const CreateForm = (t: TFunction): any =>
  yup.object().shape({
    name: yup
      .string()
      .required(t('schema-venue-form-name-required'))
      .min(3, t('schema-venue-form-name-min'))
      .max(191, t('schema-venue-form-name-max')),
    code: yup
      .string()
      .required(t('schema-venue-form-code-required'))
      .min(3, t('schema-venue-form-code-min'))
      .max(32, t('schema-venue-form-code-max')),
    appColor: yup.string().matches(regexColor, t('schema-venue-form-appColor-matches')),
    appSecondaryColor: yup.string().matches(regexColor, t('schema-venue-form-appColor-matches')),
    wallioUrl: yup
      .string()
      .min(3, t('schema-venue-form-wallio-url-min'))
      .max(191, t('schema-venue-form-wallio-url-max'))
      .url(t('schema-venue-form-wallio-url')),
    wallioTitle: yup.string(),
    logo: imageValidation(t('schema-venue-form-logo'), true),
    map: imageValidation(t('schema-venue-form-map'), false),
    useDifferentIconSet: enabledValidation,
    logoForTinting: yup.mixed().when('useDifferentIconSet', {
      is: true,
      then: imageValidation(t('schema-venue-form-logoForTinting'), true),
    }),
    venueMap: imageValidation(t('schema-venue-form-venueMap'), false),
    banner: imageValidation('Banner', false),
    language: yup.string().required(t('schema-venue-form-language-required')),
    billingRange: yup
      .number()
      .integer(t('schema-venue-form-integer-billing-range-integer'))
      .required(t('schema-venue-form-integer-billing-range-required')),
    timeZoneIdentifier: validateTimeZoneIdentifiers(),
  });

export const EditForm = (t: TFunction, hasBettingSection: boolean): any =>
  yup.object().shape({
    name: yup
      .string()
      .required(t('schema-venue-form-name-required'))
      .min(3, t('schema-venue-form-name-min'))
      .max(191, t('schema-venue-form-name-max')),
    code: yup
      .string()
      .required(t('schema-venue-form-code-required'))
      .min(3, t('schema-venue-form-code-min'))
      .max(32, t('schema-venue-form-code-max')),
    wallioUrl: yup
      .string()
      .min(3, t('schema-venue-form-wallio-url-min'))
      .max(191, t('schema-venue-form-wallio-url-max'))
      .url(t('schema-venue-form-wallio-url')),
    wallioTitle: yup.string(),
    bettingTitle: hasBettingSection
      ? yup.string().required(t('schema-venue-form-betting-title-required'))
      : yup.string(),
    bettingUrl: hasBettingSection
      ? yup.string().url(t('schema-venue-form-betting-url')).required(t('schema-venue-form-betting-url-required'))
      : yup.string(),
    logo: imageValidation(t('schema-venue-form-logo'), true),
    map: imageValidation(t('schema-venue-form-map'), false),
    commerces: yup.array().of(yup.string()),
    venueEditor: yup.string().required(t('schema-venue-form-venue-editor-required')),
    enabled: enabledValidation,
    useDifferentIconSet: enabledValidation,
    logoForTinting: yup.mixed().when('useDifferentIconSet', {
      is: true,
      then: imageValidation(t('schema-venue-form-logoForTinting'), true),
    }),
    venueMap: imageValidation(t('schema-venue-form-venueMap'), false),
    downloadBanner: imageValidation(t('schema-venue-form-downloadBanner'), true),
    banner: imageValidation('Banner', false),
    cloverMiniBackgroundImage: imageValidation(t('schema-venue-form-cloverMiniBackground'), false),
    cloverMiniLowerBanner: imageValidation(t('schema-venue-form-cloverMiniLowerBanner'), false),
    cloverFlexBackgroundImage: imageValidation(t('schema-venue-form-cloverFlexBackground'), false),
    cloverFlexLowerBanner: imageValidation(t('schema-venue-form-cloverFlexLowerBanner'), false),
    language: yup.string().required(t('schema-venue-form-language-required')),
    billingRange: yup
      .number()
      .integer(t('schema-venue-form-integer-billing-range-integer'))
      .required(t('schema-venue-form-integer-billing-range-required')),
    entityIdentificator: yup.number().integer(t('schema-venue-form-entity-identificator-integer')),
    sections: yup.array().of(yup.string()).min(1, 'Las secciones son requeridas'),
    timeZoneIdentifier: validateTimeZoneIdentifiers(),
  });
