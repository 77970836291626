/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance, { axiosMultipart } from './axios';
import PaginationData from 'types/models/PaginationData';
import Benefit from 'types/models/Benefit';
import { parseProduct } from './productServices';
import { parseImages } from './imageServices';

export const getBenefitsWithPagination = async (page?: number): Promise<PaginationData<Benefit> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `benefits?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseBenefits(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeBenefit = async (formData: FormData): Promise<Benefit | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: 'benefits',
      data: formData,
    });
    return parseBenefit(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getBenefit = async (benefitId: string): Promise<Benefit | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `benefits/${benefitId}`,
    });
    return parseBenefit(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateBenefit = async (benefitId: string, formData: FormData): Promise<Benefit | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `benefits/${benefitId}?_method=put`,
      data: formData,
    });
    return parseBenefit(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseBenefits = (benefits: any): Benefit[] => {
  return benefits.map((benefit: any): Benefit => parseBenefit(benefit));
};

const parseBenefit = (benefit: any): Benefit => ({
  id: benefit.id,
  totalAmountOrdersMin: benefit.total_amount_orders_min,
  totalAmountOrdersMax: benefit.total_amount_orders_max,
  totalCantOrdersMin: benefit.total_cant_orders_min,
  totalCantOrdersMax: benefit.total_cant_orders_max,
  benefitType: benefit.type_id,
  benefitDescription: benefit.benefit_description,
  discountAmount: benefit.discount_amount ?? undefined,
  product: benefit.product ? parseProduct(benefit.product) : undefined,
  images: benefit.images ? parseImages(benefit.images) : undefined,
  commerceId: benefit.commerce_id ?? undefined,
  franchiseId: benefit.franchise_id ?? undefined,
});
