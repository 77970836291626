import React from 'react';
import { useHistory } from 'react-router-dom';
import EventForm from 'forms/EventForm';
import EditVenueContext from 'contexts/EditVenueContext';
import { getEvent } from 'services/eventServices';
import { loadImages } from 'services/imageServices';
import Event from 'types/models/Event';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedEvent, selectedVenue } = React.useContext(EditVenueContext);
  const [completeEvent, setCompleteEvent] = React.useState<Event | null>(null);

  const load = React.useCallback(async (id: string) => {
    const event = await getEvent(id);
    if (!event) {
      history.push('/private/admin/venues');
      return;
    } else {
      if (event.images) {
        await loadImages(event.images);
      }
    }
    setCompleteEvent(event);
  }, []);

  React.useEffect(() => {
    if (!selectedEvent || !selectedVenue) {
      history.push('/private/admin/venues');
    } else {
      load(selectedEvent.id);
    }
  }, [selectedEvent, selectedVenue]);

  if (!completeEvent) return <></>;
  return <EventForm event={completeEvent} />;
};
