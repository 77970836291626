import axiosInstance, { axiosMultipart } from './axios';
import PaginationData from 'types/models/PaginationData';
import Article from 'types/models/Article';
import ArticleDetail from 'types/models/ArticleDetail';
import { parseImages } from './imageServices';

export const getArticles = async (venueId: string, page?: number): Promise<PaginationData<Article> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `venues/${venueId}/articles?page=${page ?? 1}&perPage=${10}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseArticles(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getArticle = async (articleId: string): Promise<Article | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `articles/${articleId}`,
    });
    return parseArticle(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeArticle = async (venueId: string, formData: FormData): Promise<Article | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `venues/${venueId}/articles`,
      data: formData,
    });
    return parseArticle(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateArticle = async (articleId: string, formData: FormData): Promise<Article | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `articles/${articleId}?_method=put`,
      data: formData,
    });
    return parseArticle(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteArticle = async (articleId: string): Promise<Article | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `articles/${articleId}`,
    });
    return parseArticle(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseArticles = (articles: any): Article[] => {
  return articles.map((article: any) => parseArticle(article));
};

const parseArticle = (article: any): Article => {
  return {
    id: article.id,
    title: article.title,
    description: article.description,
    date: article.date,
    detail: article.detail ? parseArticleDetail(article.detail) : undefined,
    images: article.images ? parseImages(article.images) : undefined,
  };
};

const parseArticleDetail = (detail: any): ArticleDetail => {
  return {
    id: detail.id,
    body: detail.body,
  };
};
