import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import EditCommerceCategoriesContext from 'contexts/EditCommerceCategoriesContext';
import CommerceCategory from 'types/models/CommerceCategory';
import ListCommerceCategoriesPage from 'pages/private/admin/ListCommerceCategoriesPage';
import CreateCommerceCategoriesPage from 'pages/private/admin/CreateCommerceCategoriesPage';
import EditCommerceCategoriesPage from 'pages/private/admin/EditCommerceCategoriesPage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedCommerceCategory, setSelectedCommerceCategory] = React.useState<CommerceCategory | null>(null);

  return (
    <EditCommerceCategoriesContext.Provider value={{ selectedCommerceCategory, setSelectedCommerceCategory }}>
      <Switch>
        <Route exact={true} path={`${match.url}`} component={ListCommerceCategoriesPage} />
        <Route path={`${match.url}/create`} component={CreateCommerceCategoriesPage} />
        <Route path={`${match.url}/edit`} component={EditCommerceCategoriesPage} />
        <Redirect to="/private/welcome" />
      </Switch>
    </EditCommerceCategoriesContext.Provider>
  );
};
