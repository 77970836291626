import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PaginationData from 'types/models/PaginationData';
import Banner from 'types/models/Banner';
import { getBanners } from 'services/bannerServices';
import EditVenueContext from 'contexts/EditVenueContext';

export default (): JSX.Element => {
  const history = useHistory();
  const [paginationBanner, setPaginationBanner] = React.useState<PaginationData<Banner>>();
  const { setSelectedBanner } = React.useContext(EditVenueContext);
  const { selectedVenue } = React.useContext(EditVenueContext);
  const { t } = useTranslation();

  const loadBanners = React.useCallback(async (venueId: string, page?: number) => {
    const paginationData = await getBanners(venueId, page);
    if (paginationData) setPaginationBanner(paginationData);
  }, []);

  React.useEffect(() => {
    if (selectedVenue) {
      loadBanners(selectedVenue.id);
    } else {
      history.push('/private/admin/venues');
    }
  }, []);

  const renderEditButton = (banner: Banner): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        aria-label="edit"
        onClick={() => {
          setSelectedBanner(banner);
          history.push('banners/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderContent = (paginationData: Banner[]) => {
    return (
      <>
        {paginationData.map((banner: Banner) => (
          <TableRow key={banner.id}>
            <TableCell component="th" scope="row">
              {banner.title}
            </TableCell>
            <TableCell>{banner.description}</TableCell>
            <TableCell>{renderEditButton(banner)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          history.push('banners/create');
        }}
      >
        {t('listBanners-page-create')}
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('listBanners-page-title')}</TableCell>
              <TableCell>{t('listBanners-page-edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationBanner?.data && paginationBanner?.data.length > 0 ? (
              renderContent(paginationBanner.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listEvents-page-noEvents')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationBanner && (
        <TablePagination
          component="div"
          count={paginationBanner.total}
          page={paginationBanner.currentPage - 1}
          onPageChange={(_, page: number) => {
            if (selectedVenue) {
              loadBanners(selectedVenue.id, page + 1);
            }
          }}
          rowsPerPage={paginationBanner.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
