import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import EditMPPointConfigContext from 'contexts/EditMPPointConfigContext';
import MPPointConfigForm from 'forms/MPPointConfigForm';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedMPPointConfig } = React.useContext(EditMPPointConfigContext);
  if (!selectedMPPointConfig) {
    history.push('/private/admin/payments/mercado-pago-point');
    return <></>;
  }

  return (
    <div>
      <MPPointConfigForm config={selectedMPPointConfig} />
      <Box display="flex" justifyContent="center" width={1}>
        <Button variant="contained" color="secondary" fullWidth onClick={() => history.go(-1)}>
          Back
        </Button>
      </Box>
    </div>
  );
};
