import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export default (t: TFunction): any =>
  yup.object().shape({
    email: yup.string().email(t('schema-login-form-email-email')).required(t('schema-login-form-email-required')),
    password: yup
      .string()
      .required(t('schema-login-form-password-required'))
      .min(6, t('schema-login-form-password-min'))
      .max(32, t('schema-login-form-password-max'))
      .matches(/^\S*$/, t('schema-login-form-password-matches')),
  });
