import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export default (t: TFunction): any =>
  yup.object({
    description: yup.string().required(t('schema-MPConfig-form-description-required')),
    currencyCode: yup
      .string()
      .required(t('schema-MPCheckoutConfig-form-currency-code-required'))
      .max(3, t('schema-MPCheckoutConfig-form-currency-code-max-characters')),
    mpApiUrl: yup
      .string()
      .url(t('schema-MPCheckoutConfig-form-api-url-valid'))
      .required(t('schema-MPCheckoutConfig-form-api-url-required')),
    publicKey: yup.string().required(t('schema-MPCheckoutConfig-form-public-key-required')),
    token: yup.string().required(t('schema-MPConfig-form-token-required')),
  });
