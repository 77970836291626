import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialog';
import { storeCloverPointConfig, updateCloverPointConfig } from '../services/cloverServices';
import CloverPointConfigFormProps from '../types/forms/CloverPointConfigFormProps';
import CloverPointConfigForm from './schemas/CloverPointConfigForm';

export default ({ config }: CloverPointConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    try {
      const data = config
        ? await updateCloverPointConfig(config.id, { ...formik.values })
        : await storeCloverPointConfig({ ...formik.values });
      if (data) {
        history.push('/private/admin/payments/clover-point');
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      id: config?.id ?? '',
      description: config?.description ?? '',
      accessToken: config?.accessToken ?? '',
      applicationId: config?.applicationId ?? '',
      cloverServer: config?.cloverServer ?? '',
      merchantId: config?.merchantId ?? '',
      friendlyId: config?.friendlyId ?? '',
    },
    validationSchema: CloverPointConfigForm(t),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });

  return (
    <div>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="description"
            name="description"
            type="string"
            label={t('cloverPointConfig-form-description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="token"
            name="accessToken"
            label={t('cloverPointConfig-form-accessToken')}
            type="password"
            value={formik.values.accessToken}
            onChange={formik.handleChange}
            error={formik.touched.accessToken && Boolean(formik.errors.accessToken)}
            helperText={formik.touched.accessToken && formik.errors.accessToken}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="applicationId"
            name="applicationId"
            label={t('cloverPointConfig-form-applicationId')}
            type="password"
            value={formik.values.applicationId}
            onChange={formik.handleChange}
            error={formik.touched.applicationId && Boolean(formik.errors.applicationId)}
            helperText={formik.touched.applicationId && formik.errors.applicationId}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="cloverServer"
            name="cloverServer"
            label={t('cloverPointConfig-form-cloverServer')}
            type="string"
            value={formik.values.cloverServer}
            onChange={formik.handleChange}
            error={formik.touched.cloverServer && Boolean(formik.errors.cloverServer)}
            helperText={formik.touched.cloverServer && formik.errors.cloverServer}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="merchantId"
            name="merchantId"
            label={t('cloverPointConfig-form-merchantId')}
            type="password"
            value={formik.values.merchantId}
            onChange={formik.handleChange}
            error={formik.touched.merchantId && Boolean(formik.errors.merchantId)}
            helperText={formik.touched.merchantId && formik.errors.merchantId}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="friendlyId"
            name="friendlyId"
            label={t('cloverPointConfig-form-friendlyId')}
            type="string"
            value={formik.values.friendlyId}
            onChange={formik.handleChange}
            error={formik.touched.friendlyId && Boolean(formik.errors.friendlyId)}
            helperText={formik.touched.friendlyId && formik.errors.friendlyId}
          />
        </div>
        {config && (
          <div className="vertical-bottom-space">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push(`/private/admin/payments/clover-point/posnets`)}
            >
              {t('clover-point-manageCloverPoints')}
            </Button>
          </div>
        )}
        <div className="vertical-bottom-space">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {`${config ? t('cloverPointConfig-form-edit') : t('cloverPointConfig-form-create')}`}
          </Button>
        </div>
      </form>
    </div>
  );
};
