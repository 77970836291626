import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { getVenues } from 'services/venuesServices';
import PaginationData from 'types/models/PaginationData';
import Venue from 'types/models/Venue';
import AppContext from 'contexts/AppContext';
import EditVenueContext from 'contexts/EditVenueContext';
import UserRoleType from 'types/enums/UserRoleType';

export default (): JSX.Element => {
  const { authData, currentPageListNumber, setCurrentPageListNumber } = React.useContext(AppContext);
  const history = useHistory();
  const [paginationVenue, setPaginationVenue] = React.useState<PaginationData<Venue>>();
  const { setSelectedVenue } = React.useContext(EditVenueContext);
  const { t } = useTranslation();

  const loadData = React.useCallback(async (page?: number) => {
    const paginationData = await getVenues(page);
    if (paginationData) setPaginationVenue(paginationData);
  }, []);

  React.useEffect(() => {
    if (currentPageListNumber) {
      loadData(currentPageListNumber);
    }
  }, [currentPageListNumber]);

  const renderEditButton = (venue: Venue): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          if (!venue) return;
          setSelectedVenue(venue);
          history.push(`/private/venues/${venue.id}/edit`);
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderContent = (paginationData: Venue[]): JSX.Element => {
    return (
      <>
        {paginationData.map((venue: Venue) => (
          <TableRow key={venue.id}>
            <TableCell component="th" scope="row">
              {venue.name}
            </TableCell>
            <TableCell>{venue.code}</TableCell>
            <TableCell>{venue.enabled ? t('yes') : t('no')}</TableCell>
            <TableCell>{renderEditButton(venue)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      {authData?.user.role === UserRoleType.Admin && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            history.push('/private/venues/create');
          }}
        >
          {t('listVenues-page-create')}
        </Button>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('listVenues-page-name')}</TableCell>
              <TableCell>{t('listVenues-page-code')}</TableCell>
              <TableCell>{t('listVenues-page-enabled')}</TableCell>
              <TableCell>{t('listVenues-page-edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationVenue?.data ? (
              renderContent(paginationVenue.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listVenues-page-noVenues')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationVenue && (
        <TablePagination
          component="div"
          count={paginationVenue.total}
          page={paginationVenue.currentPage - 1}
          onPageChange={(_, page: number) => {
            setCurrentPageListNumber(page + 1);
            loadData(page + 1);
          }}
          rowsPerPage={paginationVenue.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
