import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PaginationData from 'types/models/PaginationData';
import User from 'types/models/User';
import UserRoleType from 'types/enums/UserRoleType';
import { getUsersWithPagination, searchUsers } from 'services/userServices';
import EditUserContext from 'contexts/EditUserContext';
import AppContext from 'contexts/AppContext';

export default (): JSX.Element => {
  const history = useHistory();
  const [paginationUser, setPaginationUser] = React.useState<PaginationData<User>>();
  const { currentPageListNumber, setCurrentPageListNumber } = React.useContext(AppContext);
  const [searchUsersText, setSearchUserText] = React.useState('');

  const { setSelectedUser } = React.useContext(EditUserContext);
  const { t } = useTranslation();

  const loadUsers = React.useCallback(async (page?: number) => {
    const paginationData = await getUsersWithPagination(page);

    if (paginationData) setPaginationUser(paginationData);
  }, []);

  React.useEffect(() => {
    if (currentPageListNumber) {
      loadUsers(currentPageListNumber);
    }
  }, [currentPageListNumber]);
  React.useEffect(() => {
    if (!searchUsersText && !setPaginationUser) return;
    if (!searchUsersText) {
      loadUsers();
    }
  }, [searchUsersText]);
  const translateUserType = (userRoleId: UserRoleType): string => {
    switch (userRoleId) {
      case UserRoleType.CommerceEditor:
        return t('listUsers-page-commerce-user');
      case UserRoleType.FranchiseEditor:
        return t('listUsers-page-franchise-user');
      case UserRoleType.VenueEditor:
        return t('listUsers-page-venue-user');
      default:
        return 'Unknown ';
    }
  };

  const renderEditButton = (user: User): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        aria-label="edit"
        onClick={() => {
          setSelectedUser(user);
          history.push('users/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };
  const handleSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchUserText(event.target.value);
  };
  const loadSearchData = React.useCallback(async (commerceSearchTerm: string, page?: number) => {
    const paginationData: PaginationData<User> | null = await searchUsers(commerceSearchTerm, page);

    if (paginationData) {
      setPaginationUser(paginationData);
    }
  }, []);
  const renderContent = (paginationData: User[]) => {
    return (
      <>
        {paginationData.map((user: User) => (
          <TableRow key={user.id}>
            <TableCell component="th" scope="row">
              {user.email}
            </TableCell>
            <TableCell>{user.name}</TableCell>
            <TableCell>{translateUserType(user.role)}</TableCell>
            <TableCell>{renderEditButton(user)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="search-container">
        <div className="search-bar-container">
          <TextField
            id="search"
            name="searchTerm"
            label={t('listUsers-page-search-placeholder')}
            value={searchUsersText}
            onChange={handleSearchTerm}
            margin="dense"
            fullWidth
          />
        </div>
        <div className="search-button-element">
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            disabled={!searchUsersText}
            onClick={() => loadSearchData(searchUsersText)}
          >
            {t('listCommerces-page-search')}
          </Button>
        </div>
      </div>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          history.push('users/create');
        }}
      >
        {t('listUsers-page-create')}
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('listUsers-page-email')}</TableCell>
              <TableCell>{t('listUsers-page-name')}</TableCell>
              <TableCell>{t('listUsers-page-role')}</TableCell>
              <TableCell>{t('listUsers-page-edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationUser?.data && paginationUser?.data.length > 0 ? (
              renderContent(paginationUser.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listUsers-page-noUsers')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationUser && (
        <TablePagination
          component="div"
          count={paginationUser.total}
          page={paginationUser.currentPage - 1}
          onPageChange={(_, page: number) => {
            setCurrentPageListNumber(page + 1);
            loadUsers(page + 1);
          }}
          rowsPerPage={paginationUser.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
