import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommerceForm from 'forms/CommerceForm';
import AppContext from 'contexts/AppContext';
import UserRoleType from 'types/enums/UserRoleType';

export default (): JSX.Element => {
  const { authData } = React.useContext(AppContext);
  const { t } = useTranslation();

  {
    (!authData ||
      authData?.user?.role !== UserRoleType.Admin ||
      authData?.user?.role !== Number(UserRoleType.VenueEditor)) && (
      <div className="error-container">
        <Typography color="error" variant="h3" align="center">
          {t('not-authorized-error')}
        </Typography>
      </div>
    );
  }
  // if (authData?.user.role !== UserRoleType.Admin || !authData) {
  //   return (
  //     <div className="error-container">
  //       <Typography color="error" variant="h3" align="center">
  //         {t('not-authorized-error')}
  //       </Typography>
  //     </div>
  //   );
  // }

  return <CommerceForm />;
};
