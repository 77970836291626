import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import FdConfigForm from './schemas/FdConfigForm';
import SelectField from 'components/SelectField';
import ConfirmDialog from 'components/ConfirmDialog';
import { storeFDConfig, updateFDConfig } from 'services/firstDataServices';
import FDConfigFormProps from 'types/forms/FDConfigFormProps';
import FirstDataConfig from 'types/models/FirstDataConfig';

export default ({ config }: FDConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    try {
      const values: FirstDataConfig = {
        ...formik.values,
        store: parseInt(formik.values.store.toString()),
      };
      const data = config ? await updateFDConfig(values) : await storeFDConfig(values);
      if (data) {
        history.push('/private/admin/payments/first-data');
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      id: config?.id ?? '',
      description: config?.description ?? '',
      store: config?.store ?? '',
      secret: '',
      timeZone: config?.timeZone ?? 'America/Buenos_Aires',
      currency: config?.currency ?? '',
    },
    validationSchema: FdConfigForm(t),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });

  const options = [
    {
      label: 'America/Buenos_Aires',
      value: 'America/Buenos_Aires',
    },
  ];

  return (
    <div>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="description"
            name="description"
            type="description"
            label={t('fdConfig-form-description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="store"
            name="store"
            type="number"
            label={t('fdConfig-form-store')}
            value={formik.values.store}
            onChange={formik.handleChange}
            error={formik.touched.store && Boolean(formik.errors.store)}
            helperText={formik.touched.store && formik.errors.store}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="secret"
            name="secret"
            label={t('fdConfig-form-secret')}
            type="secret"
            value={formik.values.secret}
            onChange={formik.handleChange}
            error={formik.touched.secret && Boolean(formik.errors.secret)}
            helperText={formik.touched.secret && formik.errors.secret}
          />
        </div>
        <div className="vertical-bottom-space">
          <SelectField
            name="timeZone"
            label={t('fdConfig-form-timeZone-label')}
            placeholder={t('fdConfig-form-timeZone-placeholder')}
            value={formik.values.timeZone}
            options={options}
            isMulti={false}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.timeZone}
            error={formik.errors.timeZone}
            isClearable={true}
            backspaceRemovesValue={true}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="currency"
            name="currency"
            label={t('fdConfig-form-currency')}
            type="currency"
            value={formik.values.currency}
            onChange={formik.handleChange}
            error={formik.touched.currency && Boolean(formik.errors.currency)}
            helperText={formik.touched.currency && formik.errors.currency}
          />
        </div>
        <div className="vertical-bottom-space">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {`${config ? t('fdConfig-form-config-edit') : t('fdConfig-form-config-create')} `}
          </Button>
        </div>
      </form>
    </div>
  );
};
