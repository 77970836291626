/* eslint @typescript-eslint/explicit-module-boundary-types: off */
import axiosInstance from './axios';
import User from 'types/models/User';
import AuthData from 'types/models/AuthData';

export const loginEmail = async (formData: FormData): Promise<AuthData | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'login/moderator',
      data: formData,
    });
    return parseAuthData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseAuthData = (data: any): AuthData => {
  return {
    token: data.token,
    user: parseUser(data.user),
  };
};

export const parseUser = (user: any): User => {
  return {
    id: user.id,
    email: user.email,
    phone: user.phone,
    name: user.name ?? undefined,
    googleId: user.googleId,
    facebookId: user.googleId,
    role: user.role_id,
    createdAt: user.created_at,
    updatedAt: user.updated_at,
  };
};
