import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PaginationData from 'types/models/PaginationData';
import Benefit from 'types/models/Benefit';
import BenefitType from 'types/enums/BenefitType';
import { getBenefitsWithPagination } from 'services/benefitServices';
import EditBenefitContext from 'contexts/EditBenefitContext';
import AppContext from 'contexts/AppContext';

export default (): JSX.Element => {
  const history = useHistory();
  const { currentPageListNumber, setCurrentPageListNumber } = React.useContext(AppContext);
  const [paginationBenefit, setPaginationBenefit] = React.useState<PaginationData<Benefit>>();
  const { setSelectedBenefit } = React.useContext(EditBenefitContext);
  const { t } = useTranslation();

  const loadBenefits = React.useCallback(async (page?: number) => {
    const paginationData = await getBenefitsWithPagination(page);
    if (paginationData) setPaginationBenefit(paginationData);
  }, []);

  React.useEffect(() => {
    if (currentPageListNumber) {
      loadBenefits(currentPageListNumber);
    }
  }, [currentPageListNumber]);
  const renderEditButton = (benefit: Benefit): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        aria-label="edit"
        onClick={() => {
          setSelectedBenefit(benefit);
          history.push('benefits/edit');
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderContent = (paginationData: Benefit[]) => {
    return (
      <>
        {paginationData.map((benefit: Benefit) => (
          <TableRow key={benefit.id}>
            <TableCell component="th" scope="row">
              {benefit.benefitType === BenefitType.DiscountBenefit
                ? t('listBenefits-page-typeDiscount')
                : benefit.benefitType === BenefitType.ProductBenefit
                ? t('listBenefits-page-typeProduct')
                : ''}
            </TableCell>
            <TableCell>{benefit.benefitDescription}</TableCell>
            <TableCell>{renderEditButton(benefit)}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          history.push('benefits/create');
        }}
      >
        {t('listBenefits-page-create')}
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('listBenefits-page-type')}</TableCell>
              <TableCell>{t('listBenefits-page-description')}</TableCell>
              <TableCell>{t('listBenefits-page-editar')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationBenefit?.data && paginationBenefit?.data.length > 0 ? (
              renderContent(paginationBenefit.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listBenefits-page-noBenefits')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationBenefit && (
        <TablePagination
          component="div"
          count={paginationBenefit.total}
          page={paginationBenefit.currentPage - 1}
          onPageChange={(_, page: number) => {
            setCurrentPageListNumber(page + 1);
            loadBenefits(page + 1);
          }}
          rowsPerPage={paginationBenefit.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
