import React from 'react';
import { useHistory } from 'react-router-dom';
import ArticleForm from 'forms/ArticleForm';
import EditVenueContext from 'contexts/EditVenueContext';
import { getArticle } from 'services/articleServices';
import { loadImages } from 'services/imageServices';
import Article from 'types/models/Article';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedArticle, selectedVenue } = React.useContext(EditVenueContext);
  const [completeArticle, setCompleteArticle] = React.useState<Article | null>(null);

  const load = React.useCallback(async (id: string) => {
    const article = await getArticle(id);
    if (!article) {
      history.push('/private/admin/venues');
      return;
    } else {
      if (article.images) {
        await loadImages(article.images);
      }
    }
    setCompleteArticle(article);
  }, []);

  React.useEffect(() => {
    if (!selectedArticle || !selectedVenue) {
      history.push('/private/admin/venues');
    } else {
      load(selectedArticle.id);
    }
  }, [selectedArticle, selectedVenue]);

  if (!completeArticle) return <></>;
  return <ArticleForm article={completeArticle} />;
};
