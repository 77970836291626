import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialog';
import { storeMPConfig, updateMPConfig } from './../services/mercadoPagoServices';
import MPConfigForm from './schemas/MPConfigForm';
import MPConfigFormProps from '../types/forms/MPConfigFormProps';

export default ({ config }: MPConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    try {
      const data = config ? await updateMPConfig({ ...formik.values }) : await storeMPConfig({ ...formik.values });
      if (data) {
        history.push('/private/admin/payments/mercado-pago');
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      id: config?.id ?? '',
      description: config?.description ?? '',
      token: config?.token ?? '',
    },
    validationSchema: MPConfigForm(t),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });

  return (
    <div>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="description"
            name="description"
            type="string"
            label={t('mpConfig-form-description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="token"
            name="token"
            label={t('mpConfig-form-token')}
            type="string"
            value={formik.values.token}
            onChange={formik.handleChange}
            error={formik.touched.token && Boolean(formik.errors.token)}
            helperText={formik.touched.token && formik.errors.token}
          />
        </div>
        <div className="vertical-bottom-space">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {`${config ? t('mpConfig-form-config-edit') : t('mpConfig-form-config-create')} `}
          </Button>
        </div>
      </form>
    </div>
  );
};
