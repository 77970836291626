import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ProductForm from 'forms/ProductForm';
import EditCommerceContext from 'contexts/EditCommerceContext';
import AppContext from 'contexts/AppContext';
import { getProduct } from 'services/productServices';
import { loadImages } from 'services/imageServices';
import Product from 'types/models/Product';
import UserRoleType from 'types/enums/UserRoleType';

export default (): JSX.Element => {
  const history = useHistory();
  const { authData } = React.useContext(AppContext);
  const { selectedProduct, selectedCommerce } = React.useContext(EditCommerceContext);
  const [completeProduct, setCompleteProduct] = React.useState<Product | null>(null);
  const { t } = useTranslation();

  if (
    !authData ||
    (authData.user.role !== UserRoleType.Admin &&
      authData.user.role !== UserRoleType.FranchiseEditor &&
      UserRoleType.CommerceEditor &&
      selectedCommerce?.editorId !== authData.user.id)
  ) {
    return (
      <div className="error-container">
        <Typography color="error" variant="h3" align="center">
          {t('not-authorized-error-or-not-editor')}
        </Typography>
      </div>
    );
  }

  const load = React.useCallback(async (id: string) => {
    const product = await getProduct(id);
    if (!product) {
      history.push('/private/commerces');
      return;
    } else {
      if (product.images) {
        await loadImages(product.images);
      }
      setCompleteProduct(product);
    }
  }, []);

  React.useEffect(() => {
    if (!selectedProduct || !selectedCommerce) {
      history.push('/private/commerces');
    } else {
      load(selectedProduct.id);
    }
  }, [selectedProduct, selectedCommerce]);

  if (!completeProduct) return <></>;
  return <ProductForm product={completeProduct} />;
};
