import React from 'react';
import { Dialog, Box, Button, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RedirectToAppsModalProps from 'types/components/RedirectToAppsModalProps';
import Venue from 'types/models/Venue';
import EditCommerceContext from 'contexts/EditCommerceContext';
import AppContext from 'contexts/AppContext';
import { getVenuesByCommerce } from 'services/venuesServices';
import 'styles/components/RedirectToAppsModal.scss';

export default ({ open, setOpen, redirectToUrl }: RedirectToAppsModalProps): JSX.Element => {
  const [venues, setVenues] = React.useState<Venue[] | null>(null);
  const [selectedVenueId, setSelectedVenueId] = React.useState('');
  const { authData } = React.useContext(AppContext);
  const { selectedCommerce } = React.useContext(EditCommerceContext);
  let appUrl = `${redirectToUrl}`;
  const { t } = useTranslation();

  const handleClose = (): void => {
    setOpen(false);
  };

  const fetchVenues = async () => {
    if (appUrl === process.env.REACT_APP_BILLETERA_WEB_HOST) {
      const venues = await getVenuesByCommerce();
      setVenues(venues);
    }
  };

  const handleConfirm = () => {
    if (!selectedCommerce) return;
    if (appUrl === process.env.REACT_APP_BILLETERA_WEB_HOST) {
      const findVenueById = venues?.find((venue) => venue.id === selectedVenueId);
      if (findVenueById) {
        appUrl += `home?venueCode=${findVenueById.code}&commerceCode=${selectedCommerce.code}`;
      }
    } else {
      appUrl += `Login?token=${authData?.token}`;
    }
    window.open(appUrl, '_blank');
    setSelectedVenueId('');
    setOpen(false);
  };

  React.useEffect(() => {
    fetchVenues();
  }, [appUrl]);

  return (
    <Dialog open={open}>
      <Box className="redirect-box-container" display="flex" flexDirection="column" textAlign="center">
        <Typography variant="h6">
          {appUrl === process.env.REACT_APP_BILLETERA_WEB_HOST
            ? t('redirect-to-apps-modal-select-billetera-fan')
            : t('redirect-to-apps-modal-confirm-cashless')}
        </Typography>
        {appUrl === process.env.REACT_APP_BILLETERA_WEB_HOST && (
          <>
            {venues && (
              <List component="div">
                {venues.map((venue, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={venue.name} />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setSelectedVenueId(venue.id);
                      }}
                    >
                      {t('redirect-to-apps-modal-select-venue')}
                    </Button>
                  </ListItem>
                ))}
              </List>
            )}
          </>
        )}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleConfirm}
          disabled={!selectedVenueId && appUrl === process.env.REACT_APP_BILLETERA_WEB_HOST}
        >
          {t('redirect-to-apps-modal-confirm')}
        </Button>
        <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
          {t('redirect-to-apps-modal-cancel')}
        </Button>
      </Box>
    </Dialog>
  );
};
