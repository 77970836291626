/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance, { axiosMultipart } from './axios';
import PaginationData from 'types/models/PaginationData';
import Banner from 'types/models/Banner';
import { parseImages } from './imageServices';

export const getBanners = async (venueId: string, page?: number): Promise<PaginationData<Banner> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `venues/${venueId}/banners?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseBanners(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeBanner = async (venueId: string, formData: FormData): Promise<Banner | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `venues/${venueId}/banners`,
      data: formData,
    });
    return parseBanner(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getBanner = async (bannerId: string): Promise<Banner | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `banners/${bannerId}`,
    });
    return parseBanner(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateBanner = async (bannerId: string, formData: FormData): Promise<Banner | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `banners/${bannerId}?_method=put`,
      data: formData,
    });
    return parseBanner(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteBanner = async (venueId: string, bannerId: string): Promise<Banner | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `banners/${venueId}/${bannerId}`,
    });
    return parseBanner(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const parseBanners = (banners: any): Banner[] => {
  return banners.map((banner: any) => parseBanner(banner));
};

export const parseBanner = (banner: any): Banner => {
  return {
    id: banner.id,
    title: banner.title,
    url: banner.url,
    description: banner.description,
    images: banner.images ? parseImages(banner.images) : undefined,
  };
};
