/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance from './axios';
import PaymentProvider from 'types/models/PaymentProvider';
import FirstDataConfig from 'types/models/FirstDataConfig';
import MercadoPagoConfig from 'types/models/MercadoPagoConfig';
import MercadoPagoPointConfig from 'types/models/MercadoPagoPointConfig';
import CloverPointConfig from 'types/models/CloverPointConfig';
import { parseCloverPointConfigs } from './cloverServices';
import PaginationData from 'types/models/PaginationData';
import FDPayment from 'types/models/FirstDataPayment';
import IzipayConfig from 'types/models/IzipayConfig';
import { parseIzipayConfigs } from './izipayServices';
import { parseMPCheckoutConfigs } from './mercadoPagoServices';
import MercadoPagoCheckoutConfig from 'types/models/MercadoPagoCheckoutConfig';

export const getProviders = async (modelType: string): Promise<PaymentProvider[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `payments/providers?modelType=${modelType}`,
    });
    return parsePaymentProviders(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getFDConfigs = async (): Promise<FirstDataConfig[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'payments/first-data/configs?page=-1',
    });
    return parseFDConfigs(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getMPPointConfigs = async (): Promise<MercadoPagoPointConfig[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'payments/mercado-pago-point/configs?page=-1',
    });
    return parseMPPointConfigs(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getMPConfigs = async (): Promise<MercadoPagoConfig[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'payments/mercado-pago/configs?page=-1',
    });
    return parseMPConfigs(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getMPCheckoutConfigs = async (): Promise<MercadoPagoCheckoutConfig[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'payments/mercado-pago-checkout/configs?page=-1',
    });
    return parseMPCheckoutConfigs(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getCloverPointConfigs = async (): Promise<CloverPointConfig[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'payments/clover-point/configs?page=-1',
    });
    return parseCloverPointConfigs(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getIzipayConfigs = async (): Promise<IzipayConfig[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'payments/izipay/configs?page=-1',
    });
    return parseIzipayConfigs(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getReportingSells = async (
  params: Record<any, any>,
  page?: number,
  venueId: string | null = null,
  commerceId: string | null = null,
): Promise<PaginationData<FDPayment> | null> => {
  try {
    let url = `reporting/sells?page=${page ?? 1}`;
    if (venueId) url += `&venueId=${venueId}`;
    if (commerceId) url += `&commerceId=${commerceId}`;

    const { data: paginationData } = await axiosInstance({
      method: 'post',
      url: url,
      data: {
        ...params,
        isToDownload: false,
      },
    });
    return {
      currentPage: paginationData.current_page,
      data: parseFDPaymentsForSells(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllReportingSells = async (params: Record<any, any>): Promise<Blob | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `reporting/sells/`,
      data: {
        ...params,
        isToDownload: true,
      },
      responseType: 'blob',
    });

    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const parseFDPaymentsForSells = (fdPayments: any[]): FDPayment[] => {
  return fdPayments.map((fdPayment) => {
    return parseFDPaymentForSells(fdPayment);
  });
};

export const parseFDPayment = (fdPayment: any) => {
  return {
    id: fdPayment.id,
    chargeTotal: fdPayment.charge_total,
    currency: fdPayment.currency,
    timeZone: fdPayment.time_zone,
    ccBrand: fdPayment.cc_brand,
    ccCountry: fdPayment.cc_country,
    ccExpMonth: fdPayment.cc_exp_month,
    ccExpYear: fdPayment.cc_exp_year,
    ccLastFourDigits: fdPayment.cc_last_four_digits,
    ccOwnerName: fdPayment.cc_owner_name,
    ccBin: fdPayment.cc_bin,
    approvalCode: fdPayment.approval_code,
    cardNumber: fdPayment.card_number,
    dues: fdPayment.dues,
    oid: fdPayment.oid,
    refNumber: fdPayment.ref_number,
    status: fdPayment.status,
    terminalId: fdPayment.terminal_id,
    txnDateProcessed: fdPayment.txn_date_processed,
    txnDatetime: fdPayment.txn_datetime,
    txnType: fdPayment.txn_type,
  };
};

export const parseFDPaymentForSells = (fdPayment: any): FDPayment => {
  return {
    id: fdPayment.first_data_payment.id,
    clientId: fdPayment.commerce_id,
    chargeTotal: fdPayment.first_data_payment.charge_total,
    currency: fdPayment.first_data_payment.currency,
    timeZone: fdPayment.first_data_payment.time_zone,
    ccBrand: fdPayment.first_data_payment.cc_brand,
    ccCountry: fdPayment.first_data_payment.cc_country,
    ccExpMonth: fdPayment.first_data_payment.cc_exp_month,
    ccExpYear: fdPayment.first_data_payment.cc_exp_year,
    ccLastFourDigits: fdPayment.first_data_payment.cc_last_four_digits,
    ccOwnerName: fdPayment.first_data_payment.cc_owner_name,
    ccBin: fdPayment.first_data_payment.cc_bin,
    approvalCode: fdPayment.first_data_payment.approval_code,
    cardNumber: fdPayment.first_data_payment.card_number,
    dues: fdPayment.first_data_payment.dues,
    oid: fdPayment.first_data_payment.oid,
    refNumber: fdPayment.first_data_payment.ref_number,
    status: fdPayment.first_data_payment.status,
    terminalId: fdPayment.first_data_payment.terminal_id,
    txnDateProcessed: fdPayment.first_data_payment.txn_date_processed,
    txnDatetime: fdPayment.first_data_payment.txn_datetime,
    txnType: fdPayment.first_data_payment.txn_type,
  };
};

const parsePaymentProviders = (paymentProviders: any): PaymentProvider[] => {
  return paymentProviders.map((paymentProvider: any): PaymentProvider => parsePaymentProvider(paymentProvider));
};

const parsePaymentProvider = (paymentProvider: any): PaymentProvider => {
  return {
    id: paymentProvider.id,
    description: paymentProvider.description,
  };
};

const parseFDConfigs = (fdConfigs: any): FirstDataConfig[] => {
  return fdConfigs.map((item: any): FirstDataConfig => parseFDConfig(item));
};

const parseFDConfig = (fdConfig: any): FirstDataConfig => {
  return {
    id: fdConfig.id,
    description: fdConfig.description,
    store: fdConfig.fd_store,
    secret: fdConfig.fd_secret ?? '',
    timeZone: fdConfig.time_zone,
    currency: fdConfig.currency,
  };
};

const parseMPConfigs = (mpConfigs: any): MercadoPagoConfig[] => {
  return mpConfigs.map((item: any): MercadoPagoConfig => parseMPConfig(item));
};

const parseMPConfig = (mpConfig: any): MercadoPagoConfig => {
  return {
    id: mpConfig.id,
    description: mpConfig.description,
    token: mpConfig.token,
  };
};

const parseMPPointConfigs = (mpPointConfigs: any): MercadoPagoPointConfig[] => {
  return mpPointConfigs.map((item: any): MercadoPagoPointConfig => parseMPPointConfig(item));
};

const parseMPPointConfig = (mpPointConfig: any): MercadoPagoPointConfig => {
  return {
    id: mpPointConfig.id,
    description: mpPointConfig.description,
    accessToken: mpPointConfig.access_token,
  };
};
