/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance from './axios';
import PaginationData from 'types/models/PaginationData';
import IzipayConfig from 'types/models/IzipayConfig';

export const getIPConfigs = async (page?: number): Promise<PaginationData<IzipayConfig> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `payments/izipay/configs?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseIzipayConfigs(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeIPConfig = async (izipayConfig: IzipayConfig): Promise<IzipayConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'payments/izipay/config',
      data: izipayConfig,
    });
    return parseConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateIPConfig = async (izipayConfig: IzipayConfig): Promise<IzipayConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `payments/izipay/config?_method=put`,
      data: izipayConfig,
    });
    return parseConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const parseIzipayConfigs = (data: any): IzipayConfig[] => {
  return data.map((item: any): IzipayConfig => parseConfig(item));
};
const parseConfig = (data: any): IzipayConfig => {
  return {
    id: data.id,
    description: data.description,
    commerceId: data.commerce_code,
    publicKey: data.public_key,
    apiKey: data.api_key,
  };
};
