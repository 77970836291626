import React, { Fragment } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Collapse,
  Box,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { parseDateTime } from 'utils/datetime';
import PaginationData from 'types/models/PaginationData';
import Order from 'types/models/Order';
import ProtocolOrdersForm from 'forms/ProtocolOrdersForm';
import { storeOrder, getProtocolOrders, getProtocolOrdersTotal } from 'services/orderServices';
import OrderDetail from 'types/models/OrderDetail';
import { ProtocolOrderPayloadData, VenueAndCommerce } from 'types/forms/ProtocolOrdersFormProps';
import 'styles/pages/ConsumptionsReportPage.scss';

export default (): JSX.Element => {
  const [currentFormData, setCurrentFormData] = React.useState<ProtocolOrderPayloadData | null>(null);
  const [venueAndCommerceSelected, setVenueAndCommerceSelected] = React.useState<VenueAndCommerce | null>(null);
  const [total, setTotal] = React.useState<number | null>(null);
  const [paginationOrders, setPaginationOrders] = React.useState<PaginationData<Order> | null>(null);
  const [openCollapseDetail, setOpenCollapseDetail] = React.useState(0);
  const [message, setMessage] = React.useState<{ text: string; color: boolean | null } | null>(null);
  const [isOrderCreated, setisOrderCreated] = React.useState(false);

  const { t } = useTranslation();

  const saveOrder = async () => {
    if (!currentFormData) return;
    const order = await storeOrder(currentFormData);
    if (order) {
      setMessage({ text: 'La orden se creó con éxito', color: true });
      setisOrderCreated(true);
    } else {
      setisOrderCreated(false);
      setMessage({ text: 'Hubo un error al crear la orden', color: false });
    }
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  };
  const getPaginatedOrders = async (page?: number) => {
    if (!venueAndCommerceSelected || !venueAndCommerceSelected.commerce) return;
    const totalForm = {
      commerceIds: [venueAndCommerceSelected.commerce.id],
      venueId: venueAndCommerceSelected.venueId,
      dateFrom: null,
      dateTo: null,
    };
    const paginatedOrders = await getProtocolOrders(venueAndCommerceSelected, page);
    const getTotal = await getProtocolOrdersTotal(totalForm);

    if (paginatedOrders) {
      setPaginationOrders(paginatedOrders);
    }
    setTotal(getTotal !== null ? getTotal : 0);
  };

  React.useEffect(() => {
    saveOrder();
  }, [currentFormData]);

  const handleCollapse = (id: number) => {
    if (openCollapseDetail === id) {
      setOpenCollapseDetail(0);
    } else {
      setOpenCollapseDetail(id);
    }
  };

  const renderContent = (order: Order): JSX.Element => {
    return (
      <Fragment key={order.id}>
        <TableRow className={'content-table'}>
          <TableCell component="th" scope="row">
            {order.id}
          </TableCell>
          <TableCell>{order.commerce?.name}</TableCell>
          <TableCell>{order.delivery}</TableCell>
          <TableCell>{parseDateTime(order.localDatetime)}</TableCell>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => handleCollapse(order.id)}>
              {order.id === openCollapseDetail ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
            <Collapse in={order.id === openCollapseDetail} timeout="auto" unmountOnExit={true}>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom={true} component="div">
                  {t('protocolOrders-page-detail')}
                </Typography>
                <Table size="small" aria-label="details">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('protocolOrders-page-product')}</TableCell>
                      <TableCell>{t('protocolOrders-page-amount')}</TableCell>
                      <TableCell>{t('protocolOrders-page-clothingSizeType')}</TableCell>
                      <TableCell>{t('protocolOrders-page-unitPrice')}</TableCell>
                      <TableCell>{t('protocolOrders-page-totalProduct')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order?.details?.map((detail: OrderDetail) => (
                      <TableRow key={detail.id}>
                        <TableCell component="th" scope="row">
                          {detail.product}
                        </TableCell>
                        <TableCell>{detail.quantity}</TableCell>
                        <TableCell>{detail.clothinSizeType?.description ?? '-'}</TableCell>
                        <TableCell>{detail.price}</TableCell>
                        <TableCell>{detail.totalAmount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <>
      {message && (
        <Typography style={{ textAlign: 'center', color: message.color ? 'green' : 'red' }}>{message.text}</Typography>
      )}

      <ProtocolOrdersForm
        setisOrderCreatedFalse={() => setisOrderCreated(false)}
        isOrderCreated={isOrderCreated}
        setCurrentFormData={setCurrentFormData}
        setVenueAndCommerceSelected={setVenueAndCommerceSelected}
        getPaginatedOrders={getPaginatedOrders}
      />

      <>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>{t('protocolOrders-page-order')}</TableCell>
                <TableCell>{t('protocolOrders-page-commerce')}</TableCell>
                <TableCell>{t('protocolOrders-page-delivery')}</TableCell>
                <TableCell>{t('protocolOrders-page-createdDate')}</TableCell>
                <TableCell>{t('protocolOrders-page-detail')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginationOrders?.data && paginationOrders?.data.length > 0 ? (
                paginationOrders?.data.map((order: Order) => renderContent(order))
              ) : (
                <TableRow>
                  <TableCell>{t('consumptionsReport-page-noOperations')}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {paginationOrders && (
          <>
            <TableRow>
              <TableCell>{`${t('consumptionsReport-page-operationsTotal')}: $${total?.toFixed(2)}`}</TableCell>
            </TableRow>
            <TablePagination
              component="div"
              count={paginationOrders.total}
              page={paginationOrders.currentPage - 1}
              onPageChange={(_, page: number) => {
                getPaginatedOrders(page + 1);
              }}
              rowsPerPage={paginationOrders.perPage}
              rowsPerPageOptions={[]}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
              }}
            />
          </>
        )}
      </>
    </>
  );
};
