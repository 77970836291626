import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialog';
import { storeMPPointConfig, updateMPPointConfig } from '../services/mercadoPagoServices';
import MPPointConfigForm from './schemas/MPPointConfigForm';
import MPPointConfigFormProps from '../types/forms/MPPointConfigFormProps';

export default ({ config }: MPPointConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    try {
      const data = config
        ? await updateMPPointConfig({ ...formik.values })
        : await storeMPPointConfig({ ...formik.values });
      if (data) {
        history.push('/private/admin/payments/mercado-pago-point');
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      id: config?.id ?? '',
      description: config?.description ?? '',
      accessToken: config?.accessToken ?? '',
    },
    validationSchema: MPPointConfigForm(t),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });
  console.log('estoy en mp configform');
  return (
    <div>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="description"
            name="description"
            type="string"
            label={t('mpConfig-form-description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="token"
            name="accessToken"
            label={t('mpPPointConfig-form-accessToken')}
            type="string"
            value={formik.values.accessToken}
            onChange={formik.handleChange}
            error={formik.touched.accessToken && Boolean(formik.errors.accessToken)}
            helperText={formik.touched.accessToken && formik.errors.accessToken}
          />
        </div>
        {config && (
          <div className="vertical-bottom-space">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push(`/private/admin/payments/mercado-pago-point/posnets`)}
            >
              {t('mp-point-manageMpPoints')}
            </Button>
          </div>
        )}
        <div className="vertical-bottom-space">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {`${config ? t('mpConfig-form-config-edit') : t('mpConfig-form-config-create')} `}
          </Button>
        </div>
      </form>
    </div>
  );
};
