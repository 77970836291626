import React from 'react';
import { useHistory } from 'react-router-dom';
import CommerceCategoriesForm from 'forms/CommerceCategoriesForm';
import EditCommerceCategoriesContext from 'contexts/EditCommerceCategoriesContext';
import { getCommerceCategory } from 'services/commerceServices';
import CommerceCategory from 'types/models/CommerceCategory';
import { loadImages } from 'services/imageServices';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedCommerceCategory } = React.useContext(EditCommerceCategoriesContext);
  const [completeCommerceCategory, setCompleteCommerceCategory] = React.useState<CommerceCategory | null>(null);

  const load = React.useCallback(async (id: string) => {
    const commerceCategory = await getCommerceCategory(id);
    if (!commerceCategory) {
      history.push('/private/admin/commerce-categories');
      return;
    } else {
      if (commerceCategory.images) {
        await loadImages(commerceCategory.images);
      }
    }
    setCompleteCommerceCategory(commerceCategory);
  }, []);

  React.useEffect(() => {
    if (!selectedCommerceCategory) {
      history.push('/private/admin/commerce-categories');
    } else {
      load(selectedCommerceCategory.id);
    }
  }, [selectedCommerceCategory]);

  if (!completeCommerceCategory) return <></>;
  return <CommerceCategoriesForm commerceCategory={completeCommerceCategory} />;
};
