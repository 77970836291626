import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppContext from 'contexts/AppContext';
import ImageInput from 'components/ImageInput';
import SelectField from 'components/SelectField';
import ConfirmDialog from 'components/ConfirmDialog';
import RedirectToAppsModal from 'components/RedirectToAppsModal';
import SelectedFieldOption from 'types/models/SelectFieldOption';
import CommerceFormProps from 'types/forms/CommerceFormProps';
import Commerce from 'types/models/Commerce';
import CommerceCategory from 'types/models/CommerceCategory';
import User from 'types/models/User';
import CommerceType from 'types/models/CommerceType';
import UserRoleType from 'types/enums/UserRoleType';
import CommerceImageType from 'types/enums/CommerceImageType';
import { CreateForm, EditForm } from './schemas/CommerceForm';
import {
  getCommerceCategories,
  storeCommerce,
  updateCommerce,
  getCommerces,
  getAllCommerceTypes,
} from 'services/commerceServices';
import { getUsersByEditorType } from 'services/userServices';
import 'styles/forms/CommerceForm.scss';

export default ({ commerce }: CommerceFormProps): JSX.Element => {
  const history = useHistory();
  const { authData } = React.useContext(AppContext);
  const [categories, setCategories] = React.useState<CommerceCategory[] | null>(null);
  const [types, setTypes] = React.useState<CommerceType[] | null>(null);
  const [commerceUsers, setCommerceUsers] = React.useState<User[] | null>(null);
  const [errorSameEditor, setErrorSameEditor] = React.useState<string>('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openModalForBilleteraFan, setOpenModalForBilleteraFan] = React.useState(false);
  const [openModalForCashless, setOpenModalForCashless] = React.useState(false);
  const logo = commerce?.images?.find((image) => image.type === CommerceImageType.Logo);
  const banner = commerce?.images?.find((image) => image.type === CommerceImageType.Banner);
  const cloverBanner = commerce?.images?.find((image) => image.type === CommerceImageType.CloverBanner);
  const { t } = useTranslation();

  React.useEffect(() => {
    const getCategories = async () => {
      const categories = await getCommerceCategories();
      setCategories(categories);
    };
    getCategories();

    const getTypes = async () => {
      const types = await getAllCommerceTypes();
      setTypes(types);
    };
    getTypes();

    const getEditorCommerceUsers = async () => {
      if (authData?.user.role !== UserRoleType.Admin && !commerce) return;
      const users = await getUsersByEditorType(UserRoleType.CommerceEditor);
      if (users) {
        setCommerceUsers(users);
      }
    };
    getEditorCommerceUsers();
  }, []);

  const categoriesForSelect = (categories: CommerceCategory[]): SelectedFieldOption[] => {
    return categories.map((category) => {
      return {
        label: category.description,
        value: category.id,
      };
    });
  };

  const typesForSelect = (types: CommerceType[]): SelectedFieldOption[] => {
    return types.map((type) => {
      return {
        label: type.description,
        value: type.id,
      };
    });
  };

  const usersForSelect = (users: User[]): SelectedFieldOption[] => {
    return users.map((user) => {
      return {
        label: user.name,
        value: user.id,
      };
    });
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('name', formik.values.name);
    formData.append('code', formik.values.code);
    formData.append('address', formik.values.address);
    formData.append('email', formik.values.email);
    formData.append('phone', formik.values.phone);
    formData.append('hideLogoOnList', formik.values.hideLogoOnList ? '1' : '0');
    const transformCategoriesKeys = (): CommerceCategory[] => {
      if (!categories || !formik.values.categories) return [];
      return categories.filter((category) => formik.values.categories.includes(category.id));
    };
    formData.append('categories', JSON.stringify(transformCategoriesKeys()));
    formData.append('type', formik.values.type);
    formData.append('logo', formik.values.logo as unknown as File);
    formData.append('banner', formik.values.banner as unknown as File);
    if (formik.values.cloverBanner) formData.append('cloverBanner', formik.values.cloverBanner as unknown as File);

    try {
      let result: Commerce | null;
      if (commerce) {
        formData.append('enabled', formik.values.enabled ? '1' : '0');
        formData.append('integrationMaxirest', formik.values.integrationMaxirest ? '1' : '0');
        if (formik.values.integrationMaxirest) {
          formData.append('maxirestStoreId', formik.values.maxirestStoreId);
          formData.append('maxirestPartner', formik.values.maxirestPartner);
          formData.append('maxirestAuthorization', formik.values.maxirestAuthorization);
        }
        if (formik.values.commerceEditor) {
          formData.append('commerceEditor', formik.values.commerceEditor);
        }
        result = await updateCommerce(commerce.id, formData);
      } else {
        result = await storeCommerce(formData);
      }
      if (result) {
        history.go(-1);
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: commerce?.name ?? '',
      code: commerce?.code ?? '',
      address: commerce?.address ?? '',
      email: commerce?.email ?? '',
      phone: commerce?.phone ?? '',
      logo: logo?.data ?? null,
      banner: banner?.data ?? null,
      cloverBanner: cloverBanner?.data ?? null,
      type: commerce?.type?.id ?? '',
      categories: commerce?.categories?.map((category) => category.id) ?? [],
      enabled: commerce?.enabled ?? true,
      hideLogoOnList: commerce?.hideLogoOnList ?? false,
      integrationMaxirest: commerce?.integrationMaxirest ?? false,
      maxirestStoreId: commerce?.maxirestStoreId ?? '',
      maxirestPartner: commerce?.maxirestPartner ?? '',
      maxirestAuthorization: commerce?.maxirestAuthorization ?? '',
      commerceEditor: commerce?.editorId ?? '',
    },
    validationSchema: commerce ? EditForm(t) : CreateForm(t),
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  React.useEffect(() => {
    const checkForDuplicateCommerceEditor = async () => {
      if (!formik.values.commerceEditor) return;
      const allCommerces = await getCommerces();
      if (allCommerces) {
        if (commerce?.editorId) {
          const indexOfCurrentCommerce = allCommerces.findIndex((arrayCommerce) => arrayCommerce.id === commerce.id);
          allCommerces.splice(indexOfCurrentCommerce, 1);
        }
        const findCommerceWithCurrentEditorId = allCommerces.find(
          (commerce) => commerce.editorId === formik.values.commerceEditor,
        );
        if (findCommerceWithCurrentEditorId) {
          const currentCommerceEditorId = formik.values.commerceEditor;
          setErrorSameEditor(
            `No puedes asignar usuario con Id: ${currentCommerceEditorId} porque esta asignado al Comercio ${findCommerceWithCurrentEditorId.name}`,
          );
          formik.setFieldValue('commerceEditor', '');
        } else {
          setErrorSameEditor('');
        }
      }
    };
    checkForDuplicateCommerceEditor();
  }, [formik.values.commerceEditor]);

  const urlToRedirectModal = (): string => {
    if (openModalForBilleteraFan) return `${process.env.REACT_APP_BILLETERA_WEB_HOST}`;
    if (openModalForCashless) return `${process.env.REACT_APP_CASHLESS_WEB_HOST}`;
    return '';
  };

  const appToRedirectModal = (): boolean => {
    if (openModalForBilleteraFan) return true;
    if (openModalForCashless) return true;
    return false;
  };

  const setAppToRedirectModal = () => {
    if (openModalForBilleteraFan) return setOpenModalForBilleteraFan;
    if (openModalForCashless) return setOpenModalForCashless;
    return () => undefined;
  };

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      {(authData?.user.role === UserRoleType.VenueEditor || authData?.user.role === UserRoleType.CommerceEditor) && (
        <RedirectToAppsModal
          open={appToRedirectModal()}
          setOpen={setAppToRedirectModal()}
          redirectToUrl={urlToRedirectModal()}
        />
      )}
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="name"
          name="name"
          label={t('commerce-form-name')}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="code"
          name="code"
          label={t('commerce-form-code')}
          value={formik.values.code}
          onChange={formik.handleChange}
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="address"
          name="address"
          label={t('commerce-form-adress')}
          value={formik.values.address}
          onChange={formik.handleChange}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="email"
          name="email"
          label={t('commerce-form-email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="phone"
          name="phone"
          label={t('commerce-form-phone')}
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          disabled={formik.isSubmitting}
        />
        {commerce && (
          <>
            <FormControlLabel
              id="enabled"
              name="enabled"
              control={<Switch checked={formik.values.enabled} onChange={formik.handleChange} />}
              label={t('commerce-form-enabled')}
              disabled={formik.isSubmitting}
            />
            <FormControlLabel
              id="integrationMaxirest"
              name="integrationMaxirest"
              control={<Switch checked={formik.values.integrationMaxirest} onChange={formik.handleChange} />}
              label={t('commerce-form-integrationMaxirest')}
              disabled={formik.isSubmitting}
            />
          </>
        )}
        <FormControlLabel
          id="hideLogoOnList"
          name="hideLogoOnList"
          control={<Switch checked={formik.values.hideLogoOnList} onChange={formik.handleChange} />}
          label={t('commerce-form-hideLogoOnList')}
          disabled={formik.isSubmitting}
        />
        {commerce && formik.values.integrationMaxirest && (
          <>
            <TextField
              margin="dense"
              fullWidth
              id="maxirestStoreId"
              name="maxirestStoreId"
              label={t('commerce-form-maxirestStoreId')}
              value={formik.values.maxirestStoreId}
              onChange={formik.handleChange}
              error={formik.touched.maxirestStoreId && Boolean(formik.errors.maxirestStoreId)}
              helperText={formik.touched.maxirestStoreId && formik.errors.maxirestStoreId}
              disabled={formik.isSubmitting}
            />
            <TextField
              margin="dense"
              fullWidth
              id="maxirestPartner"
              name="maxirestPartner"
              label={t('commerce-form-maxirestPartner')}
              value={formik.values.maxirestPartner}
              onChange={formik.handleChange}
              error={formik.touched.maxirestPartner && Boolean(formik.errors.maxirestPartner)}
              helperText={formik.touched.maxirestPartner && formik.errors.maxirestPartner}
              disabled={formik.isSubmitting}
            />
            <TextField
              margin="dense"
              fullWidth
              id="maxirestAuthorization"
              name="maxirestAuthorization"
              label={t('commerce-form-maxirestAuthorization')}
              value={formik.values.maxirestAuthorization}
              onChange={formik.handleChange}
              error={formik.touched.maxirestAuthorization && Boolean(formik.errors.maxirestAuthorization)}
              helperText={formik.touched.maxirestAuthorization && formik.errors.maxirestAuthorization}
              disabled={formik.isSubmitting}
            />
          </>
        )}
        <ImageInput
          label={`${formik.values.logo || logo ? t('commerce-form-logo-edit') : t('commerce-form-logo-create')}`}
          name="logo"
          isSubmitting={formik.isSubmitting}
          value={formik.values.logo}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched.logo}
          errors={formik.errors.logo}
          preview={logo?.preview}
        />
        <div className="user-message">{t('commerce-form-logo-size')}</div>
        <ImageInput
          label={`${
            formik.values.banner || banner ? t('commerce-form-banner-edit') : t('commerce-form-banner-create')
          }`}
          name="banner"
          isSubmitting={formik.isSubmitting}
          value={formik.values.banner}
          idealSize={{ width: 700, height: 300 }}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched.banner}
          errors={formik.errors.banner}
          preview={banner?.preview}
        />
        {commerce &&
          (authData?.user?.role === UserRoleType.Admin ||
            authData?.user?.role === UserRoleType.CommerceEditor ||
            authData?.user?.role === UserRoleType.FranchiseEditor) && (
            <ImageInput
              label={`${
                formik.values.cloverBanner || cloverBanner
                  ? t('commerce-form-cloverBanner-edit')
                  : t('commerce-form-cloverBanner-create')
              }`}
              name="cloverBanner"
              isSubmitting={formik.isSubmitting}
              value={formik.values.cloverBanner}
              idealSize={{ width: 984, height: 171 }}
              setFieldValue={formik.setFieldValue}
              touched={formik.touched.cloverBanner}
              errors={formik.errors.cloverBanner}
              preview={cloverBanner?.preview}
            />
          )}
        {commerce &&
          (authData?.user?.role === UserRoleType.Admin ||
            authData?.user?.role === UserRoleType.CommerceEditor ||
            authData?.user?.role === UserRoleType.FranchiseEditor) && (
            <>
              <div className="vertical-bottom-space">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => history.push(`/private/commerces/${commerce.id}/edit/payment-config`)}
                >
                  {t('commerce-form-editPaymentMethods')}
                </Button>
              </div>
              <div className="vertical-bottom-space">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={(): void => history.push(`/private/commerces/${commerce.id}/edit/delivery-config`)}
                >
                  {t('commerce-form-editDeliveryConfigs')}
                </Button>
              </div>
            </>
          )}
        {(authData?.user.role === UserRoleType.VenueEditor || authData?.user.role === UserRoleType.CommerceEditor) && (
          <>
            <div className="footer">
              <Button
                fullWidth
                variant="contained"
                onClick={() => setOpenModalForBilleteraFan(true)}
                disabled={formik.isSubmitting}
              >
                {t('commerce-form-goTo-billeteraFan')}
              </Button>
            </div>
            <div className="footer">
              <Button
                fullWidth
                variant="contained"
                onClick={() => setOpenModalForCashless(true)}
                disabled={formik.isSubmitting}
              >
                {t('commerce-form-goTo-cashless')}
              </Button>
            </div>
          </>
        )}
        {commerce && (
          <div>
            <div className="footer">
              <Button
                fullWidth
                variant="contained"
                onClick={() => history.push(`/private/commerces/${commerce.id}/products`)}
                disabled={formik.isSubmitting}
              >
                {t('commerce-form-manageProducts')}
              </Button>
            </div>
            {(authData?.user.role === UserRoleType.Admin ||
              authData?.user.role === UserRoleType.CommerceEditor ||
              authData?.user.role === UserRoleType.FranchiseEditor) && (
              <div className="footer">
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => history.push(`/private/commerces/${commerce.id}/discounts`)}
                  disabled={formik.isSubmitting}
                >
                  {t('commerce-form-manageDiscounts')}
                </Button>
              </div>
            )}
          </div>
        )}
        {(authData?.user.role === UserRoleType.VenueEditor || authData?.user.role === UserRoleType.CommerceEditor) && (
          <>
            <div className="footer">
              <Button
                fullWidth
                variant="contained"
                onClick={() => setOpenModalForBilleteraFan(true)}
                disabled={formik.isSubmitting}
              >
                {t('commerce-form-goTo-billeteraFan')}
              </Button>
            </div>
            <div className="footer">
              <Button
                fullWidth
                variant="contained"
                onClick={() => setOpenModalForCashless(true)}
                disabled={formik.isSubmitting}
              >
                {t('commerce-form-goTo-cashless')}
              </Button>
            </div>
          </>
        )}
        {categories && (
          <SelectField
            name="categories"
            label={t('commerce-form-categories-label')}
            placeholder={t('commerce-form-categories-placeholder')}
            value={formik.values.categories}
            options={categoriesForSelect(categories)}
            isMulti={true}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.categories}
            error={formik.errors.categories}
            isClearable={true}
            backspaceRemovesValue={true}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        {types && (
          <SelectField
            name="type"
            label={t('commerce-form-type-label')}
            placeholder={t('commerce-form-type-placeholder')}
            value={formik.values.type}
            options={typesForSelect(types)}
            isMulti={false}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.type}
            error={formik.errors.type}
            isClearable={true}
            backspaceRemovesValue={true}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        {authData?.user.role === UserRoleType.Admin && commerceUsers && commerce && (
          <SelectField
            name="commerceEditor"
            label={t('commerce-form-commerce-users-label')}
            placeholder={t('commerce-form-selected-commerce-users-label-placeholder')}
            value={formik.values.commerceEditor}
            options={usersForSelect(commerceUsers)}
            isMulti={false}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.commerceEditor}
            error={formik.errors.commerceEditor}
            isClearable={true}
            backspaceRemovesValue={true}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        {errorSameEditor && (
          <div>
            <Typography color="error" variant="h6" component="h2">
              {errorSameEditor}
            </Typography>
          </div>
        )}
        {(authData?.user.role === UserRoleType.VenueEditor || authData?.user.role === UserRoleType.CommerceEditor) && (
          <>
            <div className="footer">
              <Button
                fullWidth
                variant="contained"
                onClick={() => setOpenModalForBilleteraFan(true)}
                disabled={formik.isSubmitting}
              >
                {t('commerce-form-goTo-billeteraFan')}
              </Button>
            </div>
            <div className="footer">
              <Button
                fullWidth
                variant="contained"
                onClick={() => setOpenModalForCashless(true)}
                disabled={formik.isSubmitting}
              >
                {t('commerce-form-goTo-cashless')}
              </Button>
            </div>
          </>
        )}
        <div className="footer">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${commerce ? t('commerce-form-commerce-edit') : t('commerce-form-commerce-create')}`}
          </Button>
        </div>
      </form>
    </>
  );
};
