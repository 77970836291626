import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import LoginUserPage from 'pages/public/LoginUserPage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const { authData } = React.useContext(AppContext);

  if (authData) {
    return <Redirect to="/private/welcome" />;
  }

  return (
    <Switch>
      <Route exact path={`${match.url}/login`} component={LoginUserPage} />
      <Redirect to="/public/login" />
    </Switch>
  );
};
