import React from 'react';
import { useHistory } from 'react-router-dom';
import VenueFactoryWidgetForm from 'forms/VenueFactoryWidgetForm';
import EditVenueContext from 'contexts/EditVenueContext';
import { getVenue } from 'services/venuesServices';
import Venue from 'types/models/Venue';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedVenue } = React.useContext(EditVenueContext);
  const [completeVenue, setCompleteVenue] = React.useState<Venue | null>(null);

  const load = React.useCallback(async (id: string) => {
    const venue = await getVenue(id);
    if (!venue) {
      history.push('/private/venues');
      return;
    }
    setCompleteVenue(venue);
  }, []);

  React.useEffect(() => {
    if (selectedVenue) {
      load(selectedVenue.id);
    } else {
      history.push('/private/venues');
    }
  }, [selectedVenue]);

  if (!completeVenue) return <></>;
  return <VenueFactoryWidgetForm venue={completeVenue} />;
};
