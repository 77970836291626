/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import Image from 'types/models/Image';

export const parseImages = (images: any): Image<any>[] => {
  return images.map(
    (image: any): Image<any> => ({
      type: image.id,
      path: image.path,
    }),
  );
};

const getBase64 = (url: string): Promise<string> => {
  return axios
    .get(url, {
      responseType: 'arraybuffer',
    })
    .then((response) => Buffer.from(response.data, 'binary').toString('base64'));
};

export const loadImage = async (image: Image<any>) => {
  if (!image.path) return;
  const base64Response = await getBase64(image.path);
  const dataURL = `data:image/jpeg;base64,${base64Response}`;
  const fetchResponse = await fetch(dataURL);
  const blob = await fetchResponse.blob();
  image.preview = dataURL;
  image.data = blob;
};

export const loadImages = async (images: Image<any>[]): Promise<void> => {
  for (const image of images) {
    await loadImage(image);
  }
};
