import axiosInstance from './axios';
import PaginationData from 'types/models/PaginationData';
import TwitterConfig from 'types/models/TwitterConfig';

export const getTwitterConfigs = async (page?: number): Promise<PaginationData<TwitterConfig> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `twitter-configs?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseConfigs(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeTwitterConfig = async (twitterConfig: TwitterConfig): Promise<TwitterConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'twitter-configs',
      data: twitterConfig,
    });
    return parseConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateTwitterConfig = async (twitterConfig: TwitterConfig): Promise<TwitterConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `twitter-configs/${twitterConfig.id}`,
      data: twitterConfig,
    });
    return parseConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseConfigs = (twitterConfigs: any): TwitterConfig[] => {
  return twitterConfigs.map((twitterConfig: any): TwitterConfig => parseConfig(twitterConfig));
};

const parseConfig = (twitterConfig: any): TwitterConfig => {
  return {
    id: twitterConfig.id,
    searchType: twitterConfig.search_type_id,
    value: twitterConfig.value,
  };
};
