import * as yup from 'yup';
import { TFunction } from 'i18next';

export const CreateForm = (t: TFunction): any =>
  yup.object({
    name: yup
      .string()
      .required(t('schema-franchise-form-name-required'))
      .min(3, t('schema-franchise-form-name-min'))
      .max(191, t('schema-franchise-form-name-max')),
    commerces: yup.array().of(yup.string()).min(1, t('schema-franchise-form-commerces-min')),
  });

export const EditForm = (t: TFunction): any =>
  yup.object({
    name: yup
      .string()
      .required(t('schema-franchise-form-name-required'))
      .min(3, t('schema-franchise-form-name-min'))
      .max(191, t('schema-franchise-form-name-max')),
    commerces: yup.array().of(yup.string()).min(1, t('schema-franchise-form-commerces-min')),
    franchiseEditor: yup.string().required(t('schema-franchise-form-editor-required')),
  });
