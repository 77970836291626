import * as React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { storeArticle, updateArticle, deleteArticle } from 'services/articleServices';
import MultipleImagesInput from 'components/MultipleImagesInput';
import ImageInput from 'components/ImageInput';
import ConfirmDialog from 'components/ConfirmDialog';
import ArticleFormProps from 'types/forms/ArticleFormProps';
import Article from 'types/models/Article';
import { CreateForm, EditForm } from './schemas/ArticleForm';
import { parseDateToFormik, parseDateTimeLumen } from 'utils/datetime';
import EditVenueContext from 'contexts/EditVenueContext';
import ArticleImageType from 'types/enums/ArticleImageType';

export default ({ article }: ArticleFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [onDeleteArticle, setOnDeleteArticle] = React.useState(false);
  const { selectedVenue } = React.useContext(EditVenueContext);
  const banner = article?.images?.find((image) => image.type === ArticleImageType.banner);
  const detailImages = article?.images?.filter((image) => image.type === ArticleImageType.detail);
  const { t } = useTranslation();

  const onCancel = (): void => {
    formik.setSubmitting(false);
    if (onDeleteArticle) {
      setOnDeleteArticle(false);
    }
  };

  const onConfirm = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('title', formik.values.title);
    formData.append('description', formik.values.description);
    formData.append('body', formik.values.body);
    formData.append(
      'date',
      parseDateTimeLumen(formik.values.date, !article && selectedVenue ? selectedVenue?.timeZoneIdentifier : ''),
    );
    formData.append('banner', formik.values.banner as unknown as File);

    if (formik.values.detailImages) {
      console.log(formik.values.detailImages);
      formik.values.detailImages.forEach((image) => {
        console.log(image);
        if (image.data) formData.append('detailImages[]', image.data);
      });
    }

    try {
      let result: Article | null = null;

      if (article) {
        result = await updateArticle(article.id, formData);
      } else if (selectedVenue) {
        result = await storeArticle(selectedVenue.id, formData);
      }
      if (result) {
        history.go(-1);
      }
    } catch (error) {
      console.log(error);
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: article?.title ?? '',
      description: article?.description ?? '',
      body: article?.detail?.body ?? '',
      date: article?.date ?? moment(moment().tz(selectedVenue?.timeZoneIdentifier ?? '')).toDate(),
      banner: banner?.data ?? null,
      detailImages: detailImages ?? [],
    },
    validationSchema: article ? EditForm(t) : CreateForm(t),
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  const deleteArticleByVenue = async (id: string) => {
    formik.setSubmitting(true);
    await deleteArticle(id);
    formik.setSubmitting(false);
    history.go(-1);
  };

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={onDeleteArticle ? deleteArticleByVenue : onConfirm}
        onCancel={onCancel}
        onConfirmArgs={article && onDeleteArticle ? [article.id] : undefined}
      />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="title"
          name="title"
          label={t('article-form-title')}
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="description"
          name="description"
          label={t('article-form-description')}
          value={formik.values.description}
          multiline={true}
          rows={2}
          variant="standard"
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="date"
          label={t('article-form-date')}
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          value={parseDateToFormik(
            formik.values.date,
            !article && selectedVenue ? selectedVenue?.timeZoneIdentifier : '',
          )}
          onChange={(event) => {
            formik.setFieldValue(
              'date',
              moment(moment(event.target.value).tz(selectedVenue?.timeZoneIdentifier ?? '')).toDate(),
            );
          }}
          error={formik.touched.date && Boolean(formik.errors.date)}
          helperText={formik.touched.date && formik.errors.date}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="body"
          name="body"
          label={t('article-form-noticeBody')}
          value={formik.values.body}
          multiline={true}
          rows={5}
          variant="standard"
          onChange={formik.handleChange}
          error={formik.touched.body && Boolean(formik.errors.body)}
          helperText={formik.touched.body && formik.errors.body}
          disabled={formik.isSubmitting}
        />
        <ImageInput
          label={`${formik.values.banner || banner ? t('article-form-banner-edit') : t('article-form-banner-create')}`}
          name="banner"
          isSubmitting={formik.isSubmitting}
          value={formik.values.banner}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched.banner}
          errors={formik.errors.banner}
          preview={banner?.preview}
        />
        <MultipleImagesInput
          label={`${
            detailImages && detailImages.length > 0 ? t('article-form-image-edit') : t('article-form-image-create')
          }`}
          name="detailImages"
          isSubmitting={formik.isSubmitting}
          values={formik.values.detailImages}
          setFieldValue={formik.setFieldValue}
          ImageType={ArticleImageType.detail}
        />
        <div className="user-message">{t('article-form-image-size')}</div>
        <div className="footer">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${article ? t('article-form-notice-edit') : t('article-form-notice-create')}`}
          </Button>
        </div>
        {article && (
          <div className="footer">
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              type="submit"
              disabled={formik.isSubmitting}
              onClick={() => {
                setOpenDialog(true);
                setOnDeleteArticle(true);
              }}
            >
              {t('article-form-notice-delete')}
            </Button>
          </div>
        )}
      </form>
    </>
  );
};
