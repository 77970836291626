import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialog';
import { storeIPConfig, updateIPConfig } from './../services/izipayServices';
import IPConfigFormProps from 'types/forms/IPConfigFormProps';
import IPConfigForm from './schemas/IPConfigForm';

export default ({ config }: IPConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    try {
      const data = config ? await updateIPConfig({ ...formik.values }) : await storeIPConfig({ ...formik.values });
      if (data) {
        history.push('/private/admin/payments/izipay');
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      id: config?.id ?? '',
      description: config?.description ?? '',
      commerceId: config?.commerceId ?? '',
      publicKey: config?.publicKey ?? '',
      apiKey: config?.apiKey ?? '',
    },
    validationSchema: IPConfigForm(t),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });
  return (
    <div>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="description"
            name="description"
            type="string"
            label={t('mpConfig-form-description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="commerceId"
            name="commerceId"
            type="string"
            label={t('izipayConfig-form-commerceId')}
            value={formik.values.commerceId}
            onChange={formik.handleChange}
            error={formik.touched.commerceId && Boolean(formik.errors.commerceId)}
            helperText={formik.touched.commerceId && formik.errors.commerceId}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="publicKey"
            name="publicKey"
            type="string"
            label={t('izipayConfig-form-publicKey')}
            value={formik.values.publicKey}
            onChange={formik.handleChange}
            error={formik.touched.publicKey && Boolean(formik.errors.publicKey)}
            helperText={formik.touched.publicKey && formik.errors.publicKey}
          />
        </div>
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="apiKey"
            name="apiKey"
            type="string"
            label={t('izipayConfig-form-apiKey')}
            value={formik.values.apiKey}
            onChange={formik.handleChange}
            error={formik.touched.apiKey && Boolean(formik.errors.apiKey)}
            helperText={formik.touched.apiKey && formik.errors.apiKey}
          />
        </div>
        <div className="vertical-bottom-space">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {`${config ? t('mpConfig-form-config-edit') : t('mpConfig-form-config-create')} `}
          </Button>
        </div>
      </form>
    </div>
  );
};
