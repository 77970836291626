import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export default (t: TFunction): any =>
  yup.object({
    searchType: yup.number().positive().integer().required(t('schema-twitterConfig-form-searchType-required')),
    value: yup
      .string()
      .required(t('schema-twitterConfig-form-value-required'))
      .min(3, t('schema-twitterConfig-form-value-min'))
      .max(191, t('schema-twitterConfig-form-value-max')),
  });
