import React from 'react';
import { Dialog, Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ConfirmDialogProps from 'types/components/ConfirmDialogProps';
import 'styles/components/ConfirmDialog.scss';

export default ({ open, setOpen, onConfirm, onCancel, onConfirmArgs = [] }: ConfirmDialogProps): JSX.Element => {
  const { t } = useTranslation();

  const handleClose = (): void => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = (): void => {
    setOpen(false);
    onConfirm(...onConfirmArgs);
  };

  return (
    <Dialog open={open}>
      <Box className="confirm-dialog-container" display="flex" flexDirection="column" textAlign="center">
        <Typography variant="h6">Confirmar Operación</Typography>
        <Button fullWidth variant="contained" color="primary" type="submit" onClick={handleConfirm}>
          {t('confirm-dialog-accept')}
        </Button>
        <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
          {t('confirm-dialog-reject')}
        </Button>
      </Box>
    </Dialog>
  );
};
