/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance, { axiosMultipart } from './axios';
import PaginationData from 'types/models/PaginationData';
import Event from 'types/models/Event';
import EventDetail from 'types/models/EventDetail';
import { parseImages } from './imageServices';

export const getEvents = async (venueId: string, page?: number): Promise<PaginationData<Event> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `venues/${venueId}/events?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseEvents(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeEvent = async (id: string, formData: FormData): Promise<Event | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `venues/${id}/events`,
      data: formData,
    });
    return parseEvent(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getEvent = async (eventId: string): Promise<Event | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `events/${eventId}`,
    });
    return parseEvent(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateEvent = async (eventId: string, formData: FormData): Promise<Event | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `events/${eventId}?_method=put`,
      data: formData,
    });
    return parseEvent(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteEvent = async (eventId: string): Promise<Event | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `events/${eventId}`,
    });
    return parseEvent(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseEvents = (events: any): Event[] => {
  return events.map((event: any) => parseEvent(event));
};

export const parseEvent = (event: any): Event => {
  return {
    id: event.id,
    name: event.name,
    eventStart: event.event_start,
    eventEnd: event.event_end,
    detail: event.detail ? parseEventDetail(event.detail) : undefined,
    images: event.images ? parseImages(event.images) : undefined,
  };
};

const parseEventDetail = (detail: any): EventDetail => {
  return {
    id: detail.id,
    description: detail.description,
  };
};
