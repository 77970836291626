import * as React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { storeBanner, updateBanner, deleteBanner } from 'services/bannerServices';
import ImageInput from 'components/ImageInput';
import ConfirmDialog from 'components/ConfirmDialog';
import BannerFormProps from 'types/forms/BannerFormProps';
import Banner from 'types/models/Banner';
import { CreateForm, EditForm } from './schemas/BannerForm';
import EditVenueContext from 'contexts/EditVenueContext';
import BannerImageType from 'types/enums/BannerImageType';

export default ({ banner }: BannerFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [onDeleteBanner, setOnDeleteBanner] = React.useState(false);
  const { selectedVenue } = React.useContext(EditVenueContext);
  const front = banner?.images?.find((image) => image.type === BannerImageType.Front);
  const { t } = useTranslation();

  const onCancel = (): void => {
    formik.setSubmitting(false);
    if (onDeleteBanner) {
      setOnDeleteBanner(false);
    }
  };

  const onConfirm = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('title', formik.values.title);
    formData.append('description', formik.values.description);
    formData.append('url', formik.values.url);
    formData.append('front', formik.values.front as unknown as File);

    try {
      let result: Banner | null = null;
      if (banner) {
        result = await updateBanner(banner.id, formData);
      } else if (selectedVenue) {
        result = await storeBanner(selectedVenue.id, formData);
      }
      if (result) {
        history.go(-1);
      }
    } catch (error) {
      console.log(error);
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: banner?.title ?? '',
      url: banner?.url ?? '',
      description: banner?.description ?? '',
      front: front?.data ?? null,
    },
    validationSchema: banner ? EditForm(t) : CreateForm(t),
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  const deleteBannerByVenue = async (id: string) => {
    if (!selectedVenue) return;
    formik.setSubmitting(true);
    await deleteBanner(selectedVenue.id, id);
    formik.setSubmitting(false);
    history.go(-1);
  };

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={onDeleteBanner ? deleteBannerByVenue : onConfirm}
        onCancel={onCancel}
        onConfirmArgs={banner && onDeleteBanner ? [banner.id] : undefined}
      />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="title"
          name="title"
          label={t('banner-form-title')}
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="url"
          name="url"
          label={t('banner-form-url')}
          value={formik.values.url}
          multiline={true}
          variant="standard"
          onChange={formik.handleChange}
          error={formik.touched.url && Boolean(formik.errors.url)}
          helperText={formik.touched.url && formik.errors.url}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="description"
          name="description"
          label={t('banner-form-description')}
          value={formik.values.description}
          multiline={true}
          variant="standard"
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          disabled={formik.isSubmitting}
        />
        <ImageInput
          label={`${formik.values.front || front ? t('banner-form-front-edit') : t('banner-form-front-create')} `}
          name="front"
          isSubmitting={formik.isSubmitting}
          value={formik.values.front}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched.front}
          errors={formik.errors.front}
          preview={front?.preview}
        />
        <div className="user-message">{t('banner-form-image-size')}</div>
        <div className="footer">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${banner ? t('banner-form-banner-edit') : t('banner-form-banner-create')}`}
          </Button>
        </div>
        {banner && (
          <div className="footer">
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              type="submit"
              disabled={formik.isSubmitting}
              onClick={() => {
                setOpenDialog(true);
                setOnDeleteBanner(true);
              }}
            >
              {t('banner-form-banner-delete')}
            </Button>
          </div>
        )}
      </form>
    </>
  );
};
