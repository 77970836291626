import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import EditCommerceContext from 'contexts/EditCommerceContext';
import Commerce from 'types/models/Commerce';
import Product from 'types/models/Product';
import Discount from 'types/models/Discount';
import ListCommercesPage from 'pages/private/ListCommercesPage';
import CreateCommercePage from 'pages/private/admin/CreateCommercePage';
import EditCommercePage from 'pages/private/EditCommercePage';
import EditCommercePaymentConfigPage from 'pages/private/admin/EditCommercePaymentConfigPage';
import EditCommerceDeliveryConfigPage from 'pages/private/admin/EditCommerceDeliveryConfigPage';
import ListProductsPage from 'pages/private/ListProductsPage';
import CreateProductPage from 'pages/private/CreateProductPage';
import EditProductPage from 'pages/private/EditProductPage';
import ListDiscountsPage from 'pages/private/ListDiscountsPage';
import CreateDiscountPage from 'pages/private/CreateDiscountPage';
import EditDiscountPage from 'pages/private/EditDiscountPage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedCommerce, setSelectedCommerce] = React.useState<Commerce | null>(null);
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(null);
  const [selectedDiscount, setSelectedDiscount] = React.useState<Discount | null>(null);

  return (
    <EditCommerceContext.Provider
      value={{
        selectedCommerce,
        setSelectedCommerce,
        selectedProduct,
        setSelectedProduct,
        selectedDiscount,
        setSelectedDiscount,
      }}
    >
      <Switch>
        <Route exact={true} path={`${match.url}`} component={ListCommercesPage} />
        <Route path={`${match.url}/create`} component={CreateCommercePage} />
        <Route exact={true} path={`${match.url}/:commerceId/edit`} component={EditCommercePage} />
        <Route path={`${match.url}/:commerceId/edit/payment-config`} component={EditCommercePaymentConfigPage} />
        <Route path={`${match.url}/:commerceId/edit/delivery-config`} component={EditCommerceDeliveryConfigPage} />
        <Route exact={true} path={`${match.url}/:commerceId/products`} component={ListProductsPage} />
        <Route path={`${match.url}/:commerceId/products/create`} component={CreateProductPage} />
        <Route path={`${match.url}/:commerceId/products/edit`} component={EditProductPage} />
        <Route exact={true} path={`${match.url}/:commerceId/discounts`} component={ListDiscountsPage} />
        <Route path={`${match.url}/:commerceId/discounts/create`} component={CreateDiscountPage} />
        <Route path={`${match.url}/:commerceId/discounts/edit`} component={EditDiscountPage} />
        <Redirect to="/private/welcome" />
      </Switch>
    </EditCommerceContext.Provider>
  );
};
