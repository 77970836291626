import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CommerceCategoriesContextRoute from './CommerceCategoriesContextRoute';
import ProductCategoriesContextRoute from './ProductCategoriesContextRoute';
import PaymentsContextRoute from './PaymentsContextRoute';
import TwitterConfigsContextRoute from './TwitterConfigsContextRoute';
import AppContext from 'contexts/AppContext';
import UserRoleType from 'types/enums/UserRoleType';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const { authData } = React.useContext(AppContext);
  const { t } = useTranslation();
  if (!authData || authData.user.role !== UserRoleType.Admin) {
    return (
      <div className="error-container">
        <Typography color="error" variant="h3" align="center">
          {t('not-authorized-error')}
        </Typography>
      </div>
    );
  }

  return (
    <Switch>
      <Route path={`${match.url}/commerce-categories`} component={CommerceCategoriesContextRoute} />
      <Route path={`${match.url}/product-categories`} component={ProductCategoriesContextRoute} />
      <Route path={`${match.url}/twitter-configs`} component={TwitterConfigsContextRoute} />
      <Route path={`${match.url}/payments`} component={PaymentsContextRoute} />
    </Switch>
  );
};
