import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Edit, FileCopy, Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Commerce from 'types/models/Commerce';
import PaginationData from 'types/models/PaginationData';
import UserRoleType from 'types/enums/UserRoleType';
import {
  getCommercesWithPagination,
  cloneCommerce,
  deleteCommerce,
  getCommercesByVenueEditor,
  searchCommercesByUser,
  searchCommercesByVenueEditor,
} from 'services/commerceServices';
import EditCommerceContext from 'contexts/EditCommerceContext';
import AppContext from 'contexts/AppContext';
import ConfirmDialog from 'components/ConfirmDialog';
import 'styles/pages/ListCommercesPage.scss';

export default (): JSX.Element => {
  const history = useHistory();
  const { authData, currentPageListNumber, setCurrentPageListNumber } = React.useContext(AppContext);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [paginationCommerce, setPaginationCommerce] = React.useState<PaginationData<Commerce>>();
  const [commerceIdToClone, setCommerceIdToClone] = React.useState<string | null>(null);
  const [commerceIdToDelete, setCommerceIdToDelete] = React.useState<string | null>(null);
  const [searchCommerceText, setSearchCommerceText] = React.useState('');
  const { setSelectedCommerce } = React.useContext(EditCommerceContext);
  const { t } = useTranslation();

  const loadData = React.useCallback(async (page?: number) => {
    let paginationData: PaginationData<Commerce> | null;
    if (authData?.user.role === UserRoleType.VenueEditor) {
      paginationData = await getCommercesByVenueEditor(page);
    } else {
      paginationData = await getCommercesWithPagination(page);
    }
    if (paginationData) setPaginationCommerce(paginationData);
  }, []);

  const loadSearchData = React.useCallback(async (commerceSearchTerm: string, page?: number) => {
    let paginationData: PaginationData<Commerce> | null;
    if (authData?.user.role === UserRoleType.VenueEditor) {
      paginationData = await searchCommercesByVenueEditor(commerceSearchTerm, page);
    } else {
      paginationData = await searchCommercesByUser(commerceSearchTerm, page);
    }
    if (paginationData) {
      setPaginationCommerce(paginationData);
    }
  }, []);

  const handleSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCommerceText(event.target.value);
  };

  React.useEffect(() => {
    if (currentPageListNumber) {
      loadData(currentPageListNumber);
    }
  }, [currentPageListNumber]);

  React.useEffect(() => {
    if (!searchCommerceText && !paginationCommerce) return;
    if (!searchCommerceText && paginationCommerce) {
      loadData();
    }
  }, [searchCommerceText]);

  const onCloneCommerce = async () => {
    if (commerceIdToClone) {
      await cloneCommerce(commerceIdToClone);
      history.go(0);
    }
  };
  const onDeleteCommerce = async () => {
    if (commerceIdToDelete) {
      await deleteCommerce(commerceIdToDelete);
      history.go(0);
    }
  };

  const onCloseDialog = () => {
    setCommerceIdToClone('');
    setCommerceIdToDelete('');
  };

  const renderEditButton = (commerce: Commerce | null): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          if (!commerce) return;
          setSelectedCommerce(commerce);
          history.push(`/private/commerces/${commerce.id}/edit`);
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderCloneButton = (commerce: Commerce | null): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          if (!commerce) return;
          setOpenDialog(true);
          setCommerceIdToClone(commerce.id);
        }}
      >
        <FileCopy />
      </IconButton>
    );
  };
  const renderDeleteButton = (commerce: Commerce | null): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          if (!commerce) return;
          setOpenDialog(true);
          setCommerceIdToDelete(commerce.id);
        }}
      >
        <Delete />
      </IconButton>
    );
  };

  const renderContent = (paginationData: Commerce[]): JSX.Element => {
    return (
      <>
        {paginationData.map((commerce: Commerce) => (
          <TableRow key={commerce.id}>
            <TableCell component="th" scope="row">
              {commerce.name}
            </TableCell>
            <TableCell>{commerce.code}</TableCell>
            <TableCell>{commerce.address}</TableCell>
            <TableCell>{commerce.enabled ? t('yes') : t('no')}</TableCell>
            <TableCell>{commerce.phone}</TableCell>
            <TableCell>{commerce.email}</TableCell>
            <TableCell>{renderEditButton(commerce)}</TableCell>
            {authData?.user?.role === UserRoleType.Admin && <TableCell>{renderCloneButton(commerce)}</TableCell>}
            {(authData?.user?.role === UserRoleType.Admin || authData?.user?.role === UserRoleType.VenueEditor) && (
              <TableCell>{renderDeleteButton(commerce)}</TableCell>
            )}
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <ConfirmDialog
        open={openDialog && commerceIdToClone !== null}
        setOpen={setOpenDialog}
        onConfirm={onCloneCommerce}
        onCancel={onCloseDialog}
      />
      <ConfirmDialog
        open={openDialog && commerceIdToDelete !== null}
        setOpen={setOpenDialog}
        onConfirm={onDeleteCommerce}
        onCancel={onCloseDialog}
      />
      {authData?.user.role !== UserRoleType.CommerceEditor && (
        <div className="search-container">
          <div className="search-bar-container">
            <TextField
              id="search"
              name="searchTerm"
              label={t('listCommerces-page-search-placeholder')}
              value={searchCommerceText}
              onChange={handleSearchTerm}
              margin="dense"
              fullWidth
            />
          </div>
          <div className="search-button-element">
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disabled={!searchCommerceText}
              onClick={() => loadSearchData(searchCommerceText)}
            >
              {t('listCommerces-page-search')}
            </Button>
          </div>
        </div>
      )}
      {(authData?.user?.role === UserRoleType.Admin || authData?.user?.role === UserRoleType.VenueEditor) && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            history.push('/private/commerces/create');
          }}
        >
          {t('listCommerces-page-create')}
        </Button>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('listCommerces-page-name')}</TableCell>
              <TableCell>{t('listCommerces-page-code')}</TableCell>
              <TableCell>{t('listCommerces-page-adress')}</TableCell>
              <TableCell>{t('listCommerces-page-enabled')}</TableCell>
              <TableCell>{t('listCommerces-page-phone')}</TableCell>
              <TableCell>{t('listCommerces-page-email')}</TableCell>
              <TableCell>{t('listCommerces-page-edit')}</TableCell>
              {authData?.user?.role === UserRoleType.Admin && <TableCell>{t('listCommerces-page-clone')}</TableCell>}
              {(authData?.user?.role === UserRoleType.Admin || authData?.user?.role === UserRoleType.VenueEditor) && (
                <TableCell>{t('listCommerces-page-delete')}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationCommerce?.data ? (
              renderContent(paginationCommerce.data)
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('listCommerces-page-noCommerce')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paginationCommerce && (
        <TablePagination
          component="div"
          count={paginationCommerce.total}
          page={paginationCommerce.currentPage - 1}
          onPageChange={(_, page: number) => {
            if (searchCommerceText) {
              loadSearchData(searchCommerceText, page + 1);
            } else {
              setCurrentPageListNumber(page + 1);
              loadData(page + 1);
            }
          }}
          rowsPerPage={paginationCommerce.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
