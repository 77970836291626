import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Collapse,
  Box,
  Typography,
  Button,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { parseDateTime } from 'utils/datetime';
import PaginationData from 'types/models/PaginationData';
import Order from 'types/models/Order';
import { ConsumptionReportPayloadData } from 'types/forms/ConsumptionsReportFormProps';
import ConsumptionsReportForm from 'forms/ConsumptionsReportForm';
import { getReportingOrdersTotal, getReportingOrders, getAllReportingOrders } from 'services/orderServices';
import OrderDetail from 'types/models/OrderDetail';
import 'styles/pages/ConsumptionsReportPage.scss';

export default (): JSX.Element => {
  const [currentFormData, setCurrentFormData] = React.useState<ConsumptionReportPayloadData | null>(null);
  const [total, setTotal] = React.useState<number | null>(null);
  const [paginationOrders, setPaginationOrders] = React.useState<PaginationData<Order> | null>(null);
  const [openCollapseDetail, setOpenCollapseDetail] = React.useState(0);
  const { t } = useTranslation();

  const loadData = async (page?: number) => {
    if (!currentFormData) return;
    const getTotal = await getReportingOrdersTotal(currentFormData);
    const getPaginationOrders = await getReportingOrders(currentFormData, page);
    if (getPaginationOrders) {
      setPaginationOrders(getPaginationOrders);
    }
    setTotal(getTotal !== null ? getTotal : 0);
  };

  const downloadReport = async (): Promise<void> => {
    if (!currentFormData) return;
    const result = await getAllReportingOrders(currentFormData);
    if (result) {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(
        new Blob([result], {
          type: 'application/octet-stream',
        }),
      );
      a.download = 'reporte_ordenes.xlsx';
      a.click();
    }
  };

  React.useEffect(() => {
    if (total && paginationOrders) {
      setTotal(null);
      setPaginationOrders(null);
    }
    loadData();
  }, [currentFormData]);

  const handleCollapse = (id: number) => {
    if (openCollapseDetail === id) {
      setOpenCollapseDetail(0);
    } else {
      setOpenCollapseDetail(id);
    }
  };

  const renderContent = (order: Order): JSX.Element => {
    return (
      <>
        <TableRow className={'content-table'}>
          <TableCell component="th" scope="row">
            {order.id}
          </TableCell>
          <TableCell>{order.commerce?.name}</TableCell>
          <TableCell>{order.total}</TableCell>
          <TableCell>{parseDateTime(order.localDatetime)}</TableCell>
          <TableCell>{order.guestEmail}</TableCell>
          <TableCell>{order.phone}</TableCell>
          <TableCell>
            {order?.firstDataPayment?.ccLastFourDigits
              ? `**** **** **** ${order.firstDataPayment.ccLastFourDigits}`
              : ''}
          </TableCell>
          <TableCell>
            {order.event ? `${order.event.name} - ${parseDateTime(order.event.eventStart)}` : 'Sin especificar'}
          </TableCell>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => handleCollapse(order.id)}>
              {order.id === openCollapseDetail ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell>{order.delivery ?? 'No Aplica'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
            <Collapse in={order.id === openCollapseDetail} timeout="auto" unmountOnExit={true}>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom={true} component="div">
                  {t('consumptionsReport-page-detail')}
                </Typography>
                <Table size="small" aria-label="details">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('consumptionsReport-page-product')}</TableCell>
                      <TableCell>{t('consumptionsReport-page-amount')}</TableCell>
                      <TableCell>{t('consumptionsReport-page-clothingSizeType')}</TableCell>
                      <TableCell>{t('consumptionsReport-page-unitPrice')}</TableCell>
                      <TableCell>{t('consumptionsReport-page-totalProduct')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.details.map((detail: OrderDetail) => (
                      <TableRow key={detail.id}>
                        <TableCell component="th" scope="row">
                          {detail.product}
                        </TableCell>
                        <TableCell>{detail.quantity}</TableCell>
                        <TableCell>{detail.clothinSizeType?.description ?? '-'}</TableCell>
                        <TableCell>{detail.price}</TableCell>
                        <TableCell>{detail.totalAmount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <ConsumptionsReportForm currentFormData={currentFormData} setCurrentFormData={setCurrentFormData} />
      <>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>{t('consumptionsReport-page-order')}</TableCell>
                <TableCell>{t('consumptionsReport-page-commerce')}</TableCell>
                <TableCell>{t('consumptionsReport-page-total')}</TableCell>
                <TableCell>{t('consumptionsReport-page-createdDate')}</TableCell>
                <TableCell>{t('consumptionsReport-page-guestEmail')}</TableCell>
                <TableCell>{t('consumptionsReport-page-phone')}</TableCell>
                <TableCell>{t('consumptionsReport-page-lastFourDigits')}</TableCell>
                <TableCell>Evento</TableCell>
                <TableCell>{t('consumptionsReport-page-detail')}</TableCell>
                <TableCell>{t('consumptionsReport-page-delivery')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginationOrders?.data && paginationOrders?.data.length > 0 ? (
                paginationOrders?.data.map((order: Order) => renderContent(order))
              ) : (
                <TableRow>
                  <TableCell>{t('consumptionsReport-page-noOperations')}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {paginationOrders && (
          <>
            <TableRow>
              <TableCell>{`${t('consumptionsReport-page-operationsTotal')}: $${total?.toFixed(2)}`}</TableCell>
            </TableRow>
            <TablePagination
              component="div"
              count={paginationOrders.total}
              page={paginationOrders.currentPage - 1}
              onPageChange={(_, page: number) => {
                loadData(page + 1);
              }}
              rowsPerPage={paginationOrders.perPage}
              rowsPerPageOptions={[]}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}-${to} ${t('pagination-of')} ${count !== -1 ? count : '0'}`;
              }}
            />
            {paginationOrders?.data.length > 0 && (
              <Box marginTop="1rem">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    downloadReport();
                  }}
                >
                  {t('consumptionsReport-page-download')}
                </Button>
              </Box>
            )}
          </>
        )}
      </>
    </>
  );
};
