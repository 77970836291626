import * as yup from 'yup';
import { AnyObjectSchema } from 'yup';
import {
  descriptionValidation,
  enabledValidation,
  imageValidation,
  regexColor,
  maxirestCodeValidation,
} from 'utils/yup';
import { TFunction } from 'react-i18next';

export const CreateForm = (maxirestCodeIsRequired: boolean, t: TFunction): AnyObjectSchema => {
  return yup.object().shape({
    name: yup.string().required(t('schema-product-form-name-required')),
    description: descriptionValidation,
    price: yup
      .string()
      .required(t('schema-product-form-price-required'))
      .test('maxDigitsAfterDecimal', t('schema-product-form-price-test'), (number: any) =>
        /^\d+(\.\d{1,2})?$/.test(number),
      ),
    estimatedPreparationTime: yup
      .number()
      .min(0o1, t('schema-product-form-estimatedPreparationTime-min'))
      .max(59, t('schema-product-form-estimatedPreparationTime-max'))
      .nullable(true),
    maxirestCode: maxirestCodeValidation(maxirestCodeIsRequired),
    category: yup.string().required(t('schema-product-form-category-required')),
    isPromotion: yup.boolean().required(t('schema-product-form-isPromotion-required')),
    showInList: yup.boolean().required(t('schema-product-form-showInList-required')),
    logo: imageValidation('Logo', true),
    stock: yup.mixed().when('isClothing', {
      is: false,
      then: yup.number().required(t('schema-product-form-stock-required')),
    }),
    minimunStock: yup.mixed().when('isClothing', {
      is: false,
      then: yup.number().required(t('schema-product-form-minimun-stock-required')),
    }),
    minimumAmount: yup.mixed().when('suggestedType', {
      is: true,
      then: yup.number().required(t('schema-product-form-minimun-amount-required')),
    }),
    promotionBanner: yup.mixed().when('isPromotion', {
      is: true,
      then: imageValidation('Banner', true),
      otherwise: imageValidation('Banner', false),
    }),
    promotionBannerBackgroundColor: yup.mixed().when('isPromotion', {
      is: true,
      then: yup
        .string()
        .required(t('schema-product-form-promotionBannerBackgroundColor-required'))
        .matches(regexColor, t('schema-product-form-promotionBannerBackgroundColor-matches')),
    }),
    promotionBannerColorTitle: yup.mixed().when('isPromotion', {
      is: true,
      then: yup
        .string()
        .required(t('schema-product-form-promotionBannerColorTitle-required'))
        .matches(regexColor, t('schema-product-form-promotionBannerColorTitle-matches')),
    }),
  });
};

export const EditForm = (maxirestCodeIsRequired: boolean, t: TFunction): any =>
  yup.object().shape({
    name: yup.string().required('El nombre es requerido'),
    description: descriptionValidation,
    price: yup
      .string()
      .required(t('schema-product-form-price-required'))
      .test('maxDigitsAfterDecimal', t(''), (number: any) => /^\d+(\.\d{1,2})?$/.test(number)), //revisar
    estimatedPreparationTime: yup
      .number()
      .min(0o1, t('schema-product-form-estimatedPreparationTime-min'))
      .max(59, t('schema-product-form-estimatedPreparationTime-max'))
      .nullable(true),
    maxirestCode: maxirestCodeValidation(maxirestCodeIsRequired),
    isPromotion: yup.boolean().required(t('schema-product-form-isPromotion-required')),
    showInList: yup.boolean().required(t('schema-product-form-showInList-required')),
    logo: imageValidation('Logo', true),
    enabled: enabledValidation,
    stock: yup.mixed().when('isClothing', {
      is: false,
      then: yup.number().required(t('schema-product-form-stock-required')),
    }),
    minimunStock: yup.mixed().when('isClothing', {
      is: false,
      then: yup.number().required(t('schema-product-form-minimun-stock-required')),
    }),
    promotionBanner: yup.mixed().when('isPromotion', {
      is: true,
      then: imageValidation('Banner', true),
      otherwise: imageValidation('Banner', false),
    }),
    suggestedType: yup.mixed().when('isSuggested', {
      is: true,
      then: yup.number().required(t('schema-product-form-suggested-type-required')),
    }),
    minimumAmount: yup.mixed().when('suggestedType', {
      is: true,
      then: yup.number().required(t('schema-product-form-minimun-amount-required')),
    }),
    promotionBannerBackgroundColor: yup.mixed().when('isPromotion', {
      is: true,
      then: yup
        .string()
        .required(t('schema-product-form-promotionBannerBackgroundColor-required'))
        .matches(regexColor, t('schema-product-form-promotionBannerBackgroundColor-matches')),
    }),
    promotionBannerColorTitle: yup.mixed().when('isPromotion', {
      is: true,
      then: yup
        .string()
        .required(t('schema-product-form-promotionBannerColorTitle-required'))
        .matches(regexColor, t('schema-product-form-promotionBannerColorTitle-matches')),
    }),
    cloverLogo: imageValidation('Clover Logo', false),
  });
