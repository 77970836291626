import React from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/pages/WelcomePage.scss';

export default (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <section className="title-section">
        <h1 className="title">{t('welcome-page-header')}</h1>
      </section>

      <div className="subtitle-container">
        <h3 className="subtitle">{t('welcome-page-subtitle')}</h3>
      </div>
    </>
  );
};
