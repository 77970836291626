import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router';
import EditMDConfigContext from 'contexts/EditMPConfigContext';
import EditMPCheckoutConfigContext from 'contexts/EditMPCheckoutConfigContext';
import EditFDConfigContext from 'contexts/EditFDConfigContext';
import EditMPPointConfigContext from 'contexts/EditMPPointConfigContext';
import EditCloverPointConfigContext from 'contexts/EditCloverPointConfigContext';
import EditIPConfigContext from 'contexts/EditIPConfigContext';
import MercadoPagoConfig from 'types/models/MercadoPagoConfig';
import SelectPaymentTypePage from 'pages/private/admin/SelectPaymentTypePage';
import ListMPConfigsPage from 'pages/private/admin/ListMPConfigsPage';
import ListMPPointConfigsPage from 'pages/private/admin/ListMPPointConfigsPage';
import ListMPCheckoutConfigsPage from 'pages/private/admin/ListMPCheckoutConfigsPage';
import CreateMPCheckoutPage from 'pages/private/admin/CreateMPCheckoutPage';
import EditMPCheckoutPage from 'pages/private/admin/EditMPCheckoutPage';
import CreateMPConfig from 'pages/private/admin/CreateMPConfig';
import CreateMPPointConfig from 'pages/private/admin/CreateMPPointConfig';
import EditMPConfig from 'pages/private/admin/EditMPConfig';
import FirstDataConfig from 'types/models/FirstDataConfig';
import MercadoPagoPointConfig from 'types/models/MercadoPagoPointConfig';
import EditMPPointConfig from 'pages/private/admin/EditMPPointConfig';
import ListMPPoints from 'pages/private/admin/ListMPPoints';
import EditMPPoint from 'pages/private/admin/EditMPPoint';
import ListFDConfigsPage from 'pages/private/admin/ListFDConfigsPage';
import CreateFDConfig from 'pages/private/admin/CreateFDConfig';
import EditFDConfig from 'pages/private/admin/EditFDConfig';
import ListCloverPointConfigsPage from 'pages/private/admin/ListCloverPointConfigsPage';
import CreateCloverPointConfigPage from 'pages/private/admin/CreateCloverPointConfigPage';
import EditCloverPointConfigPage from 'pages/private/admin/EditCloverPointConfigPage';
import ListCloverPoints from 'pages/private/admin/ListCloverPoints';
import EditCloverPointPage from 'pages/private/admin/EditCloverPointPage';
import MPPoint from 'types/models/MPPoint';
import CloverPointConfig from 'types/models/CloverPointConfig';
import CloverPoint from 'types/models/CloverPoint';
import IzipayConfig from 'types/models/IzipayConfig';
import ListIPConfigsPage from 'pages/private/admin/ListIPConfigsPage';
import CreateIPConfig from 'pages/private/admin/CreateIPConfig';
import EditIPConfig from 'pages/private/admin/EditIPConfig';
import MercadoPagoCheckoutConfig from 'types/models/MercadoPagoCheckoutConfig';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedMPConfig, setSelectedMPConfig] = React.useState<MercadoPagoConfig | null>(null);
  const [selectedIPConfig, setSelectedIPConfig] = React.useState<IzipayConfig | null>(null);
  const [selectedFDConfig, setSelectedFDConfig] = React.useState<FirstDataConfig | null>(null);
  const [selectedMPPointConfig, setSelectedMPPointConfig] = React.useState<MercadoPagoPointConfig | null>(null);
  const [selectedMPPoint, setSelectedMPPoint] = React.useState<MPPoint | null>(null);
  const [selectedMPCheckoutConfig, setSelectedMPCheckoutConfig] = React.useState<MercadoPagoCheckoutConfig | null>(
    null,
  );
  const [selectedCloverPointConfig, setSelectedCloverPointConfig] = React.useState<CloverPointConfig | null>(null);
  const [selectedCloverPoint, setSelectedCloverPoint] = React.useState<CloverPoint | null>(null);

  return (
    <EditMDConfigContext.Provider value={{ selectedMPConfig, setSelectedMPConfig }}>
      <EditMPCheckoutConfigContext.Provider value={{ selectedMPCheckoutConfig, setSelectedMPCheckoutConfig }}>
        <EditFDConfigContext.Provider value={{ selectedFDConfig, setSelectedFDConfig }}>
          <EditMPPointConfigContext.Provider
            value={{
              selectedMPPointConfig,
              setSelectedMPPointConfig,
              selectedMPPoint,
              setSelectedMPPoint,
            }}
          >
            <EditCloverPointConfigContext.Provider
              value={{
                selectedCloverPointConfig,
                setSelectedCloverPointConfig,
                selectedCloverPoint,
                setSelectedCloverPoint,
              }}
            >
              <EditIPConfigContext.Provider value={{ selectedIPConfig, setSelectedIPConfig }}>
                <Switch>
                  <Route exact={true} path={`${match.url}`} component={SelectPaymentTypePage} />
                  <Route exact={true} path={`${match.url}/izipay`} component={ListIPConfigsPage} />
                  <Route exact={true} path={`${match.url}/izipay/create`} component={CreateIPConfig} />
                  <Route exact={true} path={`${match.url}/izipay/edit`} component={EditIPConfig} />
                  <Route exact={true} path={`${match.url}/mercado-pago`} component={ListMPConfigsPage} />
                  <Route path={`${match.url}/mercado-pago/create`} component={CreateMPConfig} />
                  <Route path={`${match.url}/mercado-pago/edit`} component={EditMPConfig} />
                  <Route
                    exact={true}
                    path={`${match.url}/mercado-pago-checkout`}
                    component={ListMPCheckoutConfigsPage}
                  />
                  <Route path={`${match.url}/mercado-pago-checkout/create`} component={CreateMPCheckoutPage} />
                  <Route path={`${match.url}/mercado-pago-checkout/edit`} component={EditMPCheckoutPage} />
                  <Route exact={true} path={`${match.url}/mercado-pago-point`} component={ListMPPointConfigsPage} />
                  <Route path={`${match.url}/mercado-pago-point/create`} component={CreateMPPointConfig} />
                  <Route path={`${match.url}/mercado-pago-point/edit`} component={EditMPPointConfig} />
                  <Route exact={true} path={`${match.url}/mercado-pago-point/posnets`} component={ListMPPoints} />
                  <Route path={`${match.url}/mercado-pago-point/posnets/edit`} component={EditMPPoint} />
                  <Route exact={true} path={`${match.url}/first-data`} component={ListFDConfigsPage} />
                  <Route path={`${match.url}/first-data/create`} component={CreateFDConfig} />
                  <Route path={`${match.url}/first-data/edit`} component={EditFDConfig} />
                  <Route exact={true} path={`${match.url}/clover-point`} component={ListCloverPointConfigsPage} />
                  <Route path={`${match.url}/clover-point/create`} component={CreateCloverPointConfigPage} />
                  <Route path={`${match.url}/clover-point/edit`} component={EditCloverPointConfigPage} />
                  <Route exact={true} path={`${match.url}/clover-point/posnets`} component={ListCloverPoints} />
                  <Route path={`${match.url}/clover-point/posnets/edit`} component={EditCloverPointPage} />
                  <Redirect to="/private/welcome" />
                </Switch>
              </EditIPConfigContext.Provider>
            </EditCloverPointConfigContext.Provider>
          </EditMPPointConfigContext.Provider>
        </EditFDConfigContext.Provider>
      </EditMPCheckoutConfigContext.Provider>
    </EditMDConfigContext.Provider>
  );
};
