import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/SelectField';
import ConfirmDialog from 'components/ConfirmDialog';
import TwitterConfigFormProps from 'types/forms/TwitterConfigFormProps';
import TwitterConfigForm from './schemas/TwitterConfigForm';
import TwitterSearchType from 'types/enums/TwitterSearchType';
import { storeTwitterConfig, updateTwitterConfig } from 'services/twitterConfigsServices';

export default ({ twitterConfig }: TwitterConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    try {
      const data = twitterConfig
        ? await updateTwitterConfig({ ...twitterConfig, ...formik.values })
        : await storeTwitterConfig({ id: '', ...formik.values });
      if (data) {
        history.push('/private/twitter-configs');
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      searchType: twitterConfig?.searchType ?? TwitterSearchType.USER,
      value: twitterConfig?.value ?? '',
    },
    validationSchema: TwitterConfigForm(t),
    onSubmit: async () => {
      setOpenDialog(true);
    },
  });

  const searchTypeOptions = Object.keys(TwitterSearchType)
    .filter((key) => !isNaN(Number(TwitterSearchType[key as keyof typeof TwitterSearchType])))
    .map((key) => ({ label: key, value: TwitterSearchType[key as keyof typeof TwitterSearchType] }));

  return (
    <div>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <SelectField
          name="twitterConfig"
          label={t('twitterConfig-form-search-label')}
          placeholder={t('twitterConfig-form-selectConfig-placeholder')}
          value={formik.values.searchType}
          options={searchTypeOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.searchType}
          error={formik.errors.searchType}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <div className="vertical-bottom-space">
          <TextField
            fullWidth
            id="value"
            name="value"
            label={t('twitterConfig-form-value')}
            value={formik.values.value}
            onChange={formik.handleChange}
            error={formik.touched.value && Boolean(formik.errors.value)}
            helperText={formik.touched.value && formik.errors.value}
          />
        </div>
        <div className="vertical-bottom-space">
          <Button color="primary" variant="contained" fullWidth type="submit">
            {`${twitterConfig ? t('twitterConfig-form-config-edit') : t('twitterConfig-form-config-create')}`}
          </Button>
        </div>
      </form>
    </div>
  );
};
