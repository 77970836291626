import * as React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { storeEvent, updateEvent, deleteEvent } from 'services/eventServices';
import ImageInput from 'components/ImageInput';
import ConfirmDialog from 'components/ConfirmDialog';
import MultipleImagesInput from 'components/MultipleImagesInput';
import EventFormProps from 'types/forms/EventFormProps';
import Event from 'types/models/Event';
import { CreateForm, EditForm } from './schemas/EventForm';
import { parseDateToFormik, parseDateTimeLumen } from 'utils/datetime';
import EditVenueContext from 'contexts/EditVenueContext';
import EventImageType from 'types/enums/EventImageType';

export default ({ event }: EventFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [onDeleteEvent, setOnDeleteEvent] = React.useState(false);
  const { selectedVenue } = React.useContext(EditVenueContext);
  const banner = event?.images?.find((image) => image.type === EventImageType.banner);
  const detailImages = event?.images?.filter((image) => image.type === EventImageType.detail);
  const { t } = useTranslation();

  const onCancel = (): void => {
    formik.setSubmitting(false);
    if (onDeleteEvent) {
      setOnDeleteEvent(false);
    }
  };

  const onConfirm = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('name', formik.values.name);
    formData.append('description', formik.values.description);
    formData.append(
      'eventStart',
      parseDateTimeLumen(formik.values.eventStart, !event && selectedVenue ? selectedVenue?.timeZoneIdentifier : ''),
    );
    formData.append(
      'eventEnd',
      parseDateTimeLumen(formik.values.eventEnd, !event && selectedVenue ? selectedVenue?.timeZoneIdentifier : ''),
    );
    formData.append('banner', formik.values.banner as unknown as File);

    if (formik.values.detailImages) {
      formik.values.detailImages.forEach((image) => {
        if (image.data) formData.append('detailImages[]', image.data);
      });
    }

    try {
      let result: Event | null = null;
      if (event) {
        result = await updateEvent(event.id, formData);
      } else if (selectedVenue) {
        result = await storeEvent(selectedVenue.id, formData);
      }
      if (result) {
        history.go(-1);
      }
    } catch (error) {
      console.log(error);
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: event?.name ?? '',
      description: event?.detail?.description ?? '',
      eventStart:
        event?.eventStart ?? moment().add(1, 'day').set({ hour: 19, minutes: 0, seconds: 0, millisecond: 0 }).toDate(),
      eventEnd:
        event?.eventEnd ?? moment().add(1, 'day').set({ hour: 22, minutes: 0, seconds: 0, millisecond: 0 }).toDate(),
      banner: banner?.data ?? null,
      detailImages: detailImages ?? [],
    },
    validationSchema: event ? EditForm(t) : CreateForm(t),
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  const deleteEventByVenue = async (id: string) => {
    formik.setSubmitting(true);
    await deleteEvent(id);
    formik.setSubmitting(false);
    history.go(-1);
  };

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={onDeleteEvent ? deleteEventByVenue : onConfirm}
        onCancel={onCancel}
        onConfirmArgs={event && onDeleteEvent ? [event.id] : undefined}
      />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="name"
          name="name"
          label={t('event-form-name')}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="description"
          name="description"
          label={t('event-form-description')}
          value={formik.values.description}
          multiline={true}
          rows={2}
          variant="standard"
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="datetime-local"
          label={t('event-form-datetime-local-from')}
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          value={parseDateToFormik(
            formik.values.eventStart,
            !event && selectedVenue ? selectedVenue?.timeZoneIdentifier : '',
          )}
          onChange={(event) => {
            formik.setFieldValue(
              'eventStart',
              moment(moment(event.target.value).tz(selectedVenue?.timeZoneIdentifier ?? '')).toDate(),
            );
          }}
          error={formik.touched.eventStart && Boolean(formik.errors.eventStart)}
          helperText={formik.touched.eventStart && formik.errors.eventStart}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="datetime-local"
          label={t('event-form-datetime-local-to')}
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          value={parseDateToFormik(
            formik.values.eventEnd,
            !event && selectedVenue ? selectedVenue?.timeZoneIdentifier : '',
          )}
          onChange={(event) => {
            formik.setFieldValue(
              'eventEnd',
              moment(moment(event.target.value).tz(selectedVenue?.timeZoneIdentifier ?? '')).toDate(),
            );
          }}
          error={formik.touched.eventEnd && Boolean(formik.errors.eventEnd)}
          helperText={formik.touched.eventEnd && formik.errors.eventEnd}
          disabled={formik.isSubmitting}
        />
        <ImageInput
          label={`${formik.values.banner || banner ? t('event-form-banner-edit') : t('event-form-banner-create')} `}
          name="banner"
          isSubmitting={formik.isSubmitting}
          value={formik.values.banner}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched.banner}
          errors={formik.errors.banner}
          preview={banner?.preview}
        />
        <MultipleImagesInput
          label={`${
            detailImages && detailImages.length > 0
              ? t('event-form-detail-image-edit')
              : t('event-form-detail-image-create')
          } `}
          name="detailImages"
          isSubmitting={formik.isSubmitting}
          values={formik.values.detailImages}
          setFieldValue={formik.setFieldValue}
          ImageType={EventImageType.detail}
        />
        <div className="user-message">{t('event-form-image-size')}</div>
        <div className="footer">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${event ? t('event-form-event-edit') : t('event-form-event-create')}`}
          </Button>
        </div>
        {event && (
          <div className="footer">
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              type="submit"
              disabled={formik.isSubmitting}
              onClick={() => {
                setOpenDialog(true);
                setOnDeleteEvent(true);
              }}
            >
              {t('event-form-event-delete')}
            </Button>
          </div>
        )}
      </form>
    </>
  );
};
