import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DiscountForm from 'forms/DiscountForm';
import EditCommerceContext from 'contexts/EditCommerceContext';
import AppContext from 'contexts/AppContext';
import { getDiscount } from 'services/discountServices';
import { loadImages } from 'services/imageServices';
import Discount from 'types/models/Discount';
import UserRoleType from 'types/enums/UserRoleType';

export default (): JSX.Element => {
  const history = useHistory();
  const { authData } = React.useContext(AppContext);
  const { selectedDiscount, selectedCommerce } = React.useContext(EditCommerceContext);
  const [completeDiscount, setCompleteDiscount] = React.useState<Discount | null>(null);
  const { t } = useTranslation();

  if (
    !authData ||
    (authData.user.role !== UserRoleType.Admin &&
      authData.user.role !== UserRoleType.FranchiseEditor &&
      UserRoleType.CommerceEditor &&
      selectedCommerce?.editorId !== authData.user.id)
  ) {
    return (
      <div className="error-container">
        <Typography color="error" variant="h3" align="center">
          {t('not-authorized-error-or-not-editor')}
        </Typography>
      </div>
    );
  }

  const load = React.useCallback(async (id: string) => {
    const discount = await getDiscount(id);
    if (!discount) {
      history.push('/private/commerces');
      return;
    } else {
      if (discount.images) {
        await loadImages(discount.images);
      }
      setCompleteDiscount(discount);
    }
  }, []);

  React.useEffect(() => {
    if (!selectedDiscount || !selectedCommerce) {
      history.push('/private/commerces');
    } else {
      load(selectedDiscount.id);
    }
  }, [selectedDiscount, selectedCommerce]);

  if (!completeDiscount) return <></>;
  return <DiscountForm discount={completeDiscount} />;
};
