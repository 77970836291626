/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance from './axios';
import PaginationData from 'types/models/PaginationData';
import Franchise from 'types/models/Franchise';
import { parseCommerces } from './commerceServices';

export const getFranchisesWithPagination = async (page?: number): Promise<PaginationData<Franchise> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `franchises?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseFranchises(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllFranchises = async (): Promise<Franchise[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `franchises/all`,
    });
    return parseFranchises(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeFranchise = async (franchise: Franchise): Promise<Franchise | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'franchises',
      data: franchise,
    });
    return parseFranchise(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getFranchise = async (franchiseId: string): Promise<Franchise | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `franchises/${franchiseId}`,
    });
    return parseFranchise(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateFranchise = async (franchiseId: string, franchise: Franchise): Promise<Franchise | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `franchises/${franchiseId}`,
      data: franchise,
    });
    return parseFranchise(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseFranchises = (franchises: any): Franchise[] => {
  return franchises.map((franchise: any): Franchise => parseFranchise(franchise));
};

const parseFranchise = (franchise: any): Franchise => {
  return {
    id: franchise.id,
    name: franchise.name,
    commerces: franchise.commerces ? parseCommerces(franchise.commerces) : undefined,
    editorId: franchise.editor_id ?? undefined,
  };
};
