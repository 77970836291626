import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ImageInput from 'components/ImageInput';
import ConfirmDialog from 'components/ConfirmDialog';
import { CreateForm, EditForm } from './schemas/CommerceCategoryForm';
import CommerceCategoriesFormProps from 'types/forms/CommerceCategoriesFormProps';
import CommerceCategory from 'types/models/CommerceCategory';
import { storeCommerceCategory, updateCommerceCategory } from 'services/commerceServices';
import CategoryImageType from 'types/enums/CategoryImageType';

export default ({ commerceCategory }: CommerceCategoriesFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const logo = commerceCategory?.images?.find((image) => image.type === CategoryImageType.logo);
  const { t } = useTranslation();

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('description', formik.values.description);
    formData.append('logo', formik.values.logo as unknown as File);
    try {
      let result: CommerceCategory | null = null;
      if (commerceCategory) {
        result = await updateCommerceCategory(commerceCategory.id, formData);
      } else {
        result = await storeCommerceCategory(formData);
      }
      if (result) {
        history.go(-1);
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      description: commerceCategory?.description ?? '',
      logo: logo?.data ?? null,
    },
    validationSchema: commerceCategory ? EditForm(t) : CreateForm(t),
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="description"
          name="description"
          label={t('commerceCategories-form-description')}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          disabled={formik.isSubmitting}
        />
        <ImageInput
          label={`${
            formik.values.logo || logo
              ? t('commerceCategories-form-logo-edit')
              : t('commerceCategories-form-logo-create')
          }`}
          name="logo"
          isSubmitting={formik.isSubmitting}
          value={formik.values.logo}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched.logo}
          errors={formik.errors.logo}
          preview={logo?.preview}
        />
        <div className="user-message">{t('commerceCategories-form-logo-size')}</div>
        <div className="footer">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${
              commerceCategory
                ? t('commerceCategories-form-category-edit')
                : t('commerceCategories-form-category-create')
            }`}
          </Button>
        </div>
      </form>
    </>
  );
};
