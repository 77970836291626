import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField, FormControlLabel, Switch } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';
import ImageInput from 'components/ImageInput';
import ConfirmDialog from 'components/ConfirmDialog';
import DiscountFormProps from 'types/forms/DiscountFormProps';
import Discount from 'types/models/Discount';
import { storeDiscount, updateDiscount, deleteDiscount } from 'services/discountServices';
import EditCommerceContext from 'contexts/EditCommerceContext';
import DiscountImageTypes from 'types/enums/DiscountImageType';
import { EditForm, CreateForm } from './schemas/DiscountForm';

export default ({ discount }: DiscountFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [onDeleteDiscount, setOnDeleteDiscount] = React.useState(false);
  const { selectedCommerce } = React.useContext(EditCommerceContext);
  const banner = discount?.images?.find((image) => image.type === DiscountImageTypes.banner);
  const { t } = useTranslation();

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('description', formik.values.description);
    formData.append('amount', formik.values.amount.toString());
    formData.append('priority', formik.values.priority.toString());
    formData.append('banner', formik.values.banner as unknown as File);
    try {
      let result: Discount | null = null;
      if (!selectedCommerce) return;
      if (discount) {
        formData.append('enabled', formik.values.enabled ? '1' : '0');
        result = await updateDiscount(discount.id, formData);
      } else {
        result = await storeDiscount(selectedCommerce.id, formData);
      }
      if (result) {
        history.go(-1);
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      description: discount?.description ?? '',
      amount: discount?.amount ?? 0,
      enabled: discount?.enabled ?? false,
      priority: discount?.priority ?? 0,
      banner: banner?.data ?? null,
    },
    onSubmit: () => {
      setOpenDialog(true);
    },
    validationSchema: discount ? EditForm(t) : CreateForm(t),
  });

  const deleteDiscountByCommerce = async (id: string) => {
    formik.setSubmitting(true);
    await deleteDiscount(id);
    formik.setSubmitting(false);
    history.go(-1);
  };

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={onDeleteDiscount ? deleteDiscountByCommerce : onConfirm}
        onCancel={onCancel}
        onConfirmArgs={discount && onDeleteDiscount ? [discount.id] : undefined}
      />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="description"
          name="description"
          label={t('discountForm-form-description')}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="amount"
          name="amount"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{t('discountForm-form-amount-adornament')}</InputAdornment>,
          }}
          label={t('discountForm-form-amount')}
          value={formik.values.amount}
          onChange={formik.handleChange}
          error={formik.touched.amount && Boolean(formik.errors.amount)}
          helperText={formik.touched.amount && formik.errors.amount}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="priority"
          name="priority"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{t('discountForm-form-priority-adornament')}</InputAdornment>,
          }}
          label={t('discountForm-form-priority')}
          value={formik.values.priority}
          onChange={formik.handleChange}
          error={formik.touched.priority && Boolean(formik.errors.priority)}
          helperText={'Cuanto más bajo sea el número más alta su prioridad, no se pueden colocar ceros'}
          disabled={formik.isSubmitting}
        />
        {discount && (
          <FormControlLabel
            id="enabled"
            name="enabled"
            control={<Switch checked={formik.values.enabled} onChange={formik.handleChange} />}
            label={t('discountForm-form-enabled')}
            disabled={formik.isSubmitting}
          />
        )}
        <ImageInput
          label={`${
            formik.values.banner || banner ? t('discountForm-form-banner-edit') : t('discountForm-form-banner-create')
          } `}
          name="banner"
          isSubmitting={formik.isSubmitting}
          value={formik.values.banner}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched.banner}
          errors={formik.errors.banner}
          preview={banner?.preview}
        />
        <div className="user-message">{t('discountForm-form-banner-size')}</div>
        <div className="footer">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${discount ? t('discountForm-form-priority-edit') : t('discountForm-form-priority-create')} `}
          </Button>
        </div>
        {discount && (
          <div className="footer">
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              type="submit"
              disabled={formik.isSubmitting}
              onClick={() => {
                setOpenDialog(true);
                setOnDeleteDiscount(true);
              }}
            >
              {t('discountForm-form-delete')}
            </Button>
          </div>
        )}
      </form>
    </>
  );
};
