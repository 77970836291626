import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export default (t: TFunction): any =>
  yup.object({
    description: yup.string().required(t('schema-IPConfig-form-description-required')),
    commerceId: yup.string().required(t('schema-IPConfig-form-commerceId-required')),
    publicKey: yup.string().required(t('schema-IPConfig-form-publicKey-required')),
    apiKey: yup.string().required(t('schema-IPConfig-form-apiKey-required')),
  });
