import axiosInstance from './axios';
import PaginationData from 'types/models/PaginationData';
import MercadoPagoConfig from 'types/models/MercadoPagoConfig';
import MercadoPagoPointConfig from 'types/models/MercadoPagoPointConfig';
import MPPoint from 'types/models/MPPoint';
import MercadoPagoCheckoutConfig from 'types/models/MercadoPagoCheckoutConfig';

export const getMPConfigs = async (page?: number): Promise<PaginationData<MercadoPagoConfig> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `payments/mercado-pago/configs?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseConfigs(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getMPCheckoutConfigs = async (
  page?: number,
): Promise<PaginationData<MercadoPagoCheckoutConfig> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `payments/mercado-pago-checkout/configs?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseMPCheckoutConfigs(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeMPConfig = async (mercadoPagoConfig: MercadoPagoConfig): Promise<MercadoPagoConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'payments/mercado-pago/config',
      data: mercadoPagoConfig,
    });
    return parseConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateMPConfig = async (mercadoPagoConfig: MercadoPagoConfig): Promise<MercadoPagoConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `payments/mercado-pago/config?_method=put`,
      data: mercadoPagoConfig,
    });
    return parseConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeMPCheckoutConfig = async (
  mercadoPagoCheckoutConfig: MercadoPagoCheckoutConfig,
): Promise<MercadoPagoCheckoutConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'payments/mercado-pago-checkout/config',
      data: mercadoPagoCheckoutConfig,
    });
    return parseMPCheckoutConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateMPCheckoutConfig = async (
  mercadoPagoCheckoutConfig: MercadoPagoCheckoutConfig,
): Promise<MercadoPagoCheckoutConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `payments/mercado-pago-checkout/config?_method=put`,
      data: mercadoPagoCheckoutConfig,
    });
    return parseMPCheckoutConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getMPPointConfigs = async (page?: number): Promise<PaginationData<MercadoPagoPointConfig> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `payments/mercado-pago-point/configs?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseMPPointConfigs(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getMPPoints = async (mpPointConfigId: string, page?: number): Promise<PaginationData<MPPoint> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `payments/mercado-pago-point/config/${mpPointConfigId}/mp-points?page=${page ?? 1}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseMPPoints(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeMPPointConfig = async (
  mercadoPagoConfig: MercadoPagoPointConfig,
): Promise<MercadoPagoPointConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'payments/mercado-pago-point/config',
      data: mercadoPagoConfig,
    });
    return parseMPPointConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateMPPointConfig = async (
  mercadoPagoConfig: MercadoPagoPointConfig,
): Promise<MercadoPagoPointConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `payments/mercado-pago-point/config?_method=put`,
      data: mercadoPagoConfig,
    });
    return parseMPPointConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateMPPoint = async (mpPointConfigId: string, mpPoint: MPPoint): Promise<MPPoint | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `payments/mercado-pago-point/config/${mpPointConfigId}/mp-points/${mpPoint.id}`,
      data: mpPoint,
    });
    return parseMPPoint(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseMPPoints = (data: any): MPPoint[] => {
  return data.map((item: any): MPPoint => parseMPPoint(item));
};

const parseMPPoint = (data: any): MPPoint => {
  return {
    id: data.id,
    description: data.description,
    operatingMode: data.operating_mode,
    isPaired: data.is_paired === 1,
  };
};

const parseMPPointConfigs = (data: any): MercadoPagoPointConfig[] => {
  return data.map((item: any): MercadoPagoPointConfig => parseMPPointConfig(item));
};

const parseMPPointConfig = (data: any): MercadoPagoPointConfig => {
  return {
    id: data.id,
    description: data.description,
    accessToken: data.accessToken,
  };
};

const parseConfigs = (data: any): MercadoPagoConfig[] => {
  return data.map((item: any): MercadoPagoConfig => parseConfig(item));
};

export const parseMPCheckoutConfigs = (data: any[]): MercadoPagoCheckoutConfig[] => {
  return data.map((item: any): MercadoPagoCheckoutConfig => parseMPCheckoutConfig(item));
};

const parseConfig = (data: any): MercadoPagoConfig => {
  return {
    id: data.id,
    description: data.description,
    token: data.token,
  };
};

const parseMPCheckoutConfig = (data: any): MercadoPagoCheckoutConfig => {
  return {
    ...parseConfig(data),
    currencyCode: data.currency_code,
    mpApiUrl: data.mp_api_url,
    publicKey: data.public_key,
  };
};
