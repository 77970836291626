import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Box, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import VenueDeliveryConfigFormProps from 'types/forms/VenueDeliveryConfigFormProps';
import ConfirmDialog from 'components/ConfirmDialog';
import SelectField from 'components/SelectField';
import { getTwitterConfigs } from 'services/twitterConfigsServices';
import { updateVenueSNSConfig } from 'services/venuesServices';
import TwitterConfig from 'types/models/TwitterConfig';
import TwitterSearchType from 'types/enums/TwitterSearchType';
import VenueSNSConfigForm from './schemas/VenueSNSConfigForm';

export default ({ venue }: VenueDeliveryConfigFormProps): JSX.Element => {
  const history = useHistory();
  const [twitterConfigList, setTwitterConfigList] = React.useState<TwitterConfig[]>([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();

  const onConfirm = async (): Promise<void> => {
    if (!venue) return;

    try {
      const result = await updateVenueSNSConfig(venue.id, formik.values);
      if (result) {
        history.go(-1);
      } else {
        throw new Error();
      }
    } catch (error) {
      formik.setSubmitting(false);
    }
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      instagramToken: '',
      twitterConfig: venue.twitterConfig ?? '',
    },
    validationSchema: VenueSNSConfigForm(t),
    onSubmit: (): void => {
      setOpenDialog(true);
    },
  });

  React.useEffect(() => {
    async function getData() {
      const getTwitterConfigsResult = await getTwitterConfigs();
      if (getTwitterConfigsResult?.data) setTwitterConfigList(getTwitterConfigsResult.data);
    }
    getData();
  }, []);

  const twitterConfigOptions = twitterConfigList.map((twitterConfig) => ({
    value: twitterConfig.id,
    label: `Tipo: ${TwitterSearchType[twitterConfig.searchType]} | Valor: ${twitterConfig.value}`,
  }));

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="instagramToken"
          name="instagramToken"
          label={t('venueSNSConfig-form-instagramToken')}
          value={formik.values.instagramToken}
          onChange={formik.handleChange}
          error={formik.touched.instagramToken && Boolean(formik.errors.instagramToken)}
          helperText={formik.touched.instagramToken && formik.errors.instagramToken}
          disabled={formik.isSubmitting}
        />
        <SelectField
          name="twitterConfig"
          label={t('venueSNSConfig-form-twitterConfig-label')}
          placeholder={t('venueSNSConfig-form-twitterConfig-placeholder')}
          value={formik.values.twitterConfig}
          options={twitterConfigOptions}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.twitterConfig}
          error={formik.errors.twitterConfig}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <Box marginTop="1rem">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {t('venueSNSConfig-form-save')}
          </Button>
        </Box>
      </form>
    </>
  );
};
