import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppContext from 'contexts/AppContext';
import SelectField from 'components/SelectField';
import ConfirmDialog from 'components/ConfirmDialog';
import UserRoleType from 'types/enums/UserRoleType';
import UserFormProps from 'types/forms/UserFormProps';
import User from 'types/models/User';
import { storeUser, updateUser } from 'services/userServices';
import { CreateForm, EditForm } from './schemas/UserForm';

export default ({ user }: UserFormProps): JSX.Element => {
  const { authData } = React.useContext(AppContext);
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [error, setError] = React.useState('');
  const { t } = useTranslation();
  const roles = [
    { label: t('listUsers-page-commerce-user'), value: UserRoleType.CommerceEditor },
    { label: t('listUsers-page-venue-user'), value: UserRoleType.VenueEditor },
    { label: t('listUsers-page-franchise-user'), value: UserRoleType.FranchiseEditor },
  ];

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    const userData = {
      email: formik.values.email,
      password: formik.values.password,
      name: formik.values.name,
      role: formik.values.role,
    };

    try {
      let result: User | null;

      if (user) {
        result = await updateUser(user.id, userData);
      } else {
        result = await storeUser(userData);
      }

      if (result) {
        history.go(-1);
      }
    } catch (error: any) {
      console.log(error);
      setError(`${error}`);
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: user?.email ?? '',
      password: '',
      name: user?.name ?? '',
      role: authData?.user.role === UserRoleType.VenueEditor ? UserRoleType.CommerceEditor : user?.role ?? 0,
    },
    validationSchema: user ? EditForm(t) : CreateForm(t),
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          margin="dense"
          fullWidth
          id="email"
          name="email"
          label={t('user-form-email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="password"
          name="password"
          type="password"
          label={t('user-form-password')}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="name"
          name="name"
          label={t('user-form-name')}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          disabled={formik.isSubmitting}
        />
        {authData?.user.role !== UserRoleType.VenueEditor && (
          <SelectField
            name="role"
            label={t('user-form-role')}
            placeholder={t('user-form-select-role-placeholder')}
            value={formik.values.role}
            options={roles}
            isMulti={false}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            touched={formik.touched.role}
            error={formik.errors.role}
            isClearable={true}
            backspaceRemovesValue={false}
            isDisabled={formik.isSubmitting}
            isSearchable={true}
          />
        )}
        <div className="footer">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${user ? t('user-form-edit') : t('user-form-create')}`}
          </Button>
        </div>
        {error && (
          <div>
            <Typography color="error" variant="h6" component="h2">
              {error}
            </Typography>
          </div>
        )}
      </form>
    </>
  );
};
