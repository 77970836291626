import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import 'styles/pages/ReportinPage.scss';

export default (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="container">
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => history.push('/private/reporting/consumptions')}
      >
        {t('reporting-page-consumes')}
      </Button>
      <Button fullWidth variant="contained" color="primary" onClick={() => history.push('/private/reporting/loads')}>
        {t('reporting-page-loads')}
      </Button>
      <Button fullWidth variant="contained" color="primary" onClick={() => history.push('/private/reporting/sells')}>
        {t('reporting-page-sells')}
      </Button>
    </div>
  );
};
