import * as yup from 'yup';
import { descriptionValidation, IMAGE_VALID_FILES, MAX_FILE_SIZE } from 'utils/yup';

export default yup.object().shape({
  description: descriptionValidation,
  logo: yup
    .mixed()
    .test('FILE_SIZE', 'El archivo debe pesar menos de 2mb', (value) => !value || value.size <= MAX_FILE_SIZE)
    .test('FILE_TYPE', 'El archivo debe ser png o svg', (value) => !value || IMAGE_VALID_FILES.includes(value.type)),
});
