import React from 'react';
import { useHistory } from 'react-router-dom';
import ProductCategoryForm from 'forms/ProductCategoryForm';
import EditProductCategoriesContext from 'contexts/EditProductCategoriesContext';
import { getProductCategory } from 'services/productServices';
import { loadImage } from 'services/imageServices';
import ProductCategory from 'types/models/ProductCategory';

export default (): JSX.Element => {
  const history = useHistory();
  const { selectedProductCategory } = React.useContext(EditProductCategoriesContext);
  const [completeProductCategory, setCompleteProductCategory] = React.useState<ProductCategory | null>(null);

  const load = React.useCallback(async (id: string) => {
    const productCategory = await getProductCategory(id);
    if (!productCategory) {
      history.push('/private/commerces');
      return;
    } else {
      if (productCategory.image) {
        await loadImage(productCategory.image);
      }
      setCompleteProductCategory(productCategory);
    }
  }, []);

  React.useEffect(() => {
    if (!selectedProductCategory) {
      history.push('/private/admin/product-categories');
    } else {
      load(selectedProductCategory.id);
    }
  }, [selectedProductCategory]);

  if (!selectedProductCategory || !completeProductCategory) return <></>;
  return <ProductCategoryForm productCategory={completeProductCategory} />;
};
